import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {REDUX_FORM_NAME} from "react-admin";
import "../map/map.css";
import {change} from "redux-form";
import {LayerGroup, Map, Marker, Polygon, Popup, TileLayer} from "react-leaflet";
import IconColor from "./constants";
import {AppConstant} from "../../providers/constants";
import {useForm} from "react-final-form";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const SLBoundary = (props) => {
    const form = useForm();
    const {otherLocation, otherBoundary, otherBoundaryMarkers, boundary, location, mainBoundary, mainLocation, title} = props;

    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    });
    const [markers, setMarkers] = useState([]);
    const [zoom, setZoom] = useState(17);
    const [bounds, setBounds] = useState([]);
    const [draggable, setDraggable] = useState(true);
    const [order, setOrder] = useState(props.orderChanged);

    const refmarker = useRef();

    useEffect(() => {
        let markerList = [];
        let newBounds = [];
        if (boundary === undefined) {
            return
        }
        boundary.coordinates[0].points.forEach((value) => {
            if (value.lat && value.long) {
                markerList.push([value.lat, value.long]);
                newBounds.push({lat: value.lat, lng: value.long})
            }
        });
        setMarkers(markerList);
        setBounds(newBounds);
        if (order !== props.orderChanged) {
            markerList = [];
            newBounds = [];
            setOrder(props.orderChanged);
            boundary.coordinates[0].points.forEach((value) => {
                if (value.lat && value.long) {
                    markerList.push([value.lat, value.long]);
                    newBounds.push({lat: value.lat, lng: value.long})
                }
            });
            setMarkers(markerList);
            setBounds(newBounds);
        }
    }, [boundary, props.orderChanged]);

    const addMarkerCallback = useCallback((boundary_points, newPos) => {
        let newBounds = boundary_points;
        newBounds.push({lat: newPos[0], long: newPos[1]});
        let newCoordinates = {
            coordinates: [
                {
                    points: newBounds,
                }
            ],
        };
        form.change("boundary[" + (props.orderChanged - 1).toString() + "]", newCoordinates);
    }, [form, props.orderChanged]);

    const addMarker = (boundary_points) => (e) => {
        const newPos = [e.latlng.lat, e.latlng.lng];
        if (!boundary_points) {
            boundary_points = []
        }
        addMarkerCallback(boundary_points, newPos);
    };

    const updateMarkerCallback = useCallback((points) => {
        let newCoordinates = {
            coordinates: [
                {
                    points: points,
                }
            ],
        };
        form.change("boundary[" + (props.orderChanged - 1).toString() + "]", newCoordinates);
    }, [form, props.orderChanged]);


    const updateMarkers = (points) => (e) => {
        const latLng = e.target.getLatLng();
        const markerIndex = e.target.options.marker_index;
        let newBounds = bounds;
        if (!newBounds) {
            newBounds = [];
            points = [];
        }
        let markerData = [...markers];
        newBounds[markerIndex].lat = points[markerIndex].lat = latLng.lat;
        newBounds[markerIndex].lng = points[markerIndex].long = latLng.lng;
        markerData[markerIndex] = [latLng.lat, latLng.lng];
        setMarkers(markerData);
        setBounds(newBounds);
        updateMarkerCallback(points)
    };

    let mainBounds = [];
    let markersMain = [];
    if (!mainBoundary || !boundary) {
        return null;
    }

    mainBoundary.coordinates[0].points.forEach((value) => {
        if (value.lat && value.long) {
            markersMain.push([value.lat, value.long]);
            mainBounds.push({lat: value.lat, lng: value.long})
        }
    });

    return (
        <div id="mapId" className="leaflet-container">
            <Map
                id="mapId"
                preferCanvas={true}
                center={location ? [location.coordinates.lat, location.coordinates.long] : mainLocation}
                draggable={draggable}
                doubleClickZoom={false}
                ondblclick={addMarker(boundary.coordinates[0].points)}
                zoom={zoom}
                maxZoom={AppConstant.MAX_MAP_ZOOM}
            >
                <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker
                    //icon={IconColor("orange")}
                    draggable={false}
                    position={location ? [location.coordinates.lat, location.coordinates.long] : mainLocation}
                    ref={refmarker}
                >
                    <Popup minWidth={90}>
                                        <span>
                                            {title}
                                        </span>
                    </Popup>
                </Marker>
                {markers.map((position, idx) =>
                    <LayerGroup>
                        <Marker icon={IconColor("green")}
                                key={`marker-${idx}`}
                                position={position}
                                marker_index={`${idx}`}
                                draggable={draggable}
                                onDragend={updateMarkers(boundary.coordinates[0].points)}
                        >
                            <Popup>
                                <span>Sub Marker {idx + 1}</span>
                            </Popup>
                        </Marker>
                    </LayerGroup>
                )}
                <Polygon color={"green"} positions={bounds}/>
                <Marker
                    icon={IconColor("red")}
                    draggable={false}
                    position={mainLocation}
                    ref={refmarker}
                >
                    <Popup minWidth={90}>
                                        <span>
                                            {title}
                                        </span>
                    </Popup>
                </Marker>
                {markersMain.map((position, idx) =>
                    <LayerGroup>
                        <Marker icon={IconColor("red")} key={`marker-main-${idx}`} position={position}
                                marker_index={`${idx}`}
                                draggable={false}
                        >
                            <Popup>
                                <span>Main Marker {idx + 1}</span>
                            </Popup>
                        </Marker>
                    </LayerGroup>
                )}
                <Polygon color={"red"} positions={mainBounds}/>
                {otherLocation ? otherLocation.map((position, idx) =>
                    <LayerGroup>
                        <Marker icon={IconColor("black")} key={`marker-${idx}`} position={position}
                                marker_index={`${idx}`}
                                draggable={false}
                        >
                        </Marker>
                        {otherBoundary[idx] ? <Polygon color={"black"} positions={otherBoundary[idx]}/> : null}
                    </LayerGroup>
                ) : null}
                {otherBoundaryMarkers ? otherBoundaryMarkers.map((position, idx) =>
                    <LayerGroup>
                        <Marker icon={IconColor("black")} key={`marker-other-${idx}`} position={position}
                                marker_index={`${idx}`}
                                draggable={false}
                        >
                        </Marker>
                    </LayerGroup>
                ) : null}
            </Map>
        </div>
    );

};

class SLBoundary_1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 0,
                lng: 0
            },
            markers: [],
            zoom: 17,
            bounds: [],
            //current_marker: null,
            draggable: true,
            orderChanged: "",
        };
        this.refmarker = React.createRef();
        this.addMarker = this.addMarker.bind(this);
        this.initData = this.initData.bind(this);
        this.updateMarkers = this.updateMarkers.bind(this);
        // this.updateSubLocation = this.updateSubLocation.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //console.log("orderChanged", this.state.orderChanged);
        //console.log("Check", nextProps.orderChanged !== this.state.orderChanged);
        if (nextProps.orderChanged !== this.state.orderChanged || this.props.boundary !== nextProps.boundary) {
            if (nextProps.dispatch) {
                nextProps.dispatch(
                    change(REDUX_FORM_NAME, "boundary.coordinates[0].points", this.state.bounds),
                );
            }
            this.setState({
                markers: [],
                bounds: [],
                orderChanged: nextProps.orderChanged,
                //current_marker: [nextProps.location.lat, nextProps.location.long]
            })
        }
    }

    initData = (formData) => {
        let markerList = [];
        let bounds = [];
        formData.forEach((value) => {
            if (value.lat && value.long) {
                markerList.push([value.lat, value.long]);
                bounds.push({lat: value.lat, lng: value.long})
            }
        });
        this.setState({markers: markerList, bounds: bounds});
    };

    addMarker = (markers, boundary_points, dispatch) => (e) => {
        const newPos = [e.latlng.lat, e.latlng.lng];
        if (!boundary_points) {
            boundary_points = []
        }
        boundary_points.push({lat: newPos[0], long: newPos[1]});
        this.setState(prevState => (
            {
                markers: prevState.markers.concat([newPos]),
                bounds: prevState.bounds.concat([{lat: newPos[0], lng: newPos[1]}])
            }
        ));
        if (dispatch) {
            dispatch(
                change(REDUX_FORM_NAME, "boundary[" + (parseInt(this.props.orderChanged) - 1).toString() + "].coordinates[0].points", boundary_points),
            );
        }
    };

    // updateSubLocation = (index, dispatch) => (e) => {
    //     console.log("INDEX", index);
    //     const marker = this.refmarker.current;
    //     console.log("Marker", marker);
    //     if (marker) {
    //         const updatedMarker = marker.leafletElement.getLatLng();
    //         this.setState({
    //             current_marker: [updatedMarker.lat, updatedMarker.lng]
    //         });
    //         if (dispatch) {
    //             dispatch(
    //                 change(REDUX_FORM_NAME, "location[" + index + "].coordinates.lat", updatedMarker.lat)
    //             );
    //             dispatch(
    //                 change(
    //                     REDUX_FORM_NAME,
    //                     "location[" + index + "].coordinates.long",
    //                     updatedMarker.lng
    //                 )
    //             );
    //         }
    //     }
    // };

    updateMarkers = (points, dispatch) => (e) => {
        //debugger
        const latLng = e.target.getLatLng();
        const markerIndex = e.target.options.marker_index;
        let bounds = [...this.state.bounds];
        if (!bounds) {
            bounds = [];
            points = [];
        }
        this.setState(prevState => {
            const markerData = [...prevState.markers];
            bounds[markerIndex].lat = points[markerIndex].lat = latLng.lat;
            bounds[markerIndex].lng = points[markerIndex].long = latLng.lng;
            markerData[markerIndex] = [latLng.lat, latLng.lng];
            return {markers: markerData, bounds: bounds};
        });
        if (dispatch) {
            dispatch(
                change(REDUX_FORM_NAME, "boundary.coordinates[0].points", points),
            );
        }
    };

    render() {
        const {dispatch, otherLocation, otherBoundary, otherBoundaryMarkers, orderChanged, boundary, location, mainBoundary, mainLocation, title} = this.props;
        //console.log("Other Location", otherLocation);
        //console.log("Other Boundary", otherBoundary);
        let mainBounds = [];
        let markersMain = [];
        if (!mainBoundary || !boundary) {
            return null;
        }
        mainBoundary.coordinates[0].points.forEach((value) => {
            if (value.lat !== undefined && value.long !== undefined) {
                markersMain.push([value.lat, value.long]);
                mainBounds.push({lat: value.lat, lng: value.long})
            }
        });
        if (boundary.coordinates[0].points && boundary.coordinates) { // using marker list to update markers state
            // console.log("formData", boundary.coordinates[0].points);
            if (this.state.markers.length !== boundary.coordinates[0].points.length) {
                let c = true;
                // console.log("Length", boundary.coordinates[0].points.length);
                boundary.coordinates[0].points.forEach((element) => {
                    if (!(element.lat && element.long)) {
                        c = false;
                    }
                });
                if (c) {
                    this.initData(boundary.coordinates[0].points);
                }
            }
        }
        return (
            <div id="mapId" className="leaflet-container">
                <Map
                    id="mapId"
                    preferCanvas={true}
                    center={location ? [location.coordinates.lat, location.coordinates.long] : mainLocation}
                    draggable={this.state.draggable}
                    doubleClickZoom={false}
                    ondblclick={this.addMarker(this.state.markers, boundary.coordinates[0].points, dispatch)}
                    zoom={this.state.zoom}
                    maxZoom={AppConstant.MAX_MAP_ZOOM}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                    />
                    <Marker
                        //icon={IconColor("orange")}
                        draggable={false}
                        position={location ? [location.coordinates.lat, location.coordinates.long] : mainLocation}
                        ref={this.refmarker}
                    >
                        <Popup minWidth={90}>
                                        <span>
                                            {title}
                                        </span>
                        </Popup>
                    </Marker>
                    {this.state.markers.map((position, idx) =>
                        <LayerGroup>
                            <Marker icon={IconColor("green")}
                                    key={`marker-${idx}`}
                                    position={position}
                                    marker_index={`${idx}`}
                                    draggable={this.state.draggable}
                                    onDragend={this.updateMarkers(boundary.coordinates[0].points, dispatch)}
                            >
                                <Popup>
                                    <span>Sub Marker {idx + 1}</span>
                                </Popup>
                            </Marker>
                        </LayerGroup>
                    )}
                    <Polygon color={"green"} positions={this.state.bounds}/>
                    <Marker
                        icon={IconColor("red")}
                        draggable={false}
                        position={mainLocation}
                        ref={this.refmarker}
                    >
                        <Popup minWidth={90}>
                                        <span>
                                            {title}
                                        </span>
                        </Popup>
                    </Marker>
                    {markersMain.map((position, idx) =>
                        <LayerGroup>
                            <Marker icon={IconColor("red")} key={`marker-${idx}`} position={position}
                                    marker_index={`${idx}`}
                                    draggable={this.state.draggable}
                            >
                                <Popup>
                                    <span>Main Marker {idx + 1}</span>
                                </Popup>
                            </Marker>
                        </LayerGroup>
                    )}
                    <Polygon color={"red"} positions={mainBounds}/>
                    {otherLocation ? otherLocation.map((position, idx) =>
                        <LayerGroup>
                            <Marker icon={IconColor("black")} key={`marker-${idx}`} position={position}
                                    marker_index={`${idx}`}
                                    draggable={false}
                            >
                            </Marker>
                            {otherBoundary[idx] ? <Polygon color={"black"} positions={otherBoundary[idx]}/> : null}
                        </LayerGroup>
                    ) : null}
                    {otherBoundaryMarkers ? otherBoundaryMarkers.map((position, idx) =>
                        <LayerGroup>
                            <Marker icon={IconColor("black")} key={`marker-${idx}`} position={position}
                                    marker_index={`${idx}`}
                                    draggable={false}
                            >
                            </Marker>
                        </LayerGroup>
                    ) : null}
                </Map>
            </div>
        );
    }
}

export default SLBoundary;