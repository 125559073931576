import React from 'react';
import {
    BooleanField,
    Datagrid,
    EditButton,
    EmailField,
    Filter,
    FunctionField,
    List,
    SearchInput,
    TextField
} from 'react-admin';
import UserTitle from './UserTitle';
import {AppConstant} from "../../providers/constants";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const UserFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const UserList = props => (
    <List title={<UserTitle type="Users"/>} filters={<UserFilter/>} {...props}>
        <Datagrid>
            <TextField source="firstName"/>
            <TextField source="lastName"/>
            <TextField source="username"/>
            <FunctionField
                label="Image"
                render={record => {
                    if (record.userImage === null || record.userImage === "") {
                        return null;
                    }
                    if (record.userImage.includes("https://")) {
                        return (
                            <img
                                alt={record.defaultImage}
                                src={`${record.userImage}`}
                                width={80}
                                height={80}
                            />
                        );
                    }
                    return (
                        <img
                            alt={record.defaultImage}
                            src={`${AppConstant.SERVER_IMAGE}${record.userImage}`}
                            width={80}
                            height={80}
                        />
                    );
                }}
            />
            <EmailField source="email"/>
            <TextField source="role"/>
            <BooleanField label={"Activation"} source={"isAccountActivated"}/>
            <TextField label="FacebookID" source="facebookID"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default UserList;