import React from 'react';
import {
    ChipField,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    NumberField,
    ReferenceArrayField,
    SearchInput,
    SingleFieldList,
    TextField
} from 'react-admin';
import {AppConstant} from "../../providers/constants";
import LoopTitle from "./LoopTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const LoopFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const LoopList = props => (
    <List title={<LoopTitle type="Users"/>} filters={<LoopFilter/>} {...props}>
        <Datagrid>
            <TextField label={"Title"} source="contentTranslation[0].title"/>
            <FunctionField
                label="Image"
                render={record => {
                    if (record.thumbnail === null || record.thumbnail === "") {
                        return null;
                    }
                    return (
                        <img
                            alt={record.thumbnail}
                            src={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                            width={80}
                            height={80}
                        />
                    );
                }}
            />
            <FunctionField
                label="Loop Type"
                render={record => {
                    if (!record.loopTypes) {
                        return null;
                    }
                    if (record.loopTypes.length === 0) {
                        return null;
                    }
                    return (
                        <ReferenceArrayField label="Loop Types" source="loopTypes" reference="loop-types-admin"
                                             record={record} basePath={"loop-types-admin"}>
                            <SingleFieldList>
                                <ChipField source="loopTypeTranslations[0].name"/>
                            </SingleFieldList>
                        </ReferenceArrayField>
                    );
                }}
            />
            <NumberField label={"Total Time"} source="numberTime"/>
            <TextField labe={"Time Unit"} source="unitTime"/>
            <TextField labe={"Search Tags"} source="searchTags"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default LoopList;