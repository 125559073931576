import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    FormTab,
    ReferenceInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import HelpTitle from './HelpTitle';
import {styles} from "../../common/styleField";


const HelpEdit = props => (
    <Edit title={<HelpTitle type="Edit"/>} {...props}>
        <TabbedForm redirect={false}>
            <FormTab label="LANGUAGE">
                <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                reference="languages-admin">
                    <AutocompleteInput optionText="code" optionValue="code"/>
                </ReferenceInput>
            </FormTab>
            <FormTab label="FAQ">
                <ArrayInput source="faq" style={styles.translations}>
                    <SimpleFormIterator>
                        <TextInput fullWidth label={"Question"} source={"question"}/>
                        <TextInput fullWidth label={"Answer"} source={"answer"}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default HelpEdit;