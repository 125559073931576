import React from 'react';
import {BooleanInput, Edit, FunctionField, required, SimpleForm, TextField} from 'react-admin';
import UserTitle from './UserTitle';
import {AppConstant} from "../../providers/constants";


const UserEdit = props => (
    <Edit title={<UserTitle type="Edit"/>} {...props}>
        <SimpleForm redirect={false}>
            <BooleanInput label={"Activated"} source={"isAccountActivated"}/>
            <TextField source="firstName" validate={required()}/>
            <TextField source="lastName" validate={required()}/>
            <TextField source="username"/>
            <TextField source="email" validate={required()}/>
            <FunctionField
                id="userImage"
                label="User Image"
                render={record => {
                    if (record.userImage === null || record.userImage === "") {
                        return null;
                    }
                    if (record.userImage.includes("https://")) {
                        return (
                            <img
                                alt={record.defaultImage}
                                src={`${record.userImage}`}
                                width={80}
                                height={80}
                            />
                        );
                    }
                    return (
                        <img
                            alt={record.userImage}
                            src={`${AppConstant.SERVER_IMAGE}${record.userImage}`}
                            width={160}
                            height={160}
                        />
                    )
                }}
            />
        </SimpleForm>
    </Edit>
);

export default UserEdit;