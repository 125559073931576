import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import LoopCreate from "./LoopCreate";
import LoopList from "./LoopList";
import LoopEdit from "./LoopEdit";

export default {
    list: LoopList,
    create: LoopCreate,
    edit: LoopEdit,
    icon: DirectionsRunIcon,
};