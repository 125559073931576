import React from 'react';

import {Create, required, SimpleForm, TextInput} from 'react-admin';

const AppPricingCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput type={"number"} source="price" validate={required()}/>
        </SimpleForm>
    </Create>
);

export default AppPricingCreate;