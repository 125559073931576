import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.customLink = this.customLink.bind(this)
    }

    customLink = (props) => {
        const id = this.props.userID;
        if (id) {
            return (
                <Link to={`/users-cms/${id}`} {...props}/>
            )
        } else {
            return <Link to={`/`} {...props}/>
        }
    };

    render() {
        return (
            <Button
                id="buttonLink"
                style={{
                    marginRight: 25,
                    marginTop: 25,
                    verticalAlign: "middle"
                }}
                variant="contained"
                color="primary"
                component={this.customLink}
            >
                User Profile
            </Button>
        )
    }
}

export default withRouter(UserProfile)