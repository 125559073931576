import TermsOfServiceList from './TermsOfServiceList';
import TermsOfServiceCreate from './TermsOfServiceCreate';
import TermsOfServiceEdit from './TermsOfServiceEdit';
import GavelIcon from '@material-ui/icons/Gavel';

export default {
    list: TermsOfServiceList,
    create: TermsOfServiceCreate,
    edit: TermsOfServiceEdit,
    icon: GavelIcon,
};