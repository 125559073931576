import TourList from './TourList';
import TourCreate from './TourCreate';
import TourEdit from './TourEdit';
import TerrainIcon from '@material-ui/icons/Terrain';

export default {
    list: TourList,
    create: TourCreate,
    edit: TourEdit,
    icon: TerrainIcon,
};