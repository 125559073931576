import React from 'react';

import {
    AutocompleteInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    required,
    SimpleForm,
    withDataProvider
} from 'react-admin';
import TimetableTitle from './TimetableTitle';
import {AppConstant} from "../../providers/constants";


const destination = [
    {id: "place", name: "Place"},
    {id: "region", name: "Sub-Place"},
    {id: "restaurant", name: "Restaurant"}
];


const TimetableCreate = props => (
    <Create title={<TimetableTitle type="Create"/>} {...props}>
        <SimpleForm>
            <AutocompleteInput label={"Destination Type"} source={"destinationType"} validate={required()}
                               choices={destination}/>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (formData) {
                        let ref = "";
                        if (formData && formData.destinationType) {
                            if (formData.destinationType === "place") {
                                ref = AppConstant.PLACES_ADMIN
                            }
                            if (formData.destinationType === "region") {
                                ref = AppConstant.REGIONS_ADMIN
                            }
                            if (formData.destinationType === "restaurant") {
                                ref = AppConstant.RESTAURANTS_ADMIN
                            }
                            return (
                                <div>
                                    <ReferenceInput label="Destination" validate={required()}
                                                    source={"destinationID"}
                                                    reference={ref}>
                                        <AutocompleteInput optionText="translations[0].title"/>
                                    </ReferenceInput>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export default withDataProvider(TimetableCreate);