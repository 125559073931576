import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import LoopTypeTitle from "./LoopTypeTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const LoopTypeFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const LoopTypeList = props => (
    <List title={<LoopTypeTitle type="Languages"/>}
          filters={<LoopTypeFilter/>}
          {...props}
    >
        <Datagrid>
            <TextField label={"Loop Type"} source="loopTypeTranslations[0].name"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default LoopTypeList;