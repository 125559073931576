import React from "react";
import {
    ArrayInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from "react-admin";
import RegOrderTitle from "./RegOrderTitle";
import {styles} from "../../common/styleField";
import {AppConstant} from "../../providers/constants";
import PropTypes from "prop-types";
import 'tinymce/plugins/image';
import {EditToolbar} from "../toolbar/ToolBar";
import MoveButtons from "../order_buttons/MoveButtons";

const RegOrderEdit = (props) => {
    return (
        <Edit hasDelete={false} title={<RegOrderTitle type={"Edit"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label="SUB-PLACE ORDER">
                    <ReferenceInput label={"Place"} source={"placeID"}
                                    reference={AppConstant.PLACES_ADMIN}>
                        <SelectInput optionText="translations[0].title" disabled/>
                    </ReferenceInput>
                    <ArrayInput
                        label="Sub-Place"
                        source="childRegions"
                        style={styles.boundaries}
                    >
                        <SimpleFormIterator disableAdd disableRemove>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, dispatch, ...rest}) => {
                                    return (
                                        <div>
                                            <TextInput fullWidth source={scopedFormData.title} disabled/>
                                            <MoveButtons formArray={formData.childRegions}
                                                         fieldName="childRegions"
                                                         index={formData.childRegions.indexOf(scopedFormData)}/>
                                        </div>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

RegOrderEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default RegOrderEdit;