import {AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, fetchUtils} from "react-admin"; // eslint-disable-line import/no-unresolved
// eslint-disable-line import/no-unresolved
import {AppConstant} from "./constants";

let jwtDecode = require("jwt-decode");

let refreshID = null;

export const enableInterval = () => {
    //alert('Interval enable')
    refreshID = setInterval(function () {
        refreshToken();
    }, AppConstant.REFRESH_INTERVAL * 1000);
};

export const disableInterval = () => {
    //alert('Interval disable')
    clearInterval(refreshID)
};

function timeoutPromise(ms, promise) {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error("promise timeout"))
        }, ms);
        promise.then(
            (res) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err) => {
                clearTimeout(timeoutId);
                reject(err);
            }
        );
    })
}

export const refreshToken = () => {
    timeoutPromise(3000, getRefreshToken()).then((data) => {
        let refreshStatus = data.code;
        if (refreshStatus === 200) {
            localStorage.setItem("token", data.access_token)
        } else {
            localStorage.removeItem("token");
            localStorage.removeItem("permissions");
            disableInterval()
        }
    }).catch((error) => {
        console.log("Error", error);
    });
};

const getRefreshToken = async () => {
    let url = `${AppConstant.API_URL}/users-cms/refresh`;
    let tokenData = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        },
    });
    return await tokenData.json();
};

// Authenticated by default
export default (type, params, ...rest) => {
    console.log("TYPE", type);
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem("permissions");
        if (role && role !== "") {
            return Promise.resolve(role);
        } else {
            return Promise.reject()
        }
    }

    if (type === AUTH_LOGIN) {
        //console.log("AUTH_LOGIN");
        const {username, password} = params;
        const {fetchJson} = fetchUtils;
        const urlLogin = `${AppConstant.API_URL}/users-cms/login`;
        const bodyRequest = {email: username, password: password};
        const options = {method: "POST", body: JSON.stringify(bodyRequest)};
        return fetchJson(urlLogin, options).then(response => {
            //console.log("response", response);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.status);
            }
            return response.json
        }).then((data) => {
            enableInterval();
            localStorage.setItem("permissions", data.role);
            localStorage.setItem("token", data.token);
        });
    }

    if (type === AUTH_LOGOUT) {
        disableInterval();
        localStorage.removeItem("permissions");
        localStorage.removeItem("token");
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const {status} = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem("permissions");
            localStorage.removeItem("token");
            //disableInterval();
            return Promise.reject()
        }
        return Promise.resolve()
    }

    if (type === AUTH_CHECK) {
        const token = localStorage.getItem("token");
        const role = localStorage.getItem("permissions");
        if (!role) {
            console.log("NO ROLE");
            return Promise.reject();
        }
        if (role === "") {
            console.log("EMPTY ROLE");
            return Promise.reject();
        }
        if (!token) {
            console.log("NULL TOKEN");
            return Promise.reject();
        }
        if (token === "") {
            console.log("EMPTY TOKEN");
            return Promise.reject();
        }
        if (token === "undefined") {
            console.log("Undefined TOKEN");
            return Promise.reject();
        }
        //const token = localStorage.getItem("token");
        if (!(token && token !== "" && token !== "undefined")) {
            return Promise.reject()
        }
    }
    return Promise.resolve();
};

// export const getRefreshToken = () => {
//     //let url = `${AppConstant.API_URL}/users-cms/refresh`;
//     // let promiseJson = await fetch(url, {
//     //     method: "POST",
//     //     headers: {
//     //         Authorization: "Bearer " + localStorage.getItem("token")
//     //     },
//     // }).then((response) => {
//     //     status = response.status;
//     //     return response.json()
//     // }).then((responseData) => {
//     //     if (responseData) {
//     //         localStorage.setItem("token", responseData.access_token)
//     //     }
//     // });
//    refreshToken().then((data) => {
//        let status = data.code;
//        if (status === 200) {
//            localStorage.setItem("token", data.access_token)
//        } else {
//            localStorage.removeItem("token")
//        }
//        return status
//     });
//     //debugger
//     //status = responseJson.code;
// };

