export const styles = {
    translations: {display: "inline-block", width: "90%", padding: '20px'},
    timetables: {display: "inline-block", width: "90%"},
    images: {display: "inline-block", width: "90%", padding: "10px"},
    boundaries: {display: "inline-block", width: "90%"},
    inputInline: {display: "inline", float: "left"},
    inputBlock: {display: "inline-block"},
    marginLeft: {marginLeft: 50},
};

