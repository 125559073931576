import React from 'react';
import {Datagrid, EditButton, Filter, List, ReferenceField, SearchInput, TextField} from 'react-admin';
import FeedbackTitle from "./FeedbackTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const FeedbackFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const FeedbackList = props => (
    <List title={<FeedbackTitle type="Feedback"/>}
          filters={<FeedbackFilter/>}
          {...props}
    >
        <Datagrid>
            <ReferenceField label={"User"} source="userID" reference="users-admin">
                <TextField source="email"/>
            </ReferenceField>
            <TextField source="feedback"/>
            <TextField source={"createdAt"}/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation label={""}/>
        </Datagrid>
    </List>
);

export default FeedbackList;