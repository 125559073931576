import React from 'react';
import {
    ArrayInput,
    Edit,
    FormTab,
    FunctionField,
    ImageField,
    ImageInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    useNotify
} from 'react-admin';
import RestaurantPanoramaNotationsTitle from './RestaurantPanoramaNotationsTitle';
import {AppConstant} from "../../providers/constants";
import PropTypes from "prop-types";
import {EditToolbar} from "../toolbar/ToolBar";
import ShowPanorama from "../paranoma_images/ShowPanorama";
import ImageViewer from "../image_viewer/ImageViewer";

const RestaurantPanoramaNotationsEdit = (props) => {
    const notify = useNotify();

    const validateTranslations = (values) => {
        for (let i = 0; i < values.length - 1; i++) {
            if (!values[i]) {
                continue
            }
            if (!values[i].languageCode) {
                continue
            }
            for (let j = i + 1; j < values.length; j++) {
                if (!values[j]) {
                    continue
                }
                if (!values[j].languageCode) {
                    continue
                }
                if (values[i].languageCode === values[j].languageCode) {
                    notify(`Duplicate language code at index ${i} and ${j}`, 'error');
                    return `error: Duplicate Language Code At Record ${i} And ${j}`;
                }
            }
        }
    };

    return (
        <Edit title={<RestaurantPanoramaNotationsTitle type={"Edit"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label="General">
                    <FunctionField
                        id="thumbnail"
                        label="Thumbnail"
                        render={record => {
                            return (
                                <div>
                                    <ImageViewer
                                        url={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                                        width={AppConstant.IMAGES_WIDTH}
                                        height={AppConstant.IMAGES_HEIGHT}/>
                                </div>
                            )
                        }}/>
                    <ImageInput source="thumbnail" label="Input Thumbnail" accept="image/*">
                        <ImageField source="thumbnail" title=""/>
                    </ImageInput>
                    <FunctionField
                        id="panorama"
                        label="Panorama Picture"
                        render={record => {
                            return (
                                <div>
                                    <ShowPanorama url={`${AppConstant.SERVER_IMAGE}${record.imagePath.url}`}/>
                                </div>
                            )
                        }}/>
                    <ArrayInput source="panoramaTranslations" validate={validateTranslations}>
                        <SimpleFormIterator>
                            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                            perPage={10} reference={"languages-admin"}>
                                <SelectInput source={"languageCode"} optionText="code" optionValue="code"/>
                            </ReferenceInput>
                            <TextInput label="Notation" fullWidth source="notation"/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

RestaurantPanoramaNotationsEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default RestaurantPanoramaNotationsEdit;
