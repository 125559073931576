import PlaceTypesList from './PlaceTypesList';
import PlaceTypesCreate from './PlaceTypesCreate';
import PlaceTypesEdit from './PlaceTypesEdit';
// import PlaceTypesIcon  from '@material-ui/icons/PlaceTypes';

export default {
    list: PlaceTypesList,
    create: PlaceTypesCreate,
    edit: PlaceTypesEdit,
    // icon: PlaceTypesIcon
};