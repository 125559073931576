import React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    useNotify
} from "react-admin";
import Button from "@material-ui/core/Button";
import PTranslationsTitle from "./PTranslationsTitle";
import {styles} from "../../common/styleField";
import {AppConstant} from "../../providers/constants";
import PropTypes from "prop-types";
import TinyMCEInput from "@palustris/ra-tinymce-input";
import Popup from "reactjs-popup";
import {EditToolbar} from "../toolbar/ToolBar";
import {TinyMceInit} from "../tinymce_init/TinyMceInit";


const PTranslationsEdit = (props) => {
    const notify = useNotify();
    const validateTranslation = (values) => {
        if (!values || values.length === 0) {
            notify(`Translation can not be empty`, 'error');
            return `Translation can not be empty`;
        }
        for (let i = 0; i < values.length - 1; i++) {
            for (let j = i + 1; j < values.length; j++) {
                if (values[i].languageCode === values[j].languageCode) {
                    notify(`Duplicate language code at index ${i} and ${j}`, 'error');
                    return `error: Duplicate Language Code At Record ${i} And ${j}`;
                }
            }
        }
    };

    return (
        <Edit title={<PTranslationsTitle type={"Edit" + " "}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label="Translations">
                    <ArrayInput source="translations" style={styles.translations} validate={validateTranslation}>
                        <SimpleFormIterator>
                            <TextInput fullWidth label="Title" source="title" validate={required()}/>
                            <TextInput fullWidth label="Address" source="address"/>
                            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                            perPage={10} reference="languages-admin">
                                <AutocompleteInput source="languageCode" optionText={"code"} optionValue={"code"}/>
                            </ReferenceInput>
                            <TextInput fullWidth label="Travel Tips" source="hint" multiline/>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.languageCode) {
                                        let audioLink = `${AppConstant.RESOURCES}${scopedFormData.audio}`;
                                        let videoLink = `${AppConstant.RESOURCES}${scopedFormData.video}`;
                                        return (
                                            <React.Fragment>
                                                <TextInput fullWidth multiline label={"Short Description"}
                                                           source={getSource("shortDescription")}/>
                                                <Popup
                                                    className={"popup"}
                                                    trigger={
                                                        <Button
                                                            name={"fullDescription" + getSource("translations")}
                                                            type="button"
                                                            style={{
                                                                marginLeft: 10,
                                                                marginBottom: 20,
                                                                verticalAlign: "middle"
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            component="span"
                                                        >
                                                            Show Full Description
                                                        </Button>
                                                    }
                                                    contentStyle={{
                                                        width: AppConstant.TINY_WIDTH,
                                                        height: AppConstant.TINY_HEIGHT,
                                                    }}
                                                    modal
                                                    closeOnEscape
                                                    closeOnDocumentClick
                                                >
                                                    <TinyMCEInput
                                                        label={"Full Description"}
                                                        source={getSource("fullDescription")}
                                                        init={TinyMceInit(AppConstant.PLACES_ADMIN, formData.id, scopedFormData.languageCode)}
                                                    />
                                                </Popup>
                                                <TextInput fullWidth label="Audio Path" source={getSource("audio")}
                                                           disabled={true}/>
                                                {scopedFormData.audio ?
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                name={"showAudio" + getSource("translations")}
                                                                type="button"
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginBottom: 20,
                                                                    verticalAlign: "middle"
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                            >
                                                                Play Audio
                                                            </Button>
                                                        }
                                                        contentStyle={{
                                                            width: AppConstant.TINY_WIDTH,
                                                        }}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        <div>
                                                            <audio style={{
                                                                width: AppConstant.TINY_WIDTH
                                                            }} controls>
                                                                <source
                                                                    src={audioLink}/>
                                                            </audio>
                                                        </div>
                                                    </Popup>
                                                    : null}
                                                <FileInput source={getSource("audio")} label="Audio file"
                                                           accept="audio/*">
                                                    <FileField source={getSource("audio")} title="title"/>
                                                </FileInput>
                                                <TextInput fullWidth label="Video Path" source={getSource("video")}
                                                           disabled={true}/>
                                                {scopedFormData.video ?
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                name={"showVideo" + getSource("translations")}
                                                                type="button"
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginBottom: 20,
                                                                    verticalAlign: "middle"
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                            >
                                                                Play Video
                                                            </Button>
                                                        }
                                                        contentStyle={{
                                                            width: AppConstant.TINY_WIDTH,
                                                            height: AppConstant.TINY_HEIGHT,
                                                        }}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        <div>
                                                            <video width={AppConstant.TINY_WIDTH}
                                                                   height={AppConstant.TINY_HEIGHT} controls>
                                                                <source
                                                                    src={videoLink}/>
                                                            </video>
                                                        </div>
                                                    </Popup>
                                                    : null}
                                                <FileInput source={getSource("video")} label="Video file"
                                                           accept="video/*">
                                                    <FileField source={getSource("video")} title="title"/>
                                                </FileInput>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return null;
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

PTranslationsEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default PTranslationsEdit;