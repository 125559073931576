import React from "react";
import {
    ArrayInput,
    Edit,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from "react-admin";
import Button from "@material-ui/core/Button";
import ObjTranslationsTitle from "./ObjTranslationsTitle";
import {styles} from "../../common/styleField";
import {AppConstant} from "../../providers/constants";
import PropTypes from "prop-types";
import TinyMCEInput from "@palustris/ra-tinymce-input";
import Popup from "reactjs-popup";
import {EditToolbar} from "../toolbar/ToolBar";
import {TinyMceInit} from "../tinymce_init/TinyMceInit";

const ObjTranslationsEdit = (props) => {
    return (
        <Edit title={<ObjTranslationsTitle type={"Edit"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label="TRANSLATIONS">
                    <ArrayInput source="translations" style={styles.translations}>
                        <SimpleFormIterator>
                            <TextInput fullWidth label="Title" source="title" validate={required()}/>
                            <TextInput fullWidth label="Address" source="address"/>
                            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                            perPage={10} reference="languages-admin">
                                <SelectInput source="languageCode" optionText={"code"} optionValue={"code"}/>
                            </ReferenceInput>
                            <TextInput fullWidth label="Travel Tips" source="hint"/>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    console.log("Scoped", scopedFormData);
                                    if (scopedFormData && scopedFormData.languageCode) {
                                        let audioLink = `${AppConstant.RESOURCES}${scopedFormData.audio}`;
                                        let videoLink = `${AppConstant.RESOURCES}${scopedFormData.video}`;
                                        return (
                                            <React.Fragment>
                                                <TextInput multiline fullWidth label={"Short Description"}
                                                           source={getSource("shortDescription")}/>
                                                <Popup
                                                    className={"popup"}
                                                    trigger={
                                                        <Button
                                                            name={"fullDescription" + getSource("translations")}
                                                            type="button"
                                                            style={{
                                                                marginLeft: 10,
                                                                marginBottom: 20,
                                                                verticalAlign: "middle"
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                            component="span"
                                                        >
                                                            Show Full Description
                                                        </Button>
                                                    }
                                                    contentStyle={{
                                                        width: AppConstant.TINY_WIDTH,
                                                        height: AppConstant.TINY_HEIGHT,
                                                    }}
                                                    modal
                                                    closeOnEscape
                                                    closeOnDocumentClick
                                                >
                                                    <TinyMCEInput
                                                        label={"Full Description"}
                                                        source={getSource("fullDescription")}
                                                        init={TinyMceInit(AppConstant.OBJECTS_ADMIN, formData.id, scopedFormData.languageCode)}
                                                    />
                                                </Popup>
                                                <TextInput fullWidth label="Audio Path" source={getSource("audio")}
                                                           disabled={true}/>
                                                {scopedFormData.audio ?
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                name={"showAudio" + getSource("translations")}
                                                                type="button"
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginBottom: 20,
                                                                    verticalAlign: "middle"
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                            >
                                                                Play Audio
                                                            </Button>
                                                        }
                                                        contentStyle={{
                                                            width: AppConstant.TINY_WIDTH,
                                                        }}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        <div>
                                                            <audio style={{
                                                                width: AppConstant.TINY_WIDTH
                                                            }} controls>
                                                                <source
                                                                    src={audioLink}/>
                                                            </audio>
                                                        </div>
                                                    </Popup>
                                                    : null}
                                                <FileInput source={getSource("audio")} label="Audio file"
                                                           accept="audio/*">
                                                    <FileField source={getSource("audio")} title="title"/>
                                                </FileInput>
                                                <TextInput fullWidth label="Video Path" source={getSource("video")}
                                                           disabled={true}/>
                                                {scopedFormData.video ?
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                name={"showVideo" + getSource("translations")}
                                                                type="button"
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginBottom: 20,
                                                                    verticalAlign: "middle"
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                            >
                                                                Play Video
                                                            </Button>
                                                        }
                                                        contentStyle={{
                                                            width: AppConstant.TINY_WIDTH,
                                                            height: AppConstant.TINY_HEIGHT,
                                                        }}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        <div>
                                                            <video width={AppConstant.TINY_WIDTH}
                                                                   height={AppConstant.TINY_HEIGHT} controls>
                                                                <source
                                                                    src={videoLink}/>
                                                            </video>
                                                        </div>
                                                    </Popup>
                                                    : null}
                                                <FileInput source={getSource("video")} label="Video file"
                                                           accept="video/*">
                                                    <FileField source={getSource("video")} title="title"/>
                                                </FileInput>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return null;
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

ObjTranslationsEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default ObjTranslationsEdit;