import React from "react";
import ObjectTitle from './ObjectTitle';
import {AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput} from "react-admin";

const ObjectCreate = props => (
    <Create title={<ObjectTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput
                label="Name"
                source="translations[0].title"
                validate={required()}
            />
            <ReferenceInput label="Language Code" validate={required()} source="translations[0].languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
            <ReferenceInput label="Region" validate={required()} source="regionID" reference="regions-admin">
                <AutocompleteInput optionText="translations[0].title"/>
            </ReferenceInput>
            <TextInput
                label="Link"
                source="link"
            />
            <TextInput fullWidth label="Search Tags" source="searchTags"/>
        </SimpleForm>
    </Create>
);

export default ObjectCreate;
