import {stringify} from "query-string";
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from "react-admin";
import React from "react";
import {AppConstant} from "./constants";

// const updateRestaurantSignature = async (obj, form) => {
//     let url = `${AppConstant.API_URL}/${AppConstant.RESTAURANTS_ADMIN}/${params.id}/signature/image`;
//     return await fetch(url, {
//         method: 'post',
//         headers: {
//             Authorization: "Bearer " + localStorage.getItem("token")
//         },
//         body: form
//     }).then((response) => {
//         if (response.status !== 200) {
//             showNotification('Update failed');
//             return
//         }
//         return response.json()
//     }).then((resp) => {
//         for (let i = 0; i < resp.data.signature.length; i++) {
//             params.data['signature'][i].image = resp.data.signature[i].image
//         }
//         console.log("Object", obj);
//         return obj
//     })
// };

const restaurantUpdate = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            //console.log("Key", key, "->", "Value", params.data[key]);
            if (key === 'thumbnail') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    if (params.data[key].rawFile) {
                        form.append(`newThumbnail[]`, params.data[key].rawFile);
                    } else {
                        form.append(`oldThumbnail[]`, params.data[key])
                    }
                }
                continue
            }
            if (key === 'images') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`newImages[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`oldImages[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'menu') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`newMenu[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`oldMenu[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'panorama') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`newPanorama[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`oldPanorama[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'signatureImages') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`newSignatureImages[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`oldSignatureImages[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                form.append(`${key}`, JSON.stringify(params.data[key]));
            } else {
                form.append(`${key}`, params.data[key]);
            }
        }
    }
    return form;
};

const commonConvertToForm = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            //console.log("Key", key, "->", "Value", params.data[key]);
            if (key === 'thumbnail') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    if (params.data[key].rawFile) {
                        form.append(`newThumbnail[]`, params.data[key].rawFile);
                    } else {
                        form.append(`oldThumbnail[]`, params.data[key])
                    }
                }
                continue
            }
            if (key === 'ticket') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    if (params.data[key].rawFile) {
                        form.append(`newTicket[]`, params.data[key].rawFile);
                    } else {
                        form.append(`oldTicket[]`, params.data[key].url)
                    }
                }
                continue
            }
            if (key === 'images') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`newImages[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`oldImages[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'panorama') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`newPanorama[]`, params.data[key][i].rawFile);
                        } else {
                            form.append(`oldPanorama[]`, params.data[key][i].url)
                        }
                    }
                }
                continue
            }
            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                form.append(`${key}`, JSON.stringify(params.data[key]));
            } else {
                form.append(`${key}`, params.data[key]);
            }
        }
    }
    return form;
};

const userCmsForm = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            //console.log("Key", key, "->", "Value", params.data[key]);
            if (key === 'userImage') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    if (params.data[key].rawFile) {
                        form.append(`newUserImage[]`, params.data[key].rawFile);
                    } else {
                        form.append(`oldUserImage`, params.data[key]);
                    }
                }
                continue
            }
            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                form.append(`${key}`, JSON.stringify(params.data[key]));
            } else {
                form.append(`${key}`, params.data[key]);
            }
        }
    }
    return form;
};

const articlesUpdate = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            //console.log("Key", key, "->", "Value", params.data[key]);
            if (key === 'images') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`images[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`url[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'articles') {
                if (params.data[key]) {
                    form.append('allArticles', JSON.stringify(params.data[key]))
                }
                continue
            }
            form.append(`${key}`, params.data[key]);
        }
    }
    return form;
};

const fileToBase64 = async (file) =>
    await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.replace(/^data:.+;base64,/, ''));
        reader.onerror = (e) => reject(e)
    });

export default function (apiUrl, httpClient = fetchUtils.fetchJson) {
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = "";
        let options = {};
        options.user = {
            authenticated: true,
            token: "Bearer " + localStorage.getItem("token")
        };
        switch (type) {
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    sort: field,
                    order: order,
                    page: page,
                    perPage: perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }
            case GET_MANY: {
                const query = {
                    id: params.ids
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }

            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "GET";
                break;

            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }

            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;

            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "PUT";
                if (AppConstant.COMMON_DESTINATION.includes(resource)) {
                    options.body = commonConvertToForm(params);
                    break;
                }
                if (resource === AppConstant.USER_CMS) {
                    options.body = userCmsForm(params);
                    break;
                }
                if (resource === AppConstant.TAXI_COMPANY) {
                    let form = new FormData();
                    for (let key in params.data) {
                        if (params.data.hasOwnProperty(key)) {
                            //console.log("Key", key, "->", "Value", params.data[key]);
                            if (key === 'thumbnail') {
                                if (params.data[key]) {
                                    if (params.data[key].rawFile) {
                                        form.append(`thumbnailRaw`, params.data[key].rawFile);
                                    } else {
                                        form.append(`thumbnailUrl`, params.data[key].url);
                                    }
                                }
                            } else {
                                form.append(`${key}`, params.data[key]);
                            }
                        }
                    }
                    options.body = form;
                    break
                }
                if (resource === AppConstant.RESTAURANTS_ADMIN) {
                    options.body = restaurantUpdate(params);
                    break;
                }
                if (resource === AppConstant.CULTURAL_ARTICLES) {
                    options.body = articlesUpdate(params);
                    break;
                }
                if (AppConstant.DESTINATION_TRANSLATION.includes(resource)) {
                    let form = new FormData();
                    for (let key in params.data) {
                        if (params.data.hasOwnProperty(key)) {
                            //console.log("Key", key, "->", "Value", params.data[key]);
                            if (key === 'translations') {
                                if (params.data[key]) {
                                    for (let index = 0; index < params.data[key].length; index++) {
                                        const delimiter = "-";
                                        if (params.data[key][index].audio) {
                                            if (params.data[key][index].audio.rawFile) {
                                                let translation = params.data[key][index];
                                                let newFile = new File([translation.audio.rawFile], translation.languageCode + delimiter + translation.audio.rawFile.name, {type: translation.audio.rawFile.type});
                                                params.data[key][index].audio = "";
                                                form.append('audio[]', newFile);
                                            }
                                        } else {
                                            params.data[key][index].audio = ""
                                        }
                                        if (params.data[key][index].video) {
                                            if (params.data[key][index].video.rawFile) {
                                                let translation = params.data[key][index];
                                                let newFile = new File([translation.video.rawFile], translation.languageCode + delimiter + translation.video.rawFile.name, {type: translation.video.rawFile.type});
                                                params.data[key][index].video = "";
                                                form.append('video[]', newFile);
                                            }
                                        } else {
                                            params.data[key][index].video = "";
                                        }
                                    }
                                }
                            }
                            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                                form.append(`${key}`, JSON.stringify(params.data[key]));
                            } else {
                                form.append(`${key}`, params.data[key]);
                            }
                        }
                    }
                    options.body = form;
                    break
                }
                options.body = JSON.stringify(params.data);
                break;

            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;

            case DELETE_MANY:
                url = `${apiUrl}/${resource}`;
                options.method = "DELETE";
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    const convertHTTPResponse = (response, type, resource, params) => {
        const {headers, json} = response;
        switch (type) {
            case GET_ONE:
                return {
                    data: json.data ? json.data : null,
                };
            case GET_LIST:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case GET_MANY_REFERENCE:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case GET_MANY:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case CREATE:
                return {data: {...params.data, id: json.data.id}};
            case DELETE:
                return {data: {id: null}};
            default:
                return {data: json.data};
        }
    };

    return (type, resource, params) => {
        let {url, options} = convertDataRequestToHTTP(type, resource, params);
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, options))
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
}

