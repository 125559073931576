import React from 'react';
import {
    ArrayInput,
    Edit,
    ReferenceInput,
    required,
    SelectInput,
    showNotification,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import LoopTypeTitle from './LoopTypeTitle';
import {styles} from "../../common/styleField";
import {EditToolbar} from "../toolbar/ToolBar";
import {connect} from 'react-redux';

const LoopTypeEdit = (props) => {
    function hasDuplicateLanguageCode(values) {
        const languageCodeSet = new Set();
        for (let i = 0; i < values.length; i++) {
            if (typeof values[i] === "undefined" || values[i] === null) {
                continue
            }
            if (!values[i].hasOwnProperty("languageCode")) {
                continue
            }
            const languageCode = values[i].languageCode;
            if (!languageCode) {
                continue
            }
            if (languageCodeSet.has(languageCode)) {
                props.showNotification(`Duplicate Language Code At Record ${i + 1}`, "warning");
                return `Error: Duplicate Language Code At Record ${i + 1}`;
            }
            languageCodeSet.add(languageCode);
        }
    }

    return (
        <Edit title={<LoopTypeTitle type="Edit"/>} {...props}>
            <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
                <ArrayInput source="loopTypeTranslations" style={styles.translations}
                            validate={[hasDuplicateLanguageCode]}>
                    <SimpleFormIterator>
                        <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                        perPage={10} reference="languages-admin">
                            <SelectInput source="languageCode" optionText={"code"} optionValue={"code"}/>
                        </ReferenceInput>
                        <TextInput label={"Loop Type"} source="name" validate={required()}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
};

export default connect(undefined, {showNotification})(LoopTypeEdit);