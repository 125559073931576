import FeedbackList from './FeedbackList';
import FeedBackCreate from './FeedBackCreate';
import FeedbackEdit from './FeedbackEdit';
import FeedbackIcon from '@material-ui/icons/Feedback';

export default {
    list: FeedbackList,
    create: FeedBackCreate,
    edit: FeedbackEdit,
    icon: FeedbackIcon
};