import React from "react";
import RegionTitle from "./RegionTitle";
import {AppConstant} from "../../providers/constants";
import {AutocompleteInput, Create, NumberInput, ReferenceInput, required, SimpleForm, TextInput} from "react-admin";

const typeOptions = [
    {id: "sightseeing", name: "Sightseeing"},
    {id: "hotel", name: "Hotel"},
    {id: "restaurant", name: "Restaurant"},
    {id: "restroom", name: "Restroom"}
];

const RegionCreate = props => (
    <Create title={<RegionTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput
                label="Name"
                source="translations[0].title"
                validate={required()}
            />
            <ReferenceInput label="Parent Sub-Place" source="regionID" reference="regions-admin">
                <AutocompleteInput optionText="translations[0].title"/>
            </ReferenceInput>
            <ReferenceInput label="Language Code" validate={required()} source="translations[0].languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
            <ReferenceInput label="Parent Place" validate={required()} source="parentID"
                            reference={AppConstant.PLACES_ADMIN}>
                <AutocompleteInput optionText="translations[0].title"/>
            </ReferenceInput>
            <ReferenceInput label="Type" validate={required()} source="typeId"
                            reference="place-type">
                <AutocompleteInput optionText="type"/>
            </ReferenceInput>
            {/*<SelectInput source="type" validate={required()} choices={typeOptions} />*/}
            <TextInput fullWidth label="Search Tags" source="searchTags"/>
            <TextInput source="owner"/>
            <TextInput fullWidth source="addressCode"/>
            <TextInput source="openTime" type="time"/>
            <TextInput source="closeTime" type="time"/>
            <TextInput
                label="Link"
                source="link"
            />
            <NumberInput source="priceRate"/>
        </SimpleForm>
    </Create>
);

export default RegionCreate;
