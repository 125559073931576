import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import LanguageTitle from "./LanguageTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const LanguageFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const LanguageList = props => (
    <List title={<LanguageTitle type="Languages"/>}
          filters={<LanguageFilter/>}
          {...props}
    >
        <Datagrid>
            <TextField source="code"/>
            <TextField source="title"/>
            <TextField source="currency"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default LanguageList;