import RegionPanoramaNotationsList from './RegionPanoramaNotationsList';
//import RegionPanoramaNotationsCreate from './RegionPanoramaNotationsCreate';
import RegionPanoramaNotationsEdit from './RegionPanoramaNotationsEdit';
import PanoramaIcon from '@material-ui/icons/Panorama';

export default {
    list: RegionPanoramaNotationsList,
    //create: RegionPanoramaNotationsCreate,
    edit: RegionPanoramaNotationsEdit,
    icon: PanoramaIcon
};