import FreeAudioList from './FreeAudioList';
import FreeAudioEdit from './FreeAudioEdit';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import FreeAudioCreate from "./FreeAudioCreate";

export default {
    list: FreeAudioList,
    edit: FreeAudioEdit,
    create: FreeAudioCreate,
    icon: AudiotrackIcon,
};