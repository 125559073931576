import React from 'react';
import {
    AutocompleteInput,
    Edit,
    FunctionField,
    ImageField,
    ImageInput,
    maxLength,
    minLength,
    PasswordInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import UserCmsTitle from './UserCmsTitle';
import {AppConstant} from "../../providers/constants";
import {EditToolbar} from "../toolbar/ToolBar";
import ImageViewer from "../image_viewer/ImageViewer";

const validatePassword = [minLength(3), maxLength(20)];

const UserCmsEdit = ({permissions, ...props}) => (
    <Edit title={<UserCmsTitle type="Edit"/>} {...props}>
        <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
            <TextInput source="firstName" validate={required()}/>
            <TextInput source="lastName" validate={required()}/>
            <TextInput source="username"/>
            <TextInput source="email" validate={required()}/>
            <PasswordInput label="New Password" source="password" validate={validatePassword}/>
            {permissions === "Admin" ?
                <ReferenceInput label="Role" validate={required()} source="role"
                                reference="place-type">
                    <AutocompleteInput optionText="type"/>
                </ReferenceInput> :
                <ReferenceInput label="Role" validate={required()} source="role"
                                reference="place-type">
                    <SelectInput disabled optionText="type"/>
                </ReferenceInput>}
            <FunctionField
                id="userImage"
                label="User Image"
                render={record => {
                    if (!record.userImage || record.userImage === "") {
                        return null;
                    }
                    return (
                        <ImageViewer url={`${AppConstant.SERVER_IMAGE}${record.userImage}`}
                                     width={AppConstant.IMAGES_WIDTH}
                                     height={AppConstant.IMAGES_HEIGHT}/>
                    );
                }}
            />
            <ImageInput source="userImage" label="Input Images" accept="image/*">
                <ImageField source="userImage" title=""/>
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export default UserCmsEdit;