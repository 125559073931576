import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import TermsOfServiceTitle from './TermsOfServiceTitle';
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const TermsOfServiceFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const TermsOfServiceList = props => (
    <List title={<TermsOfServiceTitle type="Terms Of Service"/>} filters={<TermsOfServiceFilter/>} {...props}>
        <Datagrid>
            <TextField label={"Language Code"} source="languageCode"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default TermsOfServiceList;