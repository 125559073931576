export const MarkerValidate = (location, position, markerPosition) => {
    //console.log("dhashidas", location)
    if (
        !location ||
        !location.coordinates.lat ||
        !location.coordinates.long
    ) {
        position[0] = 0;
        position[1] = 0;
        markerPosition[0] = 0;
        markerPosition[1] = 0;
    } else {
        if (position[0] === 0 && position[1] === 0) {
            position[0] = location.coordinates.lat;
            position[1] = location.coordinates.long;
        }

        if (markerPosition[0] === 0 && markerPosition[1] === 0) {
            markerPosition[0] = location.coordinates.lat;
            markerPosition[1] = location.coordinates.long;
        }
    }
    if (position[0] === 0 && position[1] === 0) {
        position[0] = 16.46772;
        position[1] = 107.57914;
    }
    if (markerPosition[0] === 0 && markerPosition[1] === 0) {
        markerPosition[0] = 16.46772;
        markerPosition[1] = 107.57914;
    }
    if (!markerPosition) {
        markerPosition[0] = 16.46772;
        markerPosition[1] = 107.57914;
    }
    if (markerPosition[0] === null || markerPosition[1] === null) {
        markerPosition[0] = 16.46772;
        markerPosition[1] = 107.57914;
    }
};
