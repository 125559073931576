import React from "react";
import {
    AutocompleteInput,
    Create,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import LoopTitle from "./LoopTitle";

const TimeUnit = [
    {id: "day", name: "Day"},
    {id: "hour", name: "Hour"},
];

const LoopCreate = props => (
    <Create title={<LoopTitle type="Create"/>} {...props}>
        <SimpleForm>
            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
            <TextInput fullWidth label={"Title"} validate={required()} source={"title"}/>
            <ReferenceArrayInput label={"Loop Types"} source="loopTypes" reference="loop-types-admin">
                <SelectArrayInput optionText="loopTypeTranslations[0].name" optionValue={"id"}/>
            </ReferenceArrayInput>
            <SelectInput source="unitTime" validate={required()} choices={TimeUnit}/>
        </SimpleForm>
    </Create>
);

export default LoopCreate;