import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import ContactTitle from './ContactTitle';
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const ContactFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const ContactList = props => (
    <List title={<ContactTitle type="Contacts"/>} filters={<ContactFilter/>} {...props}>
        <Datagrid>
            <TextField label={"Title"} source="title"/>
            <TextField label={"Phone Number"} source="phoneNumber"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default ContactList;