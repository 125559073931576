import React, {Component} from "react";
import {Link, withRouter} from 'react-router-dom'
import Button from "@material-ui/core/Button";
import {AppConstant} from "../../providers/constants";
import myDataProvider from "../../providers/myDataProvider";

const dataProvider = myDataProvider(AppConstant.API_URL);

class LinkButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: ""
        };
        this.customLink = this.customLink.bind(this)
    }

    componentDidMount() {
        const {source} = this.props;
        if (source) {
            this.setState({link: source})
        } else {
            this.setState({link: ""})
        }
    };

    customLink = props => {
        const id = this.state.link;
        return (
            <Link to={`/timetable/${id}`} {...props} />
        )
    };

    render() {
        return (
            <Button
                id="buttonLink"
                style={{
                    marginRight: '25px',
                    verticalAlign: "middle"
                }}
                variant="contained"
                color="primary"
                component={this.customLink}>
                Loop Details
            </Button>
        )
    }
}

export default withRouter(LinkButton);
