import React from "react";
import {Create, minValue, number, NumberInput, required, SimpleForm} from "react-admin";
import QRCodeTitle from "./QRCodeTitle";

const redirect = (basePath, id, data) => {
    return `/qr-admin/${id}`;
};

//const maxQuantity = Math.floor(AppConstant.PAPER_SIZE[0] * AppConstant.PAPER_SIZE[1] / Math.pow(AppConstant.QR_SIZE, 2));

const validateQuantity = [number(), minValue(1), required()];
const validatePrice = [number(), minValue(0), required()];

const QRCreate = props => (
    <Create title={<QRCodeTitle type="Create"/>} {...props}>
        <SimpleForm redirect={redirect}>
            <NumberInput label={"Price Rate in VND"} source={"priceRate"} validate={validatePrice}/>
            <NumberInput label={"Quantity"} source={"quantity"} validate={validateQuantity}/>
        </SimpleForm>
    </Create>
);

export default QRCreate;