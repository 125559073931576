import React from "react";
import {Datagrid, EditButton, Filter, FunctionField, List, ReferenceField, SearchInput, TextField} from "react-admin";
import NewSLocationTitle from "./NewSLocationTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";
import {AppConstant} from "../../providers/constants";

const NewSLocationFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const NewSLocationList = props => (
    <List
        title={<NewSLocationTitle type="Co-Locations"/>}
        filters={<NewSLocationFilter/>}
        {...props}
    >
        <Datagrid>
            <FunctionField
                label="Link"
                render={record => {
                    let ref = "";
                    if (record && record.destinationType) {
                        if (record.destinationType === "place") {
                            ref = AppConstant.PLACES_ADMIN
                        }
                        if (record.destinationType === "region") {
                            ref = AppConstant.REGIONS_ADMIN
                        }
                        if (record.destinationType === "restaurant") {
                            ref = AppConstant.RESTAURANTS_ADMIN
                        }
                        return (
                            <ReferenceField basePath={ref} label="Parent"
                                            record={record}
                                            source={"destinationID"}
                                            reference={ref}>
                                <TextField source="translations[0].title"/>
                            </ReferenceField>
                        );
                    } else {
                        return null;
                    }
                }}
            />
            <TextField label={"Destination Type"} source={"destinationType"}/>,
            <TextField label={"Location Longitude"} source={"location[0].coordinates.long"}/>,
            <TextField label={"Location Latitude"} source={"location[0].coordinates.lat"}/>
            <EditButton label={""} style={{color: "#ffa24a"}}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default NewSLocationList;
