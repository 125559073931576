import React from 'react';
import {
    Datagrid,
    EditButton,
    EmailField,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    SearchInput,
    TextField
} from 'react-admin';
import UserCmsTitle from './UserCmsTitle';
import {AppConstant} from "../../providers/constants";

const UserFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const UserCmsList = ({permissions, ...props}) => (
    <List title={<UserCmsTitle type="Users"/>} filters={<UserFilter/>} {...props}>
        <Datagrid>
            <TextField source="firstName"/>
            <TextField source="lastName"/>
            <TextField source="username"/>
            <FunctionField
                label="Image"
                render={record => {
                    if (!record.userImage || record.userImage === "") {
                        return null;
                    }
                    return (
                        <img
                            alt={record.defaultImage}
                            src={`${AppConstant.SERVER_IMAGE}${record.userImage}`}
                            width={80}
                            height={80}
                        />
                    );
                }}
            />
            <EmailField source="email"/>
            <ReferenceField label={"Role"} source="role" reference="place-type" linkType={false}>
                <TextField source="type"/>
            </ReferenceField>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
        </Datagrid>
    </List>
);

export default UserCmsList;