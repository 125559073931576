import {AppConstant} from "../../providers/constants";
import tinymce from 'tinymce/tinymce.js';
import 'tinymce/icons/default/icons'
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/nonbreaking';
import React from "react";
import {showNotification} from "react-admin";
import './tiny.css'

// let imageLink = "http://171.244.51.143:8085/resources/places/5d564bc98f9c33137482f7c7/images/c805d736-dee5-4476-b68b-40876d580b19.jpg";
//
// const imageList = [
//     "http://171.244.51.143:8085/resources/restaurants/5d7b40788f9c33282823d57a/images/82ccb1b8-1e22-4cff-ad9a-4fb30811b87f.JPG",
//     "http://171.244.51.143:8085/resources/places/5d564bc98f9c33137482f7c7/images/c805d736-dee5-4476-b68b-40876d580b19.jpg",
//     "http://171.244.51.143:8085/resources/restaurants/5d7b40788f9c33282823d57a/images/14c8b0d2-b1d8-4fbf-a92a-f35d40e07efe.JPG",
// ];
//
// function f() {
//     alert("dhasb")
// }

// const html = [
//     '<figure><img id="imageID" src="' + imageList[0] + '" alt="Hello"><figcaption>Nhà hàng Chay</figcaption></figure>' + '<script type="text/javascript"> window.onload += function () {document.getElementById("imageID").addEventListener("click", myFunction, false)}' + 'function myFunction() {' + 'alert("ABC")' + '}' + '<\/script>',
//     '<img id="imageID" src="' + imageList[1] + '" alt="Hello">' + '<script type="text/javascript">document.getElementById("imageID").addEventListener("click", myFunction, false); ' + 'function myFunction() {' + 'console.log("ABC")' + '}' + '<\/script>',
//     '<img id="imageID" src="' + imageList[2] + '" alt="Hello">' + '<script type="text/javascript">document.getElementById("imageID").addEventListener("click", myFunction, false); ' + 'function myFunction() {' + 'console.log("ABC")' + '}' + '</script>',
// ];

const column = 20;

window.tinymce = tinymce;

function headers(imagesList) {
    return [];
    // let headers = [];
    //  if (imagesList.length > column) {
    //      for (let i = 0; i < column; i++) {
    //          let tmp = 'Column ' + (i + 1).toString();
    //          headers.push(tmp)
    //      }
    //      return headers
    //  }
    //  imagesList.forEach((value, index) => {
    //      let tmp = 'Column ' + (index + 1).toString();
    //      headers.push(tmp)
    //  });
    //  return headers
}

function createFontSizeList() {
    let sizeArray = [];
    for (let i = 8; i < 31; i++) {
        sizeArray.push(i.toString() + "pt")
    }
    return sizeArray.join(' ')
}

function imageHTMLDisplay(imagesList) {
    let result = [];
    let html = [];
    //let flag = false;
    imagesList.forEach((value, index) => {
        let tmp = '<figure id="figureID"><img id="imageID" src="' + value.src + '">' +
            '<figcaption id="caption-0">'
            + (index + 1).toString() +
            '</figcaption>' +
            '</figure>';
        html.push(tmp);
        // if (html.length % column === 0) {
        //     if (flag === false) {
        //         result.push(html);
        //         html = [];
        //         flag = true
        //     }
        // }
    });
    result.push(html);
    return result
}

function selection(imagesList) {
    let result = [];
    imagesList.forEach((value, index) => {
        let tmp = (index + 1).toString();
        result.push({value: value.src, text: tmp})
    });
    //debugger
    return result
}

function selectionLink(list) {
    let result = [];
    list.forEach((item, index) => {
        result.push({value: item.value, text: item.title})
    });
    //debugger
    return result
}

function customDialogConfig(imageList) {
    return {
        title: 'Beta Image Selector',
        size: 'medium',
        body: {
            type: 'panel',
            items: [
                {
                    type: 'table',
                    header: headers(imageList),
                    cells: imageHTMLDisplay(imageList),
                },
                {
                    type: 'selectbox', // component type
                    name: 'selectImage', // identifier
                    label: 'Select Image Label',
                    size: 1, // number of visible values (optional)
                    items: selection(imageList)
                },
                {
                    type: 'input', // component type
                    name: 'caption', // identifier
                    inputMode: 'text',
                    label: 'Caption', // text for the label
                    placeholder: 'Input caption here', // placeholder text for the input
                }
            ]
        },
        buttons: [
            {
                type: 'cancel',
                name: 'closeButton',
                text: 'Cancel'
            },
            {
                type: 'submit',
                name: 'submitButton',
                text: 'Insert',
                primary: true
            }
        ],
        initialData: {
            selectImage: '',
            caption: '',
        },
        onSubmit: function (api) {
            let innerInsert = '';
            let data = api.getData();
            //console.log("data", data);
            //console.log("imageList", imageList);
            let i = imageList.findIndex((currentValue, index, arr) => {
                return currentValue.src === data.selectImage
            }, data.selectImage);
            if (imageList[i].width > imageList[i].height) {
                if (data.caption === '') {
                    innerInsert =
                        '<p><img src="' + data.selectImage + '" width="316" height="213"/></p>'
                } else {
                    innerInsert =
                        '<p>' +
                        '<figure class="image">' +
                        '<img src="' + data.selectImage + '" width="316" height="213" alt=""/>' +
                        '<figcaption>' + data.caption +
                        '</figcaption>' +
                        '</figure>' +
                        '</p>' +
                        '<p>' +
                        '</p>'
                }
            } else {
                if (data.caption === '') {
                    innerInsert =
                        '<p><img src="' + data.selectImage + '" width="213" height="316"/></p>'
                } else {
                    innerInsert =
                        '<p>' +
                        '<figure class="image">' +
                        '<img src="' + data.selectImage + '" width="213" height="316" alt=""/>' +
                        '<figcaption>' + data.caption +
                        '</figcaption>' +
                        '</figure>' +
                        '</p>' +
                        '<p>' +
                        '</p>'
                }
            }
            //console.log("Data", data);
            tinymce.activeEditor.execCommand('mceInsertContent', false, innerInsert);
            api.close();
        }
    }
}

function customInsertLinkDialogConfig(list) {
    return {
        title: 'Insert Article Link Selector',
        size: 'medium',
        body: {
            type: 'panel',
            items: [
                {
                    type: 'selectbox', // component type
                    name: 'selectLink', // identifier
                    label: 'Select Link',
                    size: 1, // number of visible values (optional)
                    items: selectionLink(list)
                },
                {
                    type: 'input', // component type
                    name: 'linkTitle', // identifier
                    inputMode: 'text',
                    label: 'Link Title', // text for the label
                    placeholder: 'Input Title here', // placeholder text for the input
                },
                {
                    type: 'input', // component type
                    name: 'textToDisplay', // identifier
                    inputMode: 'text',
                    label: 'Text To Display', // text for the label
                    placeholder: 'Input Text To Display here', // placeholder text for the input
                },
            ]
        },
        buttons: [
            {
                type: 'cancel',
                name: 'closeButton',
                text: 'Cancel'
            },
            {
                type: 'submit',
                name: 'submitButton',
                text: 'Insert',
                primary: true
            }
        ],
        initialData: {
            selectLink: '',
            linkTitle: 'Article Link',
            textToDisplay: '',
        },
        onSubmit: function (api) {
            let innerInsert = '';
            let data = api.getData();
            if (data.linkTitle === '') {
                tinymce.activeEditor.windowManager.alert('Link Title is required');
                api.close();
                return
            }
            if (data.textToDisplay === '') {
                data.textToDisplay = data.linkTitle
            }
            innerInsert = `<p><a style="text-decoration: none" title="${data.linkTitle}" href="${data.selectLink}">${data.textToDisplay}</a></p>`;
            //console.log("data", data);
            //console.log("imageList", imageList);
            tinymce.activeEditor.execCommand('mceInsertContent', false, innerInsert);
            api.close();
        }
    }
}


export const TinyMceMediaArticlesUpload = (blobInfo, blobCache, cb, file, destinationType, destinationID) => {
    let f = new FormData();
    f.append("file", blobInfo.blob());
    if (blobInfo.blob().name) {
        fetch(`${AppConstant.API_URL}/${destinationType}/${destinationID}/articles`, {
            method: 'post',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: f
        }).then((response) => {
            if (response.status !== 200) {
                showNotification('HTTP Error: ' + response.status);
                return;
            }
            return response.json() //response.json() is resolving its promise. It waits for the body to load
        }).then((data) => {
            blobCache.add(blobInfo);
            cb(data.data.location, {title: file.name});
        });
    }
};

export const TinyMceInit = (destinationType, destinationID, languageCode) => {
    let tools = "preview code lists image image-server media link imagetools undo redo fontsizeselect fontselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent"
    if (
        destinationType === AppConstant.PLACES_ADMIN ||
        destinationType === AppConstant.OBJECTS_ADMIN ||
        destinationType === AppConstant.RESTAURANTS_ADMIN ||
        destinationType === AppConstant.REGIONS_ADMIN
    ) {
        tools = "preview code lists image image-server insert-article media link imagetools undo redo fontsizeselect fontselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent"
    }
    return ({
        plugins: "nonbreaking lists image paste table link imagetools media code advlist preview",
        toolbar: tools,
        file_picker_types: 'media',
        setup: function (editor) {
            editor.ui.registry.addButton('insert-article', {
                icon: 'embed-page',
                tooltip: 'Insert article link',
                onAction: function () {
                    let url = `${AppConstant.API_URL}/${AppConstant.CULTURAL_ARTICLES}/links?language=${languageCode}`;
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            "Accept": "application/json",
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    }).then(function (response) {
                        if (response.status !== 200) {
                            showNotification('HTTP Error: ' + response.status);
                            return;
                        }
                        return response.json() //response.json() is resolving its promise. It waits for the body to load
                    }).then((responseData) => {
                        if (responseData.data && responseData.data.length === 0) {
                            editor.notificationManager.open({
                                text: 'Current language code has no article',
                                type: 'error',
                                timeout: 5000,
                                //closeButton: false
                            });
                        } else {
                            editor.windowManager.open(customInsertLinkDialogConfig(responseData.data))
                        }
                    });
                }
            });
            editor.ui.registry.addButton('image-server', {
                icon: 'gallery',
                tooltip: 'Insert image from server',
                onAction: function () {
                    fetch(`${AppConstant.API_URL}/${destinationType}/${destinationID}/images`, {
                        method: 'GET',
                        headers: {
                            "Accept": "application/json",
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                    }).then(function (response) {
                        if (response.status !== 200) {
                            showNotification('HTTP Error: ' + response.status);
                            return;
                        }
                        return response.json()//response.json() is resolving its promise. It waits for the body to load
                    }).then((responseData) => {
                        let all = [];
                        let images = responseData.data.images;
                        if (!images || images.length === 0) {
                            editor.windowManager.alert("The current record has no image, please upload image");
                            return;
                        }
                        //console.log("images", images);
                        images.map(function (item, index) {
                            let element = {
                                src: `${AppConstant.RESOURCES}${images[index].title}`,
                                width: images[index].width,
                                height: images[index].height,
                            };
                            all.push(element)
                            //all.push(`${AppConstant.RESOURCES}${images[index].title}`)
                        });
                        //console.log("images", images);
                        editor.windowManager.open(customDialogConfig(all))
                    });
                }
            })
        },
        // image_list: function (success) {
        //     fetch(`${AppConstant.API_URL}/${destinationType}/${destinationID}/images`, {
        //         method: 'GET',
        //         headers: {
        //             "Accept": "application/json",
        //             "Authorization": "Bearer " + localStorage.getItem("token")
        //         },
        //     }).then(function (response) {
        //         if (response.status !== 200) {
        //             showNotification('HTTP Error: ' + response.status);
        //             return;
        //         }
        //         return response.json() //response.json() is resolving its promise. It waits for the body to load
        //     }).then((resp) => {
        //         success(resp.data.images);
        //     });
        // },
        file_picker_callback: function (cb, value, meta) {
            let input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'audio/*,video/*');
            input.onchange = function () {
                //console.log(this.files);
                let file = this.files[0];
                let reader = new FileReader();
                reader.onload = function () {
                    let id = 'blobid' + (new Date()).getTime();
                    let blobCache = tinymce.activeEditor.editorUpload.blobCache;
                    let base64 = reader.result.split(',')[1];
                    let blobInfo = blobCache.create(id, file, base64);
                    TinyMceMediaArticlesUpload(blobInfo, blobCache, cb, file, destinationType, destinationID)
                };
                reader.readAsDataURL(file);
            };
            input.click();
        },
        images_upload_handler: function (blobInfo, success, failure) {
            TinyMceImageArticles(blobInfo, success, failure, destinationType, destinationID)
        },
        media_url_resolver: (data, resolve, reject) => {
            if (data.url !== "" || !data.url) {
                let format = data.url.split('.')[data.url.split('.').length - 1];
                //console.log("Format", format);
                if (AppConstant.SUPPORTED_MEDIA_FORMAT.includes(format)) {
                    const embedHtml = `<p><figure><video controls src="${data.url}" width="316" height="213" allow="fullscreen" style="border:none;"></video><figcaption>Input video caption here.....</figcaption></figure></p><p></p>`;
                    resolve({html: `${embedHtml}`});
                } else {
                    if (AppConstant.SUPPORTED_AUDIO_FORMAT.includes(format)) {
                        const embedHtml = `<p><figure><audio controls><source src="${data.url}"></audio><figcaption>Input audio caption here.....</figcaption></p><p></p>`;
                        resolve({html: `${embedHtml}`});
                    }
                }
            } else {
                reject('unsupported media format')
            }
        },
        audio_template_callback: function (data) {
            return '<p><audio controls style="border:none;">' + '\n<source src="' + data.source1 + '"' + (data.source1mime ? ' type="' + data.source1mime + '"' : '') + ' />\n' + '</audio></p><p></p>';
        },
        video_template_callback: (data) => {
            return '<p><video controls src="' + data.source1 + '" width="316" height="213" allow="fullscreen" style="border:none;"></video></p><p></p>';
        },
        media_poster: true,
        media_alt_source: true,
        media_dimensions: false,
        image_advtab: false,
        paste_auto_cleanup_on_paste: true,
        image_description: false,
        image_caption: false,
        //paste_as_text: true,
        images_upload_url: `${AppConstant.API_URL}/${destinationType}/${destinationID}/articles`,
        paste_webkit_styles: "all",
        invalid_elements: "br",
        paste_retain_style_properties: "color font-size",
        //paste_enable_default_filters: false,
        //force_br_newlines: false,
        force_p_newlines: true,
        entity_encoding: "raw",
        height: AppConstant.TINY_HEIGHT / 1.15,
        width: AppConstant.TINY_WIDTH,
        media_live_embeds: true,
        nonbreaking_force_tab: true,
        resize: false,
        //content_css: ['//fonts.googleapis.com/css?family=Indie+Flower'],
        //font_formats: 'Arial Black=arial black,avant garde;Indie Flower=indie flower, cursive;Times New Roman=times new roman,times;',
        fontsize_formats: createFontSizeList(),
    })
};

const TinyMceImageArticles = (blobInfo, success, failure, destinationType, destinationID) => {
    let f = new FormData();
    f.append("file", blobInfo.blob());
    if (blobInfo.blob().name) {
        fetch(`${AppConstant.API_URL}/${destinationType}/${destinationID}/articles`, {
            method: 'post',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: f
        }).then((response) => {
            if (response.status !== 200) {
                failure('HTTP Error: ' + response.status);
                return;
            }
            return response.json() //response.json() is resolving its promise. It waits for the body to load
        }).then((data) => success(data.data.location)).catch((error) => {
            failure(error)
        })
    }
};