import ObjectList from './ObjectList';
import ObjectCreate from './ObjectCreate';
import ObjectEdit from './ObjectEdit';
import ObjectIcon from '@material-ui/icons/LibraryBooks';

export default {
    list: ObjectList,
    create: ObjectCreate,
    edit: ObjectEdit,
    icon: ObjectIcon
};