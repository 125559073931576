import RestaurantTypesList from './RestaurantTypesList';
import RestaurantTypesCreate from './RestaurantTypesCreate';
import RestaurantTypesEdit from './RestaurantTypesEdit';
import ListAltIcon from '@material-ui/icons/ListAlt';

export default {
    list: RestaurantTypesList,
    create: RestaurantTypesCreate,
    edit: RestaurantTypesEdit,
    icon: ListAltIcon
};