import React from 'react';
import {BooleanInput, Edit, required, SimpleForm, TextInput} from 'react-admin';
import PlaceTypesTitle from './PlaceTypesTitle';

const PlaceTypesEdit = props => (
    <Edit title={<PlaceTypesTitle type="Edit"/>} {...props}>
        <SimpleForm>
            <BooleanInput label={"Hide"} source={"isHide"}/>
            <TextInput source="type" validate={required()} disabled={true}/>
        </SimpleForm>
    </Edit>
);

export default PlaceTypesEdit;