import React, {Component} from "react";

import {
    ArrayInput,
    AutocompleteInput,
    Create,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    showNotification,
    SimpleForm,
    SimpleFormIterator
} from "react-admin";
import NewSLocationTitle from "./NewSLocationTitle";
import {AppConstant} from "../../providers/constants";
import {styles} from "../../common/styleField";
import '../map/map.css'
import {TextField} from "@material-ui/core";
import ShowSubLocationMap from "../map/ShowSubLocationMap";

const DestinationType = [
    {id: "place", name: "Place"},
    {id: "region", name: "Sub-Place"},
    {id: "restaurant", name: "Restaurant"}
];

class NewSLocationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainLocation: AppConstant.DEFAULT_LOCATION,
            mainID: "",
            mainTitle: "Main Location",
        };
        this.refmarker = React.createRef();
        this.getMainLocation = this.getMainLocation.bind(this)
    }

    getMainLocation = (id, destinationType) => {
        let url = `${AppConstant.API_URL}/${destinationType}/${id}`;
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
        }).then(response => {
            if (response.status !== 200) {
                showNotification("Error: Request failed", "warning");
            } else {
                return response.json()
            }
        }).then((res) => {
            //console.log("Data", res.data);
            if (!res) {
                this.setState({
                    mainLocation: AppConstant.DEFAULT_LOCATION,
                    mainID: id,
                })
            } else {
                this.setState({
                    mainLocation: [res.data.location.coordinates.lat, res.data.location.coordinates.long],
                    mainID: id,
                    mainTitle: res.data.translations[0].title
                })
            }
        })
    };

    render() {
        const props = this.props;
        return (
            <Create title={<NewSLocationTitle type="Create"/>} {...props}>
                <SimpleForm>
                    <SelectInput label={"Destination Type"} source={"destinationType"} validate={required()}
                                 choices={DestinationType}/>
                    <FormDataConsumer>
                        {({formData, dispatch, ...rest}) => {
                            if (!formData.location) {
                                formData.location = []
                            }
                            let ref = "";
                            if (formData && formData.destinationType) {
                                switch (formData.destinationType) {
                                    case "place":
                                        ref = AppConstant.PLACES_ADMIN;
                                        break;
                                    case "region" :
                                        ref = AppConstant.REGIONS_ADMIN;
                                        break;
                                    case "restaurant":
                                        ref = AppConstant.RESTAURANTS_ADMIN;
                                        break;
                                    default:
                                }
                                return (
                                    <div>
                                        <ReferenceInput label="Destination" validate={required()}
                                                        source={"destinationID"}
                                                        reference={ref}>
                                            <AutocompleteInput optionText="translations[0].title"/>
                                        </ReferenceInput>
                                        {formData.destinationID && formData.destinationID !== this.state.mainID ? this.getMainLocation(formData.destinationID, ref) : null}
                                        <form style={styles.boundaries}>
                                            <TextField style={styles.inputInline} label="Main Longitude"
                                                       value={formData.destinationID ? this.state.mainLocation[1] : 0}
                                                       disabled/>
                                            <TextField style={styles.marginLeft} label="Main Latitude"
                                                       value={formData.destinationID ? this.state.mainLocation[0] : 0}
                                                       disabled/>
                                        </form>
                                        <ArrayInput
                                            label="Co-Location Coordinates"
                                            source="location"
                                            style={styles.boundaries}
                                        >
                                            <SimpleFormIterator disableAdd>
                                                <NumberInput
                                                    label="Longitude"
                                                    source="coordinates.long"
                                                    inputStyle={{width: '100%'}}
                                                    style={styles.inputInline}
                                                />
                                                <NumberInput
                                                    label="Latitude"
                                                    source="coordinates.lat"
                                                    inputStyle={{width: '100%'}}
                                                    style={styles.inputInline}
                                                />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                        {formData.destinationID ?
                                            <ShowSubLocationMap draggableMarkers={true} location={formData.location}
                                                                title={this.state.mainTitle}
                                                                mainLocation={this.state.mainLocation}
                                                                dispatch={dispatch}/> : null}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }}
                    </FormDataConsumer>
                </SimpleForm>
            </Create>
        )
    }
}

export default NewSLocationCreate;
