import NewSLocationList from './NewSLocationList';
import NewSLocationCreate from './NewSLocationCreate';
import NewSLocationEdit from './NewSLocationEdit';
import AddLocationIcon from '@material-ui/icons/AddLocation';

export default {
    list: NewSLocationList,
    create: NewSLocationCreate,
    edit: NewSLocationEdit,
    icon: AddLocationIcon
};