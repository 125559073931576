import HelpList from './HelpList';
import HelpCreate from './HelpCreate';
import HelpEdit from './HelpEdit';
import HelpIcon from '@material-ui/icons/Help';

export default {
    list: HelpList,
    create: HelpCreate,
    edit: HelpEdit,
    icon: HelpIcon
};