import React from 'react';

import {AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput} from 'react-admin';
import UserCmsTitle from './UserCmsTitle';


const UserCmsCreate = props => (
    <Create title={<UserCmsTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput source="firstName" validate={required()}/>
            <TextInput source="lastName" validate={required()}/>
            <TextInput source="username"/>
            <ReferenceInput label="Role" validate={required()} source="role"
                            reference="place-type">
                <AutocompleteInput optionText="type"/>
            </ReferenceInput>
            <TextInput source="email" validate={required()}/>
            <TextInput source="password" validate={required()} type="password" autoComplete="new-password"/>
        </SimpleForm>
    </Create>
);

export default UserCmsCreate;