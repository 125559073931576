import React, {Component} from "react";
import {ChipField, Datagrid, EditButton, Filter, List, SearchInput, TextField} from "react-admin";
import CultArticlesTitle from "./CultArticlesTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const CultArticlesFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

class CultArticlesList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <List
                title={<CultArticlesTitle type={"Cultural Articles"}/>}
                filters={<CultArticlesFilter/>}
                {...props}
            >
                <Datagrid>
                    <TextField label="Title" source="articles[0].title"/>
                    <TextField label="Available Languages" source="availableLanguages"/>
                    <ChipField label={"Search Tags"} source={"searchTags"}/>
                    <EditButton style={{color: "#ffa24a"}} label={""}/>
                    <DeleteButtonWithConfirmation undoable={false} label={""}/>
                </Datagrid>
            </List>
        )
    }
}

export default CultArticlesList