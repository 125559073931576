import React from 'react';
import {BooleanField, Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import RestaurantTypesTitle from "./RestaurantTypesTitle";


const RestaurantTypesFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const RestaurantTypesList = ({permissions, ...props}) => (
    <List title={<RestaurantTypesTitle type="Restaurant Types"/>}
          filters={<RestaurantTypesFilter/>}
          bulkActionButtons={false}
          {...props}
    >
        <Datagrid>
            <TextField source="type"/>
            <BooleanField label={"Hide"} source={"isHide"}/>
            {permissions === "Admin" ? [<EditButton label={""} style={{color: "#ffa24a"}}/>] : null}
        </Datagrid>
    </List>
);

export default RestaurantTypesList;