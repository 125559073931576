import React, {useState} from "react";
import {Admin, defaultTheme, Login} from "react-admin";
import {enableInterval, refreshToken} from "./providers/authProvider";
import {ShowAdmin, ShowPlaces, ShowRestaurant, ShowSightseeing} from "./components/permissions/permissions"
import myDataProvider from "./providers/myDataProvider";
import {AppConstant} from "./providers/constants";
import vietnameseMessages from "ra-language-vietnamese";
import englishMessages from "ra-language-english";
import {createMuiTheme} from '@material-ui/core/styles';
import image0 from "./images/doi-vong-canh-1.jpg"
import image1 from "./images/1.JPG"
import image2 from "./images/2_1.jpg"
import image3 from "./images/3_1.jpg"
import image4 from "./images/4_1.jpg"
import merge from 'lodash/merge';
import red from '@material-ui/core/colors/red';
import indigo from "@material-ui/core/colors/indigo";
import Dashboard from "./components/dashboard/Dashboard";
import newAuthProvider from "./providers/newAuthProvider";
import TreeMenu from '@bb-tech/ra-treemenu';

const background = [image0, image1, image2, image3, image4];

const dataProvider = myDataProvider(AppConstant.API_URL);

const messages = {
    vi: vietnameseMessages,
    en: englishMessages,
};

const imageIndexRandomize = () => {
    return Math.floor(Math.random() * 5)
};

//"http://171.244.51.143:8085/resources/wallpaper/hue.jpg";


const i18nProvider = locale => messages[locale];

const tokenValidate = () => {
    const token = localStorage.getItem("token");
    if (!token) {
        return false
    }
    return token !== "" && token !== 'undefined';
};

const permissionsValidate = () => {
    const perm = localStorage.getItem("permissions");
    if (!perm) {
        return false
    }
    return perm !== "";
};

const detectRefresh = () => {
    if (window.performance) {
        if (performance.navigation.type === 1) {
            refreshToken();
            if (tokenValidate()) {
                enableInterval();
            }
        }
    }
};

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark'
    }
});

const myTheme = merge({}, defaultTheme, {
    palette: {
        primary: {
            main: '#733000',
        },
        background: {
            default: 'rgba(187,168,105,0.47)',
        },
        secondary: {
            light: '#733000',
            main: '#733000',
            contrastText: '#ffffff',//'#ffffff',
        },
        info: indigo,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Arial', 'sans-serif'].join(','),
        button: {
            textTransform: 'none'
        }
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: '#92804b', // Some CSS
            },
        },
    },
});

const MyLoginPage = () => <Login theme={myTheme} backgroundImage={background[imageIndexRandomize()]}/>;

const App = () => {
    detectRefresh();
    const [permission, setPermission] = useState(localStorage.getItem("permissions"));
    const [token, setToken] = useState(localStorage.getItem("token"));

    return (
        <Admin
            locale="en"
            authProvider={newAuthProvider}
            dataProvider={dataProvider}
            dashboard={Dashboard}
            loginPage={MyLoginPage}
            theme={myTheme}
            menu={TreeMenu}
        >
            {permissions => permissions === "Admin" ? ShowAdmin() : (permissions === "Restaurant" ? ShowRestaurant() : (permissions === "Sightseeing" ? ShowSightseeing() : ShowPlaces()))}
        </Admin>
    )
};

export default App;
