import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {AppConstant} from "../../providers/constants";
import Button from "@material-ui/core/Button";

class CsvButton extends Component {
    constructor(props) {
        super(props);
        this.customLink = this.customLink.bind(this)
    }

    customLink = (props) => {
        fetch(`${AppConstant.ADMIN_ADDRESS}/csv`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token")
            },
        }).then(response => {
            //console.log(`${AppConstant.ADMIN_ADDRESS}/csv`);
            //console.log(response);
        });
        return (
            <Link to={`/csv`} {...props}/>
        )
    };

    render() {
        return (
            <Button
                id="buttonLink"
                style={{
                    marginRight: 25,
                    marginTop: 25,
                    verticalAlign: "middle"
                }}
                variant="contained"
                color="primary"
                href={`${AppConstant.ADMIN_ADDRESS}/csv`}
                download={`${AppConstant.ADMIN_ADDRESS}/csv`}
            >
                Csv Download
            </Button>
        )
    }
}

export default withRouter(CsvButton)