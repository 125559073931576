import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import QRCodeTitle from "./QRCodeTitle";
import ReactToPrint from 'react-to-print';
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";
import Button from "@material-ui/core/Button";
import './qrImages.css'
import {AppConstant} from "../../providers/constants";

const parse = require('html-react-parser');

const QRCodeFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

function htmlToElements(html) {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
}

// 18 max width, 13 max height
const imagesListToHtml = (imgList) => {
    console.log("imG", imgList);
    if (!imgList) {
        return '<table></table>'
    }
    let maxWidthQuantity = Math.floor(AppConstant.PAPER_SIZE[0] / AppConstant.QR_SIZE);
    let maxHeightQuantity = Math.floor(AppConstant.PAPER_SIZE[1] / AppConstant.QR_SIZE);
    let html = '<table id="tableID"><tr>';
    for (let i = 0; i < imgList.length; i++) {
        if (i % maxWidthQuantity === 0 && i !== 0) {
            html += '</tr><tr>'
        }
        html += '<td><img id="qr" src="' + imgList[i] + '"/></td>'
    }
    html += '</tr></table>';
    console.log("HTML", html);
    return html;
};

const getAllImageLinks = (formData) => {
    let res = [];
    //console.log("FORM DATA", formData)
    if (!formData.records || formData.records.length === 0) {
        return res
    }
    for (let i = 0; i < formData.records.length; i++) {
        res.push(`${AppConstant.SERVER_IMAGE}${formData.records[i].imageCode}`)
    }
    //console.log("IMG List", res);
    return res
};

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {qrSrc} = this.props;
        let html = parse(imagesListToHtml(qrSrc));
        return (
            html
        );
    }
}

class QRCodeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imagesList: null
        };
        this.setImagesList = this.setImagesList.bind(this)
    }

    setImagesList = (imgList) => {
        this.setState({imagesList: imgList})
    };

    render() {
        const props = this.props;
        return (
            <List title={<QRCodeTitle type="QRCodes"/>}
                  filters={<QRCodeFilter/>}
                  {...props}
            >
                <Datagrid>
                    <TextField label="Price" source="priceRate"/>
                    <TextField label="Quantity" source="quantity"/>
                    <TextField label="Creation" source="creationDate" style={{color: 'black', fontWeight: 'bold'}}/>
                    <TextField label="Expiry" source="expiry" style={{color: 'red', fontWeight: 'bold'}}/>
                    {this.state.imagesList && this.state.imagesList !== [] ?
                        <div>
                            <ReactToPrint
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return (
                                        <Button
                                            name={"print"}
                                            type="button"
                                            style={{
                                                marginTop: 20,
                                                marginLeft: 10,
                                                marginBottom: 20,
                                                verticalAlign: "middle"
                                            }}
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                        >
                                            Print QR Codes
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            />
                            <div style={{display: "none"}}>
                                <ComponentToPrint qrSrc={this.state.imagesList}
                                                  ref={el => (this.componentRef = el)}/>
                            </div>
                        </div> : null
                    }
                    <EditButton style={{color: "#ffa24a"}} label={""}/>
                    <DeleteButtonWithConfirmation undoable={false} label={""}/>
                </Datagrid>
            </List>
        )
    }
};

export default QRCodeList;