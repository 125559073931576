import React, {Component} from 'react';
import {
    AutocompleteInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    required,
    TabbedForm,
    TextField
} from 'react-admin';
import FreeAudioTitle from "./FreeAudioTitle";

const subject = [
    {id: "place", name: "Place"},
    {id: "region", name: "Sub-Place"},
    {id: "object", name: "Object"},
];

class FreeAudioEdit extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <Edit title={<FreeAudioTitle type="Edit"/>} {...props}>
                <TabbedForm redirect={false}>
                    <FormTab label={"Details"}>
                        <AutocompleteInput label={"Subject Type"} source={"subjectType"}
                                           validate={required()}
                                           choices={subject}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                let ref = "";
                                if (formData && formData.subjectType) {
                                    if (formData.subjectType === "place") {
                                        ref = "places-admin"
                                    }
                                    if (formData.subjectType === "region") {
                                        ref = "regions-admin"
                                    }
                                    if (formData.subjectType === "object") {
                                        ref = "objects-admin"
                                    }
                                    return (
                                        <div>
                                            <ReferenceInput label="Subject" validate={required()}
                                                            source={"freeAudio"}
                                                            reference={ref}>
                                                <AutocompleteInput optionText="translations[0].title"/>
                                            </ReferenceInput>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        </FormDataConsumer>
                        <TextField label={"Title"} source={"title"}/>
                    </FormTab>
                </TabbedForm>
            </Edit>
        )
    }
}

export default FreeAudioEdit;