import React from "react";
import TourTitle from "./TourTitle";
import {AutocompleteInput, Create, NumberInput, ReferenceInput, required, SimpleForm, TextInput} from "react-admin";

const redirect = (basePath, id, data) => {
    return `/tours-admin/${id}`;
};

const TourCreate = props => (
    <Create title={<TourTitle type="Create"/>} {...props}>
        <SimpleForm redirect={redirect}>
            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
            <TextInput fullWidth label={"Title"} validate={required()} source={"title"}/>
            <NumberInput
                label="Price in VND"
                source="price"
            />
            <NumberInput
                label="Total days"
                source="numberDays"
            />
            <NumberInput
                label="Total nights"
                source="numberNights"
            />
        </SimpleForm>
    </Create>
);

export default TourCreate;
