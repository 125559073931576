import React from "react";
import {Datagrid, EditButton, Filter, FunctionField, List, SearchInput, TextField} from "react-admin";
import TourTitle from "./TourTitle";
import {AppConstant} from "../../providers/constants";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const TourFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const TourList = props => (
    <List
        title={<TourTitle type="Tours"/>}
        filters={<TourFilter/>}
        {...props}
    >
        <Datagrid>
            <TextField label="Title" source="contentTranslation[0].title"/>
            <FunctionField
                label="Image"
                render={record => {
                    if (record.images === null || record.images === "") {
                        return null;
                    }
                    return (
                        <img
                            alt={record.images[0].url}
                            src={`${AppConstant.SERVER_IMAGE}${record.images[0].url}`}
                            width={80}
                            height={80}
                        />
                    );
                }}
            />
            <TextField label="Total destinations" source="numberDestinations"/>
            <TextField source="price"/>
            <TextField label="Total days" source="numberDays"/>
            <TextField label="Total nights" source="numberNights"/>
            <TextField labe={"Search Tags"} source="searchTags"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default TourList;
