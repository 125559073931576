import React from 'react';
import {Edit, NumberInput, required, SelectInput, SimpleForm} from 'react-admin';
import AppPricingTitle from './AppPricingTitle';
import {EditToolbar} from "../toolbar/ToolBar";

let cc = require('currency-codes');

function currencyChoices() {
    return cc.codes().map((item, index) => {
        return {id: item, name: item}
    })
}

const AppPricingEdit = props => (
    <Edit title={<AppPricingTitle type="Edit"/>} {...props}>
        <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
            <NumberInput label={"Price"} source="price" step={"0.01"} validate={required()}/>
            <SelectInput label={"Base Currency"} source="baseCurrency" choices={currencyChoices()}/>
        </SimpleForm>
    </Edit>
);

export default AppPricingEdit;