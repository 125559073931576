import React from "react";
import {
    BooleanField,
    BooleanInput,
    ChipField,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    TextInput,
} from "react-admin";
import RestaurantTitle from "./RestaurantTitle";
import {AppConstant} from "../../providers/constants";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";
import Popup from "reactjs-popup";

const RestaurantFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
        <TextInput source="owner"/>
        <TextInput source="rating"/>
        <BooleanInput label={"Most Visited"} source={"isMostVisited"}/>
        <BooleanInput label={"Hide"} source={"isHide"}/>
        <ReferenceInput label="Type" source="typeId" reference="restaurant-type" allowEmpty>
            <SelectInput optionText="type"/>
        </ReferenceInput>
    </Filter>
);

const RestaurantList = props => (
    <List
        title={<RestaurantTitle type="Restaurants"/>}
        filters={<RestaurantFilter/>}
        {...props}
    >
        <Datagrid>
            <ReferenceField label={"Title / Translations"} source={"translationID"}
                            reference={AppConstant.RESTAURANT_TRANSLATIONS}>
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <FunctionField
                label="Image"
                render={record => {
                    return (
                        <img
                            src={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                            width={80}
                            height={80}
                        />
                    );
                }}
            />
            <TextField source="rating"/>
            <ChipField source="link"/>
            <ChipField label={"Available Languages"} source={"availableLanguages"}/>
            <FunctionField
                label="Search Tags"
                render={record => {
                    const l = record.searchTags.split(", ")
                    return (
                        <Popup trigger={<button>Show</button>} position="left center">
                            <div>{l.map((t, index) => <li
                                style={{"list-style": "none"}}>{(index + 1).toString() + ". " + t}</li>)}</div>
                        </Popup>
                    );
                }}
            />
            {/*<ChipField label={"Search Tags"} source={"searchTags"}/>*/}
            <BooleanField label={"Most Visited"} source={"isMostVisited"}/>
            <BooleanField label={"Hide"} source={"isHide"}/>
            <EditButton label={""} style={{color: "#ffa24a"}}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default RestaurantList;
