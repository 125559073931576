import React from 'react';
import TaxiCompanyTitle from './TaxiCompanyTitle';
import {Create, maxValue, minValue, NumberInput, required, SimpleForm, TextInput} from 'react-admin';

const validateStars = [minValue(0), maxValue(5)];
const validatePrice = [required(), minValue(0)];

const TaxiCompanyCreate = props => (
    <Create title={<TaxiCompanyTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput fullWidth label={"Title"} source="title" validate={required()}/>
            <TextInput label={"Phone Number"} source="phone" validate={required()}/>
            <NumberInput label={"Stars"} source="stars" validate={validateStars}/>
            <NumberInput label={"Price Per Kilometer in dong"} source="price" validate={validatePrice}/>
        </SimpleForm>
    </Create>
);

export default TaxiCompanyCreate;