import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import HelpTitle from "./HelpTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const HelpFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const HelpList = props => (
    <List title={<HelpTitle type="Help"/>}
          filters={<HelpFilter/>}
          {...props}
    >
        <Datagrid>
            <TextField label={"Language"} source={"languageCode"}/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default HelpList;