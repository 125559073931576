import React from 'react';

import {Create, required, SimpleForm, TextInput} from 'react-admin';
import UserTitle from './UserTitle';

const UserCreate = props => (
    <Create title={<UserTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput source="firstName" validate={required()}/>
            <TextInput source="lastName" validate={required()}/>
            <TextInput source="username"/>
            <TextInput source="email" validate={required()}/>
            <TextInput source="password" validate={required()} type="password" autoComplete="new-password"/>
        </SimpleForm>
    </Create>
);

export default UserCreate;