import LanguageList from './LanguageList';
import LanguageCreate from './LanguageCreate';
import LanguageEdit from './LanguageEdit';
import LanguageIcon from '@material-ui/icons/Language';

export default {
    list: LanguageList,
    create: LanguageCreate,
    edit: LanguageEdit,
    icon: LanguageIcon
};