import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    FormDataConsumer,
    FormTab,
    NumberField,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextField
} from 'react-admin';
import {styles} from "../../common/styleField";
import TimetableTitle from "./TimetableTitle";
import {AppConstant} from "../../providers/constants";
import MoveButtons from "../order_buttons/MoveButtons";

const vehicles = [
    {id: "Walking", name: "Walking"},
    {id: "Bicycle", name: "Bicycle"},
    {id: "Car", name: "Car"},
    {id: "Car/Motorcycle", name: "Car/Motorcycle"},
    {id: "Boat", name: "Boat"},
];

const destination = [
    {id: "place", name: "Place"},
    {id: "region", name: "Sub-Place"},
    {id: "restaurant", name: "Restaurants"}
];

const TimetableEdit = (props) => {
    return (
        <Edit title={<TimetableTitle type="Edit"/>} {...props}>
            <TabbedForm redirect={false}>
                <FormTab label={"General"}>
                    <NumberField label={"Total Distance in metres"} source={"totalDistance"}
                                 style={styles.inputInline}/>
                    <NumberField label={"Total Time"} source={"totalTime"} style={styles.inputInline}/>
                    <TextField label={"Locations"} source={"locationsName"}/>
                </FormTab>
                <FormTab label={"Details"}>
                    <ArrayInput source={"loopDetails"} style={styles.timetables}>
                        <SimpleFormIterator>
                            <NumberInput label={"Distance in metres"} source={"moveDistance"}/>
                            <NumberInput label={"Duration in minutes"} source={"moveTime"}/>
                            <SelectInput label={"Vehicles"} source={"vehicle"} choices={vehicles}/>
                            <AutocompleteInput label={"Destination Type"} source={"destinationType"}
                                               validate={required()}
                                               choices={destination}/>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, dispatch, ...rest}) => {
                                    let ref = "";
                                    if (scopedFormData && scopedFormData.destinationType) {
                                        if (scopedFormData.destinationType === "place") {
                                            ref = AppConstant.PLACES_ADMIN
                                        }
                                        if (scopedFormData.destinationType === "region") {
                                            ref = AppConstant.REGIONS_ADMIN
                                        }
                                        if (scopedFormData.destinationType === "restaurant") {
                                            ref = AppConstant.RESTAURANTS_ADMIN
                                        }
                                        if (scopedFormData.destinationID === "" || !scopedFormData.destinationID) {
                                            return (
                                                <ReferenceInput label="Destination" validate={required()}
                                                                source={getSource("destinationID")}
                                                                reference={ref}
                                                                perPage={AppConstant.REFERENCE_PAGE}
                                                >
                                                    <AutocompleteInput optionText="translations[0].title"/>
                                                </ReferenceInput>)
                                        } else {
                                            return (
                                                <div>
                                                    <ReferenceInput label="Destination" validate={required()}
                                                                    source={getSource("destinationID")}
                                                                    reference={ref}
                                                                    perPage={AppConstant.REFERENCE_PAGE}
                                                    >
                                                        <AutocompleteInput optionText="translations[0].title"/>
                                                    </ReferenceInput>
                                                    <ReferenceInput label="Longitude" validate={required()}
                                                                    source={getSource("destinationID")}
                                                                    reference={ref}
                                                                    perPage={AppConstant.REFERENCE_PAGE}
                                                    >
                                                        <SelectInput optionText="location.coordinates.long"
                                                                     disabled={true}/>
                                                    </ReferenceInput>
                                                    <ReferenceInput label="Latitude" validate={required()}
                                                                    source={getSource("destinationID")}
                                                                    reference={ref}
                                                                    perPage={AppConstant.REFERENCE_PAGE}
                                                    >
                                                        <SelectInput optionText="location.coordinates.lat"
                                                                     disabled={true} style={{marginLeft: 30}}/>
                                                    </ReferenceInput>
                                                    <MoveButtons formArray={formData.loopDetails}
                                                                 index={formData.loopDetails.indexOf(scopedFormData)}
                                                                 fieldName={'loopDetails'}/>
                                                </div>
                                            );
                                        }
                                    } else {
                                        return null;
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default TimetableEdit;