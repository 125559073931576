import TimetableList from './TimetableList';
import TimetableEdit from './TimetableEdit';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import TimetableCreate from "./TimetableCreate";

export default {
    list: TimetableList,
    edit: TimetableEdit,
    create: TimetableCreate,
    icon: AssistantPhotoIcon,
};