import TaxiCompanyList from './TaxiCompanyList';
import TaxiCompanyCreate from './TaxiCompanyCreate';
import TaxiCompanyEdit from './TaxiCompanyEdit';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';

export default {
    list: TaxiCompanyList,
    create: TaxiCompanyCreate,
    edit: TaxiCompanyEdit,
    icon: LocalTaxiIcon,
};