import PlacePanoramaNotationsList from './PlacePanoramaNotationsList';
//import PlacePanoramaNotationsCreate from './PlacePanoramaNotationsCreate';
import PlacePanoramaNotationsEdit from './PlacePanoramaNotationsEdit';
import PanoramaIcon from '@material-ui/icons/Panorama';

export default {
    list: PlacePanoramaNotationsList,
    //create: PlacePanoramaNotationsCreate,
    edit: PlacePanoramaNotationsEdit,
    icon: PanoramaIcon
};