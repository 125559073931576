import React from 'react';

import {Create, required, SimpleForm, TextInput} from 'react-admin';

const PlaceTypesCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="type" validate={required()}/>
        </SimpleForm>
    </Create>
);

export default PlaceTypesCreate;