import React from 'react';

import {Create, required, SimpleForm, TextInput} from 'react-admin';
import ContactTitle from './ContactTitle';

const ContactCreate = props => (
    <Create title={<ContactTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput label={"Title"} source="title" validate={required()}/>
            <TextInput fullWidth label={"Description"} source="description"/>
            <TextInput label={"Phone Number"} source="phoneNumber" validate={required()}/>
        </SimpleForm>
    </Create>
);

export default ContactCreate;