import React, {useState} from "react";
import {AppConstant} from "../../providers/constants";
import {default as ReactPopup} from "reactjs-popup";
import Button from "@material-ui/core/Button";
import {Pannellum} from "pannellum-react";
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginBottom: '50px'
    },
});

const ShowPanorama = (props) => {
    const classes = useStyles();
    const [showProgress, setShowProgress] = useState(true);
    const {url} = props;
    let imageUrl = '';
    if (url.includes(AppConstant.SERVER_IMAGE) || url.includes('blob:')) {
        imageUrl = url
    } else {
        imageUrl = `${AppConstant.SERVER_IMAGE}${url}`
    }

    return (
        <React.Fragment>
            {showProgress ? <div className={classes.root}>
                <dt>Loading...</dt>
                <LinearProgress variant="indeterminate"/>
            </div> : null}
            <img
                onLoad={() => {
                    setShowProgress(false)
                }}
                alt={imageUrl}
                src={imageUrl}
                width={AppConstant.IMAGES_WIDTH}
                height={AppConstant.IMAGES_HEIGHT}
                style={showProgress ? {display: 'none'} : {display: "inline-block"}}
            />
            <ReactPopup
                trigger={
                    <Button
                        name={"Panorama"}
                        type="button"
                        style={showProgress ? {display: 'none'} : {
                            marginLeft: 10,
                            marginBottom: 20,
                            verticalAlign: "middle"
                        }}
                        variant="contained"
                        color="primary"
                        component="span"
                    >
                        Show Panorama
                    </Button>
                }
                contentStyle={{
                    width: "100%",
                    height: AppConstant.TINY_HEIGHT,
                }}
                modal
                closeOnDocumentClick
            >
                <div>
                    <Pannellum
                        width="100%"
                        height={AppConstant.TINY_HEIGHT}
                        image={imageUrl}
                        pitch={10}
                        yaw={180}
                        hfov={110}
                        autoLoad
                        onLoad={() => {
                            //console.log("panorama loaded");
                        }}
                    >
                    </Pannellum>
                </div>
            </ReactPopup>
        </React.Fragment>
    )
};

export default ShowPanorama;