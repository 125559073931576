import RestaurantPanoramaNotationsList from './RestaurantPanoramaNotationsList';
//import RestaurantPanoramaNotationsCreate from './RestaurantPanoramaNotationsCreate';
import RestaurantPanoramaNotationsEdit from './RestaurantPanoramaNotationsEdit';
import PanoramaIcon from '@material-ui/icons/Panorama';

export default {
    list: RestaurantPanoramaNotationsList,
    //create: RestaurantPanoramaNotationsCreate,
    edit: RestaurantPanoramaNotationsEdit,
    icon: PanoramaIcon
};