import React, {useEffect, useState} from "react";
import {
    ArrayInput,
    Edit,
    FormDataConsumer,
    FormTab,
    NumberInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    useRefresh
} from "react-admin";
import NewSLocationTitle from "./NewSLocationTitle";
import {styles} from "../../common/styleField";
import "../map/map.css";
import PropTypes from "prop-types";
import SubLocationMap from "../map/ShowSubLocationMap";
import SLBoundary from "../map/SLBoundary";

// bounds [lat, lng]
// formData [lat, long]
// marker [number, number]

// {formData.mainLocation ? <form style={styles.boundaries}>
//     <TextField style={styles.inputInline} label="Main Longitude"
//                value={formData.mainLocation.coordinates.long} disabled/>
//     <TextField style={styles.marginLeft} label="Main Latitude"
//                value={formData.mainLocation.coordinates.lat} disabled/>
// </form> : null}

const NewSLocationEdit = (props) => {
    const [markerOrder, setMarkerOrder] = useState(1);

    const refresh = useRefresh();

    useEffect(() => {

    }, []);

    const getChoices = (locationArray) => {
        let res = [];
        for (let i = 0; i < locationArray.length; i++) {
            res.push({id: i + 1, name: "Marker Number " + (i + 1).toString()})
        }
        return (res)
    };

    const isValid = (obj) => {
        return obj !== undefined && obj !== null
    }

    const getOtherZones = (formLocation, formBoundary, index) => {
        let location = [];
        let boundary = [];
        let otherBoundaryMarkers = [];
        //console.log("L Length", formLocation.length);
        //console.log("L index", index);
        for (let i = 0; i < formLocation.length; i++) {
            if (i !== index - 1) {
                location.push([formLocation[i].coordinates.lat, formLocation[i].coordinates.long])
            }
        }
        for (let i = 0; i < formBoundary.length; i++) {
            if (i !== index - 1) {
                let otherBounds = [];
                if (formBoundary[i].coordinates[0].points) {
                    formBoundary[i].coordinates[0].points.forEach((value) => {
                        if (value.lat && value.long) {
                            otherBoundaryMarkers.push([value.lat, value.long]);
                            otherBounds.push({lat: value.lat, lng: value.long})
                        }
                    });
                }
                boundary.push(otherBounds)
            }
        }
        return [location, boundary, otherBoundaryMarkers]
    };

    return (
        <Edit undoable={false} title={<NewSLocationTitle type={"Edit" + " "}/>} {...props}>
            <TabbedForm redirect={false}>
                <FormTab label="Co-Location Map">
                    <FormDataConsumer>
                        {({formData, scopedFormData, getSource, dispatch, ...rest}) => {
                            console.log("Scoped Form Data", scopedFormData)
                            console.log("Form Data", formData)
                            return (
                                <React.Fragment>
                                    <ArrayInput
                                        label="Co-Location Coordinates"
                                        source="location"
                                        style={styles.boundaries}
                                    >
                                        <SimpleFormIterator disableAdd>
                                            <NumberInput
                                                label="Longitude"
                                                source="coordinates.long"
                                                style={styles.inputInline}
                                            />
                                            <NumberInput
                                                label="Latitude"
                                                source="coordinates.lat"
                                                style={styles.inputInline}
                                            />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                    {formData.mainLocation && formData.mainLocation.coordinates ?
                                        <SubLocationMap draggableMarkers={true}
                                                        location={formData.location}
                                                        title={formData.title}
                                                        boundary={formData.boundary}
                                                        mainLocation={[formData.mainLocation.coordinates.lat, formData.mainLocation.coordinates.long]}/> : null}
                                </React.Fragment>
                            );
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={"Co-Location Boundary"}>
                    <ArrayInput
                        label="Boundary Coordinates"
                        source={`boundary[${(markerOrder - 1).toString()}].coordinates[0].points`}
                        style={styles.boundaries}
                    >
                        <SimpleFormIterator disableAdd>
                            <NumberInput
                                label="Longitude"
                                source="long"
                                style={styles.inputInline}
                            />
                            <NumberInput
                                label="Latitude"
                                source="lat"
                                style={styles.inputInline}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <FormDataConsumer>
                        {({formData}) => {
                            if (!formData.subIndex || formData.subIndex === "") {
                                formData.subIndex = markerOrder.toString()
                            }
                            if (formData.location) {
                                if (!formData.boundary) {
                                    formData.boundary = [];
                                    for (let i = 0; i < formData.location.length; i++) {
                                        formData.boundary.push({coordinates: [{points: []}]})
                                    }
                                } else {
                                    if (formData.boundary.length < formData.location.length) {
                                        for (let i = 0; i < formData.location.length - formData.boundary.length; i++) {
                                            formData.boundary.push({coordinates: [{points: []}]})
                                        }
                                    }
                                }
                            }
                            if (formData.subIndex && formData.subIndex !== "" && parseInt(formData.subIndex) !== markerOrder) {
                                setMarkerOrder(parseInt(formData.subIndex));
                                refresh()
                            }
                            return (
                                <div>
                                    <SelectInput label={"Co-Location Index"} source="subIndex"
                                                 choices={getChoices(formData.location)}/>
                                    <SLBoundary mainBoundary={formData.mainBoundary}
                                                draggableMarkers={false}
                                                location={formData.location[markerOrder - 1]}
                                                boundary={formData.boundary[markerOrder - 1]}
                                                title={formData.title}
                                                totalLocations={formData.location.length}
                                                otherLocation={getOtherZones(formData.location, formData.boundary, markerOrder)[0]}
                                                otherBoundary={getOtherZones(formData.location, formData.boundary, markerOrder)[1]}
                                                otherBoundaryMarkers={getOtherZones(formData.location, formData.boundary, markerOrder)[2]}
                                                orderChanged={markerOrder}
                                                mainLocation={isValid(formData.mainLocation) ? [formData.mainLocation.coordinates.lat, formData.mainLocation.coordinates.long] : [0, 0]}/>
                                </div>
                            )
                        }}
                    </FormDataConsumer>
                    {/*<FormDataConsumer>*/}
                    {/*    {({formData, scopedFormData, getSource, dispatch, ...rest}) => {*/}
                    {/*        if (!formData.subIndex || formData.subIndex === "") {*/}
                    {/*            formData.subIndex = "1"*/}
                    {/*        }*/}
                    {/*        if (formData.location) {*/}
                    {/*            if (!formData.boundary) {*/}
                    {/*                formData.boundary = [];*/}
                    {/*                for (let i = 0; i < formData.location.length; i++) {*/}
                    {/*                    formData.boundary.push({coordinates: [{points: []}]})*/}
                    {/*                }*/}
                    {/*            } else {*/}
                    {/*                if (formData.boundary.length < formData.location.length) {*/}
                    {/*                    for (let i = 0; i < formData.location.length - formData.boundary.length; i++) {*/}
                    {/*                        formData.boundary.push({coordinates: [{points: []}]})*/}
                    {/*                    }*/}
                    {/*                }*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*        //console.log("Form Data", formData);*/}
                    {/*        return (*/}
                    {/*            <React.Fragment>*/}
                    {/*                {formData.location && formData.location.length > 0 ?*/}
                    {/*                    <SelectInput label={"Co-Location Index"} source="subIndex"*/}
                    {/*                                 choices={getChoices(formData.location)} /> : null}*/}
                    {/*                <br/>*/}
                    {/*                {formData.subIndex !== "" && formData.subIndex !== markerOrder ?setMarkerOrder(formData.subIndex) : null}*/}
                    {/*                {formData.subIndex !== "" && formData.location && formData.location.length > 0 ?*/}
                    {/*                    <ArrayInput*/}
                    {/*                        label="Boundary Coordinates"*/}
                    {/*                        source={"boundary[" + (parseInt(markerOrder) - 1).toString() + "].coordinates[0].points"}*/}
                    {/*                        style={styles.boundaries}*/}
                    {/*                    >*/}
                    {/*                        <SimpleFormIterator>*/}
                    {/*                            <NumberInput*/}
                    {/*                                label="Longitude"*/}
                    {/*                                source="long"*/}
                    {/*                                style={styles.inputInline}*/}
                    {/*                            />*/}
                    {/*                            <NumberInput*/}
                    {/*                                label="Latitude"*/}
                    {/*                                source="lat"*/}
                    {/*                                style={styles.inputInline}*/}
                    {/*                            />*/}
                    {/*                        </SimpleFormIterator>*/}
                    {/*                    </ArrayInput> : null}*/}
                    {/*                {formData.location ? (formData.mainLocation && formData.mainBoundary ?*/}
                    {/*                    <SLBoundary mainBoundary={formData.mainBoundary}*/}
                    {/*                                draggableMarkers={false}*/}
                    {/*                                location={formData.location[parseInt(markerOrder) - 1]}*/}
                    {/*                                boundary={formData.boundary[parseInt(markerOrder) - 1]}*/}
                    {/*                                title={formData.title}*/}
                    {/*                                totalLocations={formData.location.length}*/}
                    {/*                                otherLocation={getOtherZones(formData.location, formData.boundary, markerOrder)[0]}*/}
                    {/*                                otherBoundary={getOtherZones(formData.location, formData.boundary, markerOrder)[1]}*/}
                    {/*                                otherBoundaryMarkers={getOtherZones(formData.location, formData.boundary, markerOrder)[2]}*/}
                    {/*                                orderChanged={markerOrder}*/}
                    {/*                                mainLocation={[formData.mainLocation.coordinates.lat, formData.mainLocation.coordinates.long]}/> : null) : null}*/}
                    {/*            </React.Fragment>*/}
                    {/*        );*/}
                    {/*    }}*/}
                    {/*</FormDataConsumer>*/}
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

// const getChoices = (locationArray) => {
//     let res = [];
//     for (let i = 0; i < locationArray.length; i++) {
//         res.push({id: i + 1, name: "Marker Number " + (i + 1).toString()})
//     }
//     return (res)
// };
//
// const getOtherZones = (formLocation, formBoundary, index) => {
//     let location = [];
//     let boundary = [];
//     let otherBoundaryMarkers = [];
//     //console.log("L Length", formLocation.length);
//     //console.log("L index", index);
//     for (let i = 0; i < formLocation.length; i++) {
//         if (i !== parseInt(index) - 1) {
//             location.push([formLocation[i].coordinates.lat, formLocation[i].coordinates.long])
//         }
//     }
//     for (let i = 0; i < formBoundary.length; i++) {
//         if (i !== parseInt(index) - 1) {
//             let otherBounds = [];
//             if (formBoundary[i].coordinates[0].points) {
//                 formBoundary[i].coordinates[0].points.forEach((value) => {
//                     if (value.lat && value.long) {
//                         otherBoundaryMarkers.push([value.lat, value.long]);
//                         otherBounds.push({lat: value.lat, lng: value.long})
//                     }
//                 });
//             }
//             boundary.push(otherBounds)
//         }
//     }
//     return [location, boundary, otherBoundaryMarkers]
// };
//
// const removeBoundary = (boundary, index) => {
//     boundary.splice(index, 1)
// };
//
// class NewSLocationEdit extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             title: "",
//             mainLocation: AppConstant.DEFAULT_LOCATION,
//             markerOrder: "1",
//         };
//         this.getTitle = this.getTitle.bind(this);
//         this.setMarkerOrder = this.setMarkerOrder.bind(this);
//     };
//
//     getTitle = (name) => {
//         this.setState({title: name});
//     };
//
//     setMarkerOrder = (order) => {
//         this.setState({markerOrder: order});
//     };
//
//     render() {
//         let name = this.state.title;
//         const props = this.props;
//         return (
//             <Edit undoable={false} title={<NewSLocationTitle type={"Edit " + `${name}` + " "}/>} {...props}>
//                 <TabbedForm redirect={false}>
//                     <FormTab label="CO-LOCATION MAP">
//                         <FormDataConsumer>
//                             {({formData, scopedFormData, getSource, dispatch, ...rest}) => {
//                                 //console.log("Scoped Form Data", scopedFormData)
//                                 return (
//                                     <React.Fragment>
//                                         <ArrayInput
//                                             label="Co-Location Coordinates"
//                                             source="location"
//                                             style={styles.boundaries}
//                                         >
//                                             <SimpleFormIterator disableAdd>
//                                                 <NumberInput
//                                                     label="Longitude"
//                                                     source="coordinates.long"
//                                                     style={styles.inputInline}
//                                                 />
//                                                 <NumberInput
//                                                     label="Latitude"
//                                                     source="coordinates.lat"
//                                                     style={styles.inputInline}
//                                                 />
//                                             </SimpleFormIterator>
//                                         </ArrayInput>
//                                         {formData.mainLocation ?
//                                             <SubLocationMap draggableMarkers={true}
//                                                                 location={formData.location}
//                                                                 removeFunc={removeBoundary}
//                                                                 title={formData.title}
//                                                                 boundary={formData.boundary}
//                                                                 mainLocation={[formData.mainLocation.coordinates.lat, formData.mainLocation.coordinates.long]}
//                                                                 dispatch={dispatch}/> : null}
//                                     </React.Fragment>
//                                 );
//                             }}
//                         </FormDataConsumer>
//                     </FormTab>
//                     <FormTab label={"CO-LOCATION BOUNDARY"}>
//                         <FormDataConsumer>
//                             {({formData, scopedFormData, getSource, dispatch, ...rest}) => {
//                                 if (!formData.subIndex) {
//                                     formData.subIndex = "1"
//                                 }
//                                 if (formData.location) {
//                                     if (!formData.boundary) {
//                                         formData.boundary = [];
//                                         for (let i = 0; i < formData.location.length; i++) {
//                                             formData.boundary.push({coordinates: [{points: []}]})
//                                         }
//                                     } else {
//                                         if (formData.boundary.length < formData.location.length) {
//                                             for (let i = 0; i < formData.location.length - formData.boundary.length; i++) {
//                                                 formData.boundary.push({coordinates: [{points: []}]})
//                                             }
//                                         }
//                                     }
//                                 }
//                                 //console.log("Form Data", formData);
//                                 return (
//                                     <React.Fragment>
//                                         {formData.location && formData.location.length > 0 ?
//                                             <SelectInput label={"Co-Location Index"} source="subIndex"
//                                                          choices={getChoices(formData.location)}/> : null}
//                                         <br/>
//                                         {formData.subIndex !== "" && formData.subIndex !== this.state.markerOrder ? this.setMarkerOrder(formData.subIndex) : null}
//                                         {formData.subIndex !== "" && formData.location && formData.location.length > 0 ?
//                                             <ArrayInput
//                                                 label="Boundary Coordinates"
//                                                 source={"boundary[" + (parseInt(this.state.markerOrder) - 1).toString() + "].coordinates[0].points"}
//                                                 style={styles.boundaries}
//                                             >
//                                                 <SimpleFormIterator>
//                                                     <NumberInput
//                                                         label="Longitude"
//                                                         source="long"
//                                                         style={styles.inputInline}
//                                                     />
//                                                     <NumberInput
//                                                         label="Latitude"
//                                                         source="lat"
//                                                         style={styles.inputInline}
//                                                     />
//                                                 </SimpleFormIterator>
//                                             </ArrayInput> : null}
//                                         {formData.location ? (formData.mainLocation && formData.mainBoundary ?
//                                             <SLBoundary mainBoundary={formData.mainBoundary}
//                                                         draggableMarkers={false}
//                                                         location={formData.location[parseInt(this.state.markerOrder) - 1]}
//                                                         boundary={formData.boundary[parseInt(this.state.markerOrder) - 1]}
//                                                         title={formData.title}
//                                                         totalLocations={formData.location.length}
//                                                         otherLocation={getOtherZones(formData.location, formData.boundary, this.state.markerOrder)[0]}
//                                                         otherBoundary={getOtherZones(formData.location, formData.boundary, this.state.markerOrder)[1]}
//                                                         otherBoundaryMarkers={getOtherZones(formData.location, formData.boundary, this.state.markerOrder)[2]}
//                                                         orderChanged={this.state.markerOrder}
//                                                         mainLocation={[formData.mainLocation.coordinates.lat, formData.mainLocation.coordinates.long]}
//                                                         dispatch={dispatch}/> : null) : null}
//                                     </React.Fragment>
//                                 );
//                             }}
//                         </FormDataConsumer>
//                     </FormTab>
//                 </TabbedForm>
//             </Edit>
//         );
//     }
// }

NewSLocationEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default NewSLocationEdit;