import {AppConstant} from "../providers/constants";

export const MapValidate = (formData, markerPosition, position) => {
    if (
        formData.location === undefined ||
        formData.location === null ||
        formData.location.coordinates.lat === null ||
        formData.location.coordinates.long === null
    ) {
        position[0] = 0;
        position[1] = 0;
        markerPosition[0] = 0;
        markerPosition[1] = 0;
    } else {
        if (position[0] === 0 && position[1] === 0) {
            //console.log("formData", formData.location.coordinates);
            position[0] = formData.location.coordinates.lat;
            position[1] = formData.location.coordinates.long;
        }

        if (markerPosition[0] === 0 && markerPosition[1] === 0) {
            markerPosition[0] = formData.location.coordinates.lat;
            markerPosition[1] = formData.location.coordinates.long;
        }
    }
    if (position[0] === 0 && position[1] === 0) {
        position = AppConstant.DEFAULT_LOCATION;
    }
    if (position === null || position === undefined) {
        position = AppConstant.DEFAULT_LOCATION;

    }
    if (markerPosition[0] === 0 && markerPosition[1] === 0) {
        markerPosition = AppConstant.DEFAULT_LOCATION
    }
    if (markerPosition === null || markerPosition === undefined) {
        markerPosition = AppConstant.DEFAULT_LOCATION
    }
    if (markerPosition[0] === null || markerPosition[1] === null) {
        markerPosition = AppConstant.DEFAULT_LOCATION
    }
};

export const mapSubLocations = (subLocations) => {
    //console.log("Map Sub", subLocations)
    let res = [], i = 0;
    if (!subLocations) {
        return res;
    }
    for (i = 0; i < subLocations.length; i++) {
        res.push([subLocations[i].coordinates.lat, subLocations[i].coordinates.long])
    }
    return (res)
};

export const boundariesMarkers = (subBoundaries) => {
    let res = [], i = 0, j = 0;
    if (!subBoundaries) {
        return res;
    }
    for (i = 0; i < subBoundaries.length; i++) {
        for (j = 0; j < subBoundaries[i].coordinates[0].points.length; j++) {
            res.push([subBoundaries[i].coordinates[0].points[j].lat, subBoundaries[i].coordinates[0].points[j].long])
        }
    }
    return (res)
};

export const boundaryToPolygonCoordinates = (subBoundaries) => {
    let res = [], i = 0, j = 0;
    if (!subBoundaries) {
        return res;
    }
    res = subBoundaries;
    for (i = 0; i < res.length; i++) {
        for (j = 0; j < res[i].coordinates[0].points.length; j++) {
            res[i].coordinates[0].points[j].lng = res[i].coordinates[0].points[j].long;
            delete res[i]["coordinates[0].points[j].long"]
        }
    }
    return (res)
};
