import ContactList from './ContactList';
import ContactCreate from './ContactCreate';
import ContactEdit from './ContactEdit';
import PhoneIcon from '@material-ui/icons/Phone';

export default {
    list: ContactList,
    create: ContactCreate,
    edit: ContactEdit,
    icon: PhoneIcon,
};