import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import AppPricingTitle from "./AppPricingTitle";

const AppPricingFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const AppPricingList = props => (
    <List bulkActionButtons={false} title={<AppPricingTitle type="App Pricing"/>}
          filters={<AppPricingFilter/>}
          {...props}
    >
        <Datagrid>
            <TextField label={"App Activation Price"} source="price"/>
            <TextField label={"Base Currency"} source="baseCurrency"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
        </Datagrid>
    </List>
);

export default AppPricingList;