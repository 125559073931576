import React, {useState} from "react";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    FunctionField,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import Button from "@material-ui/core/Button";
import RegionTitle from "./RegionTitle";
import {styles} from "../../common/styleField";
import {AppConstant} from "../../providers/constants";
import PropTypes from "prop-types";
import ShowBoundary from "../map/ShowBoundary";
import {EditToolbar} from "../toolbar/ToolBar";
import ImageViewer from "../image_viewer/ImageViewer";
import {boundariesMarkers, boundaryToPolygonCoordinates, mapSubLocations} from "../../common/mapValidate";
import ShowMap from "../map/ShowMap";
import MoveButtons from "../order_buttons/MoveButtons";
import ShowPanorama from "../paranoma_images/ShowPanorama";

const RegionEdit = (props) => {
    const [regionName, setRegionName] = useState('');
    const [showBoundary, setShowBoundary] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const formatImage = (value) => {
        if (!value || typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
            return {url: value};
        } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
            return value;
        }
    };
    return (
        <Edit title={<RegionTitle type={"Edit " + `${regionName}` + " "}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label="General">
                    <TextField
                        source="code"
                        validate={required()}
                        style={styles.inputInline}
                    />
                    <TextInput
                        fullWidth
                        label="Link"
                        source="link"
                    />
                    <TextInput
                        fullWidth
                        label="Search Tags"
                        source="searchTags"
                    />
                    <TextInput label="Phone number" source="destinationContact.phone"/>
                    <TextInput label="Email" source="destinationContact.email"/>
                    <TextInput label="Website" source="destinationContact.website"/>
                    <ReferenceInput label="Parent Place" validate={required()} style={styles.inputInline}
                                    source="parentID"
                                    reference={AppConstant.PLACES_ADMIN}
                                    setPagination={100}
                    >
                        <AutocompleteInput optionText="translations[0].title"/>
                    </ReferenceInput>
                    <ReferenceInput label="Parent Sub-Place" validate={required()} source="regionID"
                                    reference="regions-admin">
                        <AutocompleteInput optionText="translations[0].title"/>
                    </ReferenceInput>
                    <ReferenceInput label="Type" validate={required()} source="typeId"
                                    reference="place-type">
                        <AutocompleteInput optionText="type"/>
                    </ReferenceInput>
                    <TextInput fullWidth source="owner"/>
                    <TextInput fullWidth source="addressCode"/>
                    <TextInput source="openTime" type="time"/>
                    <TextInput source="closeTime" type="time"/>
                    <BooleanInput label={"Most Visited"} source={"isMostVisited"}/>
                    <BooleanInput label={"Hide"} source={"isHide"}/>
                    <NumberInput source="rating"/>
                    <FunctionField
                        id="regionThumbnail"
                        label="Thumbnail"
                        render={record => {
                            if (!record.thumbnail || record.thumbnail === '') {
                                return null
                            }
                            return (
                                <ImageViewer
                                    url={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                                    width={AppConstant.IMAGES_WIDTH}
                                    height={AppConstant.IMAGES_HEIGHT}/>
                            )
                        }}
                    />
                    <ImageInput source="thumbnail" label="Input Thumbnail" accept="image/*">
                        <ImageField source="thumbnail" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Images">
                    <ArrayInput source="images" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        return (
                                            <div>
                                                <ImageViewer url={scopedFormData.url}
                                                             width={AppConstant.IMAGES_WIDTH}
                                                             height={AppConstant.IMAGES_HEIGHT}/>
                                                <MoveButtons formArray={formData.images}
                                                             index={formData.images.indexOf(scopedFormData)}
                                                             fieldName={'images'}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Input Images" accept="image/*" multiple>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Panorama">
                    <ArrayInput source="panorama" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        return (
                                            <div>
                                                <ShowPanorama url={scopedFormData.url}/>
                                                <MoveButtons formArray={formData.panorama}
                                                             index={formData.panorama.indexOf(scopedFormData)}
                                                             fieldName={'panorama'}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="panorama" label="Input Images" accept="image/*" multiple>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Region Map">
                    <TextInput fullWidth label="Location" source="location.type" disabled/>
                    <NumberInput
                        label="Coordinates long"
                        source="location.coordinates.long"
                        style={styles.inputInline}
                    />
                    <NumberInput
                        label="Coordinates lat"
                        source="location.coordinates.lat"
                        style={{marginLeft: 50}}
                    />
                    <FormDataConsumer>
                        {({formData, scopedFormData, getSource, dispatch, ...rest}) => {
                            const title = formData.translations[0].title;
                            if (showLocation) {
                                return (
                                    <div>
                                        <Button
                                            id="showRegionLocation"
                                            type="button"
                                            style={{
                                                marginLeft: 0,
                                                marginBottom: 30,
                                                verticalAlign: "middle"
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={(event) => {
                                                setShowLocation(!showLocation)
                                            }}>
                                            Hide Map
                                        </Button>
                                        <ShowMap subLocations={mapSubLocations(formData.subLocations)}
                                                 location={formData.location}
                                                 title={title}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <Button
                                        id="showRegionLocation"
                                        type="button"
                                        style={{
                                            marginLeft: 0,
                                            marginBottom: 30,
                                            verticalAlign: "middle"
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={(event) => {
                                            setShowLocation(!showLocation)
                                        }}>
                                        Show Map
                                    </Button>
                                )
                            }
                        }}
                    </FormDataConsumer>
                    <TextInput fullWidth label="Boundary" source="boundary.type" disabled/>
                    <ArrayInput
                        label="Coordinates"
                        source="boundary.coordinates[0].points"
                        style={styles.boundaries}
                    >
                        <SimpleFormIterator>
                            <NumberInput
                                label="Longitude"
                                source="long"
                                style={styles.inputInline}
                            />
                            <NumberInput
                                label="Latitude"
                                source="lat"
                                style={styles.inputInline}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (showBoundary) {
                                return (
                                    <div>
                                        <Button
                                            id="showRegionBoundary"
                                            type="button"
                                            style={{
                                                marginLeft: 0,
                                                marginBottom: 30,
                                                verticalAlign: "middle"
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={(event) => {
                                                setShowBoundary(!showBoundary)
                                            }}>
                                            Hide Map
                                        </Button>
                                        <ShowBoundary subLocations={formData.subLocations}
                                                      boundary={formData.boundary}
                                                      location={formData.location}
                                                      subBoundaries={boundaryToPolygonCoordinates(formData.subBoundaries)}
                                                      boundaryMarkers={boundariesMarkers(formData.subBoundaries)}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <Button
                                        id="showRegionBoundary"
                                        type="button"
                                        style={{
                                            marginLeft: 0,
                                            marginBottom: 30,
                                            verticalAlign: "middle"
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={(event) => {
                                            setShowBoundary(!showBoundary)
                                        }}>
                                        Show Map
                                    </Button>
                                )
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label="Ticket">
                    <BooleanInput label={"Under restoration, No Visiting"} source={"noVisiting"}/>
                    <FunctionField
                        id="ticketIcon"
                        label="Ticket"
                        render={record => {
                            if (!record.ticket) {
                                return null
                            }
                            if (!record.ticket.url) {
                                return null
                            }
                            if (record.ticket.url === '') {
                                return null
                            }
                            return (
                                <ImageViewer
                                    url={`${AppConstant.SERVER_IMAGE}${record.ticket.url}`}
                                    width={AppConstant.IMAGES_WIDTH}
                                    height={AppConstant.IMAGES_HEIGHT}/>
                            )
                        }}
                    />
                    <ImageInput source="ticket" label="Input Ticket" accept="image/*">
                        <ImageField source="url" title=""/>
                    </ImageInput>
                    {/*<FormDataConsumer>*/}
                    {/*    {({formData, ...rest}) => {*/}
                    {/*        if (formData.noVisiting !== this.state.noVisiting) {*/}
                    {/*            this.isNoVisiting(formData.noVisiting);*/}
                    {/*        }*/}
                    {/*        if (this.state.noVisiting) {*/}
                    {/*            return null;*/}
                    {/*        } else {*/}
                    {/*            return (*/}
                    {/*                <ArrayInput label={"Ticket Details"} source={"priceRate"}*/}
                    {/*                            style={styles.boundaries}>*/}
                    {/*                    <SimpleFormIterator>*/}
                    {/*                        <TextInput fullWidth label={"Tourist Type"} source="touristType"/>*/}
                    {/*                        <NumberInput label={"Price in VND"} source="value"/>*/}
                    {/*                    </SimpleFormIterator>*/}
                    {/*                </ArrayInput>*/}
                    {/*            );*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*</FormDataConsumer>*/}
                </FormTab>
                <FormTab label="Url">
                    <TextInput fullWidth label={"Ticket sale link"} source={"ticketSaleUrl"}/>
                    <TextInput multiline fullWidth label={"3D link"} source={"url3D"}/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );

};

RegionEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default RegionEdit;
