import React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    ReferenceInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import CultArticlesTitle from "./CultArticlesTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import Button from "@material-ui/core/Button";
import {AppConstant} from "../../providers/constants";
import TinyMCEInput from "@palustris/ra-tinymce-input";
import {TinyMceInit} from "../tinymce_init/TinyMceInit";
import Popup from "reactjs-popup";
import {styles} from "../../common/styleField";
import ImageViewer from "../image_viewer/ImageViewer";

const CultArticlesEdit = (props) => {
    return (
        <Edit
            title={<CultArticlesTitle type={"Edit Articles"}/>}
            {...props}
        >
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label={"Articles"}>
                    <ArrayInput source="articles" style={styles.images}>
                        <SimpleFormIterator>
                            <TextInput
                                fullWidth
                                label="Title"
                                source="title"
                            />
                            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                            reference="languages-admin">
                                <AutocompleteInput optionText="code" optionValue="code"/>
                            </ReferenceInput>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    return (
                                        <React.Fragment>
                                            <Popup
                                                trigger={
                                                    <Button
                                                        name={"content" + getSource("content")}
                                                        type="button"
                                                        style={{
                                                            marginLeft: 10,
                                                            marginBottom: 20,
                                                            verticalAlign: "middle"
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                    >
                                                        Show Content
                                                    </Button>
                                                }
                                                contentStyle={{
                                                    width: AppConstant.TINY_WIDTH,
                                                    height: AppConstant.TINY_HEIGHT,
                                                }}
                                                modal
                                                closeOnDocumentClick
                                            >
                                                <React.Fragment>
                                                    <TinyMCEInput
                                                        label={"Article Content"}
                                                        source={getSource("content")}
                                                        init={TinyMceInit(AppConstant.CULTURAL_ARTICLES, formData.id)}
                                                    />
                                                </React.Fragment>
                                            </Popup>
                                        </React.Fragment>
                                    )
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <TextInput
                        fullWidth
                        label="Search Tags"
                        source="searchTags"
                    />
                </FormTab>
                <FormTab label="Images">
                    <ArrayInput source="images" style={styles.translations}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (formData.images && formData.images.length > 0) {
                                        if (scopedFormData && scopedFormData.url) {
                                            return (
                                                <ImageViewer url={`${scopedFormData.url}`}
                                                             width={AppConstant.IMAGES_WIDTH}
                                                             height={AppConstant.IMAGES_HEIGHT}/>
                                            )
                                        }
                                    } else {
                                        return null
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Images" accept="image/*" multiple>
                        <ImageField style={{display: 'none'}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default CultArticlesEdit;