import React from 'react';
import {Edit, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';
import TermsOfServiceTitle from './TermsOfServiceTitle';

const TermsOfServiceEdit = props => (
    <Edit title={<TermsOfServiceTitle type="Edit"/>} {...props}>
        <SimpleForm redirect={false}>
            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                            perPage={10} reference={"languages-admin"}>
                <SelectInput source={"languageCode"} optionText="code" optionValue="code"/>
            </ReferenceInput>
            <TextInput fullWidth label={"Detail"} source="content" multiline/>
        </SimpleForm>
    </Edit>
);

export default TermsOfServiceEdit;