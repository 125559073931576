import LoopTypeList from './LoopTypeList';
import LoopTypeCreate from './LoopTypeCreate';
import LoopTypeEdit from './LoopTypeEdit';
import CategoryIcon from '@material-ui/icons/Category';

export default {
    list: LoopTypeList,
    create: LoopTypeCreate,
    edit: LoopTypeEdit,
    icon: CategoryIcon
};