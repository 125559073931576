import React from 'react';
import {BooleanInput, Edit, required, SimpleForm, TextInput} from 'react-admin';
import RestaurantTypesTitle from './RestaurantTypesTitle';

const RestaurantTypesEdit = props => (
    <Edit title={<RestaurantTypesTitle type="Edit"/>} {...props}>
        <SimpleForm>
            <BooleanInput label={"Hide"} source={"isHide"}/>
            <TextInput source="type" validate={required()}/>
        </SimpleForm>
    </Edit>
);

export default RestaurantTypesEdit;