import React from 'react';

import {Create, required, SimpleForm, TextInput} from 'react-admin';

const LanguageCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" validate={required()}/>
            <TextInput source="currency" validate={required()}/>
        </SimpleForm>
    </Create>
);

export default LanguageCreate;