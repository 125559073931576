import UserCmsList from './UserCmsList';
import UserCmsCreate from './UserCmsCreate';
import UserCmsEdit from './UserCmsEdit';
import UserIcon from '@material-ui/icons/Group';

export default {
    list: UserCmsList,
    create: UserCmsCreate,
    edit: UserCmsEdit,
    icon: UserIcon,
};