import React, {useCallback, useEffect, useRef, useState} from "react";
import {LayerGroup, Map, Marker, Popup, TileLayer} from "react-leaflet";
import '../map/map.css'
import IconColor from "./constants";
import {AppConstant} from "../../providers/constants";
import {useForm} from "react-final-form";

const SubLocationMap = (props) => {
    const {location, draggableMarkers, mainLocation, title} = props;
    const form = useForm();
    const [markers, setMarkers] = useState([]);
    const [zoom, setZoom] = useState(18);
    const [removeIndex, setRemoveIndex] = useState([]);
    const [mLocation, setMLocation] = useState(AppConstant.DEFAULT_LOCATION);
    const [draggable, setDraggable] = useState(true);
    const refmarker = useRef(null);

    useEffect(() => {
        let markerList = [];
        props.location.forEach((value) => {
            if (value.coordinates) {
                if (value.coordinates.lat && value.coordinates.long) {
                    markerList.push([value.coordinates.lat, value.coordinates.long]);
                }
            }
        });
        setMarkers(markerList)
    }, [props.location]);

    const addMarkersCallback = useCallback((newPos) => {
        let markerList = [];
        props.location.forEach((value) => {
            if (value.coordinates) {
                if (value.coordinates.lat && value.coordinates.long) {
                    markerList.push({
                        type: '',
                        coordinates: {lat: value.coordinates.lat, long: value.coordinates.long}
                    });
                }
            }
        });
        markerList.push({type: '', coordinates: {lat: newPos[0], long: newPos[1]}});
        form.change("location", markerList);
        setMarkers([...markers, newPos]);
    }, [form, markers, props.location]);

    const addMarker = () => (e) => {
        const newPos = [e.latlng.lat, e.latlng.lng];
        addMarkersCallback(newPos)
    };

    const updateMarkersCallback = useCallback((markerIndex, lat, lng) => {
        let markerList = [];
        props.location.forEach((value) => {
            if (value.coordinates) {
                if (value.coordinates.lat && value.coordinates.long) {
                    markerList.push({
                        type: '',
                        coordinates: {lat: value.coordinates.lat, long: value.coordinates.long}
                    });
                }
            }
        });
        markerList[markerIndex].coordinates.lat = lat;
        markerList[markerIndex].coordinates.long = lng;
        form.change("location", markerList);
    }, [form, props.location, markers]);

    const updateMarkers = (markers) => (e) => {
        const latLng = e.target.getLatLng();
        const markerIndex = e.target.options.marker_index;
        updateMarkersCallback(markerIndex, latLng.lat, latLng.lng)
    };

    return (
        <div id="mapId" className="leaflet-container">
            <Map
                id="mapId"
                preferCanvas={true}
                center={mainLocation ? mainLocation : mLocation}
                draggable={draggable}
                doubleClickZoom={false}
                ondblclick={addMarker()}
                zoom={zoom}
            >
                <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker
                    icon={IconColor("red")}
                    draggable={false}
                    position={mainLocation}
                    ref={refmarker}
                >
                    <Popup minWidth={90}>
                            <span className="markerSpan">
                                {title}<br/>
                                {mainLocation[1]} {mainLocation[0]}
                            </span>
                    </Popup>
                </Marker>
                {markers.length !== 0 ? markers.map((position, idx) =>
                    <LayerGroup>
                        <Marker icon={IconColor("green")}
                                key={`marker-${idx}`}
                                position={position}
                                marker_index={`${idx}`}
                                draggable={draggableMarkers}
                                onDragend={updateMarkers(location)}
                        >
                            <Popup>
                                <span>Marker number {idx + 1}</span>
                            </Popup>
                        </Marker>
                    </LayerGroup>
                ) : null}
            </Map>
        </div>
    )

};

// class ShowSubLocationMap extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             center: {
//                 lat: 0,
//                 lng: 0
//             },
//             marker: {
//                 lat: 0,
//                 lng: 0
//             },
//             markers: [],
//             zoom: 18,
//             draggable: true,
//             formValue: this.props.location,
//             mainLocation: AppConstant.DEFAULT_LOCATION,
//             removedIndex: [],
//         };
//         this.refmarker = React.createRef();
//         this.addMarker = this.addMarker.bind(this);
//         this.updateMarkers = this.updateMarkers.bind(this);
//         this.initData = this.initData.bind(this);
//     }
//
//     componentWillReceiveProps(nextProps, nextContext) {
//         // console.log("New", nextProps.location)
//         // console.log("Old", this.props.location)
//         // if (!nextProps.removedIndex) {
//         //     return
//         // }
//         if (this.props.location && nextProps.boundary) {
//             if (nextProps.location !== this.props.location) {
//                 for (let i = 0; i < this.props.location.length; i++) {
//                     if (nextProps.location.indexOf(this.props.location[i]) === -1)
//                         this.props.removeFunc(nextProps.boundary, i);
//                     //nextProps.boundary.splice(i, 1)
//                 }
//             }
//         }
//     }
//
//     toggleDraggable = (draggable) => {
//         this.setState({draggable: !draggable});
//     };
//
//     addMarker = (markers, markerList, dispatch) => (e) => {
//         const newPos = [e.latlng.lat, e.latlng.lng];
//         //console.log("Marker List", markerList);
//         //console.log("markers", this.state.markers);
//         if (!markerList) {
//             markerList = [];
//         }
//         markerList.push({coordinates: {lat: newPos[0], long: newPos[1]}});
//         this.setState(prevState => (
//             {
//                 markers: prevState.markers.concat([newPos]),
//             }
//         ));
//         if (dispatch) {
//             dispatch(
//                 change(REDUX_FORM_NAME, "location", markerList),
//             );
//         }
//     };
//
//     initData = (formData) => {
//         let markerList = [];
//         //console.log("location", formData);
//         formData.forEach((value) => {
//             if (value.coordinates) {
//                 if (value.coordinates.lat && value.coordinates.long) {
//                     markerList.push([value.coordinates.lat, value.coordinates.long]);
//                 }
//             }
//         });
//         this.setState({markers: markerList});
//     };
//
//     updateMarkers = (markers, dispatch) => (e) => {
//         const latLng = e.target.getLatLng();
//         const markerIndex = e.target.options.marker_index;
//         this.setState(prevState => {
//             const markerData = [...prevState.markers];
//             markerData[markerIndex] = [latLng.lat, latLng.lng];
//             return {markers: markerData};
//         });
//         if (dispatch) {
//             //console.log("Index", markerIndex.toString())
//             dispatch(
//                 change(REDUX_FORM_NAME, "location[" + markerIndex.toString() + "]", {
//                     coordinates: {
//                         lat: latLng.lat,
//                         long: latLng.lng
//                     }
//                 }),
//             );
//         }
//     };
//
//     render() {
//         const {location, draggableMarkers, mainLocation, title, dispatch} = this.props;
//         if (location) {
//             if (this.state.markers.length !== location.length) {
//                 let flag = true;
//                 location.forEach((element) => {
//                     //console.log(element)
//                     if (element.coordinates) {
//                         if (!element.coordinates.lat || !element.coordinates.long) {
//                             flag = false
//                         }
//                     } else {
//                         flag = false
//                     }
//                 });
//                 if (flag) {
//                     this.initData(location)
//                 }
//             }
//         }
//         return (
//             <div id="mapId" className="leaflet-container">
//                 <Map
//                     id="mapId"
//                     preferCanvas={true}
//                     center={mainLocation ? mainLocation : this.state.mainLocation}
//                     draggable={this.state.draggable}
//                     doubleClickZoom={false}
//                     ondblclick={this.addMarker(this.state.markers, location, dispatch)}
//                     zoom={this.state.zoom}
//                 >
//                     <TileLayer
//                         attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
//                         url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
//                     />
//                     <Marker
//                         icon={IconColor("red")}
//                         draggable={false}
//                         position={mainLocation}
//                         ref={this.refmarker}
//                     >
//                         <Popup minWidth={90}>
//                             <span className="markerSpan">
//                                 {title}<br/>
//                                 {mainLocation[1]} {mainLocation[0]}
//                             </span>
//                         </Popup>
//                     </Marker>
//                     {this.state.markers.length !== 0 ? this.state.markers.map((position, idx) =>
//                         <LayerGroup>
//                             <Marker icon={IconColor("green")}
//                                     key={`marker-${idx}`}
//                                     position={position}
//                                     marker_index={`${idx}`}
//                                     draggable={draggableMarkers}
//                                     onDragend={this.updateMarkers(location, dispatch)}
//                             >
//                                 <Popup>
//                                     <span>Marker number {idx + 1}</span>
//                                 </Popup>
//                             </Marker>
//                         </LayerGroup>
//                     ) : null}
//                 </Map>
//             </div>
//         )
//     }
// }

export default SubLocationMap;