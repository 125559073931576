import React from 'react';

import {Create, SimpleForm} from 'react-admin';

const FeedBackCreate = props => (
    <Create {...props}>
        <SimpleForm>
        </SimpleForm>
    </Create>
);

export default FeedBackCreate;