import React from 'react';
import {
    Edit,
    FunctionField,
    ImageField,
    ImageInput,
    minValue,
    NumberInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';
import TaxiCompanyTitle from './TaxiCompanyTitle';
import ImageViewer from "../image_viewer/ImageViewer";

const validatePrice = [required(), minValue(0)];

const TaxiCompanyEdit = props => (
    <Edit title={<TaxiCompanyTitle type="Edit"/>} {...props}>
        <SimpleForm redirect={false}>
            <TextInput fullWidth label={"Title"} source="title" validate={required()}/>
            <TextInput label={"Phone Number"} source="phone" validate={required()}/>
            <NumberInput label={"Stars"} source="stars"/>
            <NumberInput label={"Price Per Kilometer in dong"} source="price" validate={validatePrice}/>
            <FunctionField
                id="thumbnailIcon"
                label="Thumbnail Icon"
                render={record => {
                    if (!record.thumbnail) {
                        return null;
                    }
                    if (!record.thumbnail.url || record.thumbnail.url === "") {
                        return null;
                    }
                    return (
                        <div>
                            <ImageViewer
                                alt={record.thumbnail}
                                url={`${record.thumbnail.url}`}
                                width={240}
                                height={160}
                            />
                        </div>
                    );
                }}
            />
            <ImageInput source="thumbnail" label="Thumbnail" accept="image/*">
                <ImageField source="thumbnail"/>
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export default TaxiCompanyEdit;