import React from 'react';

import {
    AutocompleteInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm
} from 'react-admin';
import FreeAudioTitle from "./FreeAudioTitle";

const subject = [
    {id: "place", name: "Place"},
    {id: "region", name: "Sub-Place"},
    {id: "object", name: "Object"}
];

const FreeAudioCreate = props => (
    <Create title={<FreeAudioTitle type="Create"/>} {...props}>
        <SimpleForm>
            <SelectInput label={"Subject Type"} source={"subjectType"} validate={required()}
                         choices={subject}/>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (formData) {
                        let ref = "";
                        if (formData && formData.subjectType) {
                            if (formData.subjectType === "place") {
                                ref = "places-admin"
                            }
                            if (formData.subjectType === "region") {
                                ref = "regions-admin"
                            }
                            if (formData.subjectType === "object") {
                                ref = "objects-admin"
                            }
                            return (
                                <div>
                                    <ReferenceInput label="Subject" validate={required()}
                                                    source={"idValue"}
                                                    reference={ref}>
                                        <AutocompleteInput optionText="translations[0].title"/>
                                    </ReferenceInput>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export default FreeAudioCreate;