import React from 'react';
import {Edit, SimpleForm, TextField} from 'react-admin';
import FeedbackTitle from './FeedbackTitle';

const FeedbackEdit = props => (
    <Edit title={<FeedbackTitle type="Edit"/>} {...props}>
        <SimpleForm>
            <TextField source="userID"/>
            <TextField source="feedback"/>
            <TextField source={"createdAt"}/>
        </SimpleForm>
    </Edit>
);

export default FeedbackEdit;