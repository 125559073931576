import React from "react";
import RestaurantTitle from "./RestaurantTitle";
import {AppConstant} from "../../providers/constants";
import {AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput} from "react-admin";

const typeOptions = [
    {id: "sightseeing", name: "Sightseeing"},
    {id: "hotel", name: "Hotel"},
    {id: "restaurant", name: "Restaurant"},
    {id: "restroom", name: "Restroom"}
];

const RestaurantCreate = props => (
    <Create title={<RestaurantTitle type="Create"/>} {...props}>
        <SimpleForm>
            <TextInput
                label="Name"
                source="translations[0].title"
                validate={required()}
            />
            <ReferenceInput label="Language Code" validate={required()} source="translations[0].languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
            <ReferenceInput label="Parent Place" source="parentPlace"
                            reference={AppConstant.PLACES_ADMIN}>
                <AutocompleteInput optionText="translations[0].title"/>
            </ReferenceInput>
            {/*<ReferenceInput label="Parent Sub-Place"  source="parentRegion"*/}
            {/*                reference={AppConstant.REGIONS_ADMIN}>*/}
            {/*    <AutocompleteInput optionText="translations[0].title"/>*/}
            {/*</ReferenceInput>*/}
            <ReferenceInput label="Type" validate={required()} source="typeId"
                            reference="restaurant-type">
                <AutocompleteInput optionText="type"/>
            </ReferenceInput>
            {/*<SelectInput source="type" validate={required()} choices={typeOptions} />*/}
            <TextInput source="owner"/>
            <TextInput source="addressCode"/>
            <TextInput
                label="Link"
                source="link"
            />
            <TextInput fullWidth label="Search Tags" source="searchTags"/>
        </SimpleForm>
    </Create>
);

export default RestaurantCreate;
