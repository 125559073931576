import React, {useCallback, useRef, useState} from "react";
import {LayerGroup, Map, Marker, Popup, TileLayer} from "react-leaflet";
import '../map/map.css'
import {MarkerValidate} from './common'
import IconColor from "./constants";
import {AppConstant} from "../../providers/constants";
import {useForm} from "react-final-form";

const ShowMap = (props) => {
    const form = useForm();
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    });
    const [marker, setMarker] = useState({
        lat: 0,
        lng: 0
    });
    const [zoom, setZoom] = useState(18);
    const [draggable, setDraggable] = useState(true);
    const refmarker = useRef(null);

    const {location, subLocations, title} = props;
    let position = [center.lat, center.lng];
    let markerPosition = [marker.lat, marker.lng];
    MarkerValidate(location, position, markerPosition);

    const toggleDraggable = () => {
        setDraggable(!draggable);
    };

    const handleUpdate = useCallback((marker) => {
        form.change('location.coordinates.lat', marker.lat);
        form.change('location.coordinates.long', marker.lng);
        setMarker({
            lat: marker.lat,
            lng: marker.lng
        });
        setCenter({
            lat: marker.lat,
            lng: marker.lng
        });
    }, [form]);

    const updatePosition = () => {
        const currentRef = refmarker.current;
        if (currentRef) {
            const updatedMarker = currentRef.leafletElement.getLatLng();
            console.log("Current Ref", currentRef);
            if (updatedMarker) {
                if (updatedMarker.lat && updatedMarker.lng) {
                    handleUpdate(updatedMarker);
                }
            }
        }
    };

    return (
        <div id="mapId" className="leaflet-container">
            <Map onZoomEnd={(event) => setZoom(event.target._zoom)} id="mapId" center={position} zoom={zoom}
                 maxZoom={AppConstant.MAX_MAP_ZOOM}>
                <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                {subLocations ? subLocations.map((position, idx) =>
                    <LayerGroup>
                        <Marker
                            icon={IconColor("black")}
                            key={`marker-${idx}`} position={position}
                            marker_index={`${idx}`}
                            draggable={false}
                        >
                            <Popup>
                                <span>Sub Marker number {idx + 1}</span>
                            </Popup>
                        </Marker>
                    </LayerGroup>
                ) : null}
                <Marker
                    draggable={draggable}
                    onDragend={updatePosition}
                    position={markerPosition}
                    ref={refmarker}
                >
                    >
                    <Popup minWidth={140}>
                          <span onClick={toggleDraggable}>
                              {title}
                              <br/>
                              {draggable
                                  ? "Type: Draggable Marker"
                                  : "Type: Fixed Marker"}
                          </span>
                    </Popup>
                </Marker>
            </Map>
        </div>
    )
};

export default ShowMap