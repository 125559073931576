import {Resource} from "react-admin";
import places from "../places";
import regions from "../regions";
import objects from "../objects";
import React from "react";
import restaurants from "../restaurants";
import userCms from "../userCms";
import users from "../users";
import freeAudio from "../freeAudio";
import languages from "../languages";
import qr from "../qrCode";
import timetables from "../timetables";
import loops from "../loops";
import tours from "../tours";
import placeTypes from "../placeTypes";
import contacts from "../contacts";
import feedback from "../feedback";
import help from "../help";
import region_panorama_notations from "../region_panorama_notations";
import place_panorama_notations from "../place_panorama_notations";
import restaurant_panorama_notations from "../restaurant_panorama_notations";
import object_panorama_notations from "../object_panorama_notations";
import restaurant_type from "../restaurant_type"
import new_sub_location from "../new_sub_location";
import cultural_articles from "../cultural_articles"
import app_pricing from "../app_pricing"
import tos from "../terms_of_service"
import loop_type from "../loop_type"
import taxi_company from "../taxi_company"
import RegOrderEdit from "../region_order/RegOrderEdit";
import UserCmsEdit from "../userCms/UserCmsEdit";
import {AppConstant} from "../../providers/constants";
import ObjTranslationsEdit from "../object_translations/ObjTranslationsEdit";
import ResTranslationsEdit from "../restaurant_translation/ResTranslationsEdit";
import RegTranslationsEdit from "../region_translations/RegTranslationsEdit";
import PTranslationsEdit from "../place_translations/PTranslationsEdit";
import UserIcon from '@material-ui/icons/Group';
import PlaceIcon from '@material-ui/icons/Place';
import PanoramaIcon from '@material-ui/icons/Panorama';
import ListIcon from '@material-ui/icons/List';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const displayLabel = (name, menuParentName) => {
    if (!menuParentName) {
        return {label: name}
    }
    return {label: name, menuParent: menuParentName}
};

export const ShowAdmin = () => {
    return (
        [
            <Resource name="Users" icon={UserIcon} options={{"label": "Users", "isMenuParent": true}}/>,
            <Resource
                name="users-cms"
                options={displayLabel("Administrator", "Users")}
                {...userCms}
            />,
            <Resource
                name="users-admin"
                options={displayLabel("Mobile", "Users")}
                {...users}
            />,
            <Resource name="Destinations" icon={PlaceIcon}
                      options={{"label": "Destinations", "isMenuParent": true}}/>,
            <Resource
                name={AppConstant.PLACES_ADMIN}
                options={displayLabel("Places", "Destinations")}
                {...places}
            />,
            <Resource
                name={AppConstant.REGION_ORDERS_ADMIN}
                options={displayLabel("Sub-Place Order", "Destinations")}
                edit={RegOrderEdit}
            />,
            <Resource
                name="regions-admin"
                options={displayLabel("Sub-Places", "Destinations")}
                {...regions}
            />,
            <Resource
                name="objects-admin"
                options={displayLabel("Objects", "Destinations")}
                {...objects}
            />,
            <Resource
                name="restaurants-admin"
                options={displayLabel("Restaurants", "Destinations")}
                {...restaurants}
            />,
            <Resource
                name="new-sub-location-admin"
                options={displayLabel("Co-Locations", "Destinations")}
                {...new_sub_location}
            />,
            <Resource name="Types" icon={ListIcon} options={{"label": "Types", "isMenuParent": true}}/>,
            <Resource
                name="place-type"
                options={displayLabel("Place, User Role", "Types")}
                {...placeTypes}
            />,
            <Resource
                name="restaurant-type"
                options={displayLabel("Restaurant", "Types")}
                {...restaurant_type}
            />,
            <Resource name="Panorama Notations" icon={PanoramaIcon}
                      options={{"label": "Panorama Notations", "isMenuParent": true}}/>,
            <Resource
                name={AppConstant.PLACE_PANORAMA_NOTATIONS}
                options={displayLabel("Place", "Panorama Notations")}
                {...place_panorama_notations}
            />,
            <Resource
                name={AppConstant.REGION_PANORAMA_NOTATIONS}
                options={displayLabel("Sub-Place", "Panorama Notations")}
                {...region_panorama_notations}
            />,
            <Resource
                name={AppConstant.RESTAURANTS_PANORAMA_NOTATIONS}
                options={displayLabel("Restaurant", "Panorama Notations")}
                {...restaurant_panorama_notations}
            />,
            <Resource
                name={AppConstant.OBJECT_PANORAMA_NOTATIONS}
                options={displayLabel("Object", "Panorama Notations")}
                {...object_panorama_notations}
            />,
            <Resource
                name={AppConstant.CULTURAL_ARTICLES}
                options={displayLabel("Cultural Articles")}
                {...cultural_articles}
            />,
            <Resource name="Loops" icon={DirectionsRunIcon}
                      options={{"label": "Loops", "isMenuParent": true}}/>,
            <Resource
                name="loops-admin"
                options={displayLabel("Loops Records", "Loops")}
                {...loops}
            />,
            <Resource
                name="loop-types-admin"
                options={displayLabel("Loop Types", "Loops")}
                {...loop_type}
            />,
            <Resource
                name="timetable"
                options={displayLabel("Timetables", "Loops")}
                {...timetables}
            />,
            <Resource
                name="tours-admin"
                options={displayLabel("Tours")}
                {...tours}
            />,
            <Resource
                name="languages-admin"
                options={displayLabel("Languages")}
                {...languages}
            />,
            <Resource name="Miscellaneous" icon={MenuOpenIcon}
                      options={{"label": "Miscellaneous", "isMenuParent": true}}/>,
            // <Resource
            //     name="qr-admin"
            //     options={displayLabel("QRCode", "Miscellaneous")}
            //     {...qr}
            // />,
            <Resource
                name="freeAudio-admin"
                options={displayLabel("Free Audio", "Miscellaneous")}
                {...freeAudio}
            />,
            <Resource
                name="contacts-admin"
                options={displayLabel("Emergency Contacts", "Miscellaneous")}
                {...contacts}
            />,
            <Resource
                name="feedback-admin"
                options={displayLabel("Feedback", "Miscellaneous")}
                {...feedback}
            />,
            <Resource
                name="help-admin"
                options={displayLabel("Help", "Miscellaneous")}
                {...help}
            />,
            <Resource
                name="translations-admin/place"
                options={displayLabel("Place Translations")}
                edit={PTranslationsEdit}
            />,
            <Resource
                name="translations-admin/region"
                options={displayLabel("Sub-Place Translations")}
                edit={RegTranslationsEdit}
            />,
            <Resource
                name="translations-admin/restaurant"
                options={displayLabel("Restaurant Translations")}
                edit={ResTranslationsEdit}
            />,
            <Resource
                name="translations-admin/object"
                options={displayLabel("Object Translations")}
                edit={ObjTranslationsEdit}
            />,
            <Resource
                name="taxi-company-admin"
                options={displayLabel("Taxi Company", "Miscellaneous")}
                {...taxi_company}
            />,
            <Resource
                name="appPricing-admin"
                options={displayLabel("App Pricing", "Miscellaneous")}
                {...app_pricing}
            />,
            <Resource
                name="terms-of-service-admin"
                options={displayLabel("Terms of Service", "Miscellaneous")}
                {...tos}
            />,
        ]
    )
};

export const ShowSightseeing = () => {
    return (
        [
            <Resource
                name="users-cms"
                options={displayLabel("Admin")}
                edit={UserCmsEdit}
            />,
            <Resource
                name="users-admin"
                options={displayLabel("Mobile Users")}
            />,
            <Resource
                name={AppConstant.PLACES_ADMIN}
                options={displayLabel("Places")}
                {...places}
            />,
            <Resource
                name="new-sub-location-admin"
                options={displayLabel("Co-Locations")}
                {...new_sub_location}
            />,
            <Resource
                name="restaurants-admin"
                options={displayLabel("Restaurants")}
            />,
            <Resource
                name="restaurant-type"
                options={displayLabel("Restaurants Types")}
            />,
            <Resource
                name="regions-admin"
                options={displayLabel("Sub-Places")}
                {...regions}
            />,
            <Resource
                name="objects-admin"
                options={displayLabel("Objects")}
                {...objects}
            />,
            <Resource
                name="freeAudio-admin"
                options={displayLabel("Free Audio")}
            />,
            <Resource
                name="languages-admin"
                options={displayLabel("Languages")}
            />,
            // <Resource
            //     name="qr-admin"
            //     options={displayLabel("QRCode")}
            // />,
            <Resource
                name="timetable"
                options={displayLabel("Timetables")}
            />,
            <Resource
                name="loops-admin"
                options={displayLabel("Loops")}
            />,
            <Resource
                name="tours-admin"
                options={displayLabel("Tours")}
            />,
            <Resource
                name="place-type"
                options={displayLabel("Place Types")}
            />,
            <Resource
                name="contacts-admin"
                options={displayLabel("Contacts")}
            />,
            <Resource
                name="feedback-admin"
                options={displayLabel("Feedback")}
            />,
            <Resource
                name="help-admin"
                options={displayLabel("Help")}
            />,
            <Resource
                name="translations-admin/place"
                options={displayLabel("Place Translations")}
                edit={PTranslationsEdit}
            />,
            <Resource
                name="translations-admin/region"
                options={displayLabel("Sub-Place Translations")}
                edit={RegTranslationsEdit}
            />,
            <Resource
                name="translations-admin/object"
                options={displayLabel("Object Translations")}
                edit={ObjTranslationsEdit}
            />,
        ]
    )
};

export const ShowRestaurant = () => {
    return (
        [
            <Resource
                name="users-cms"
                options={displayLabel("Admin")}
                edit={UserCmsEdit}
            />,
            <Resource
                name="users-admin"
                options={displayLabel("Mobile Users")}
            />,
            <Resource
                name={AppConstant.PLACES_ADMIN}
                options={displayLabel("Places")}
            />,
            <Resource
                name="new-sub-location-admin"
                options={displayLabel("Co-Locations")}
                {...new_sub_location}
            />,
            <Resource
                name="restaurants-admin"
                options={displayLabel("Restaurants")}
                {...restaurants}
            />,
            <Resource
                name="restaurant-type"
                options={displayLabel("Restaurants Types")}
                {...restaurant_type}
            />,
            <Resource
                name="regions-admin"
                options={displayLabel("Sub-Places")}
            />,
            <Resource
                name="objects-admin"
                options={displayLabel("Objects")}
            />,
            <Resource
                name="freeAudio-admin"
                options={displayLabel("Free Audio")}
            />,
            <Resource
                name="languages-admin"
                options={displayLabel("Languages")}
            />,
            // <Resource
            //     name="qr-admin"
            //     options={displayLabel("QRCode")}
            // />,
            <Resource
                name="timetable"
                options={displayLabel("Timetables")}
            />,
            <Resource
                name="loops-admin"
                options={displayLabel("Loops")}
            />,
            <Resource
                name="tours-admin"
                options={displayLabel("Tours")}
            />,
            <Resource
                name="place-type"
                options={displayLabel("Place Types")}
            />,
            <Resource
                name="contacts-admin"
                options={displayLabel("Contacts")}
            />,
            <Resource
                name="feedback-admin"
                options={displayLabel("Feedback")}
            />,
            <Resource
                name="help-admin"
                options={displayLabel("Help")}
            />,
            <Resource
                name="translations-admin/restaurant"
                options={displayLabel("Restaurant Translations")}
                edit={ResTranslationsEdit}
            />,
        ]
    )
};

export const ShowPlaces = () => {
    return (
        [
            <Resource
                name="users-cms"
                options={displayLabel("Admin")}
                edit={UserCmsEdit}
            />,
            <Resource
                name="users-admin"
                options={displayLabel("Mobile Users")}
            />,
            <Resource
                name={AppConstant.PLACES_ADMIN}
                options={displayLabel("Places")}
                {...places}
            />,
            <Resource
                name="restaurants-admin"
                options={displayLabel("Restaurants")}
            />,
            <Resource
                name="restaurant-type"
                options={displayLabel("Restaurants Types")}
            />,
            <Resource
                name="regions-admin"
                options={displayLabel("Sub-Places")}
            />,
            <Resource
                name="objects-admin"
                options={displayLabel("Objects")}
            />,
            <Resource
                name="freeAudio-admin"
                options={displayLabel("Free Audio")}
            />,
            <Resource
                name="languages-admin"
                options={displayLabel("Languages")}
            />,
            // <Resource
            //     name="qr-admin"
            //     options={displayLabel("QRCode")}
            // />,
            <Resource
                name="timetable"
                options={displayLabel("Timetables")}
            />,
            <Resource
                name="loops-admin"
                options={displayLabel("Loops")}
            />,
            <Resource
                name="tours-admin"
                options={displayLabel("Tours")}
            />,
            <Resource
                name="place-type"
                options={displayLabel("Place Types")}
            />,
            <Resource
                name="contacts-admin"
                options={displayLabel("Emergency Contacts")}
            />,
            <Resource
                name="feedback-admin"
                options={displayLabel("Feedback")}
            />,
            <Resource
                name="help-admin"
                options={displayLabel("Help")}
            />,
            <Resource
                name="translations-admin/place"
                options={displayLabel("Place Translations")}
                edit={PTranslationsEdit}
            />,
            <Resource
                name="translations-admin/object"
                options={displayLabel("Object Translations")}
                edit={ObjTranslationsEdit}
            />,
            <Resource
                name="translations-admin/restaurant"
                options={displayLabel("Restaurant Translations")}
                edit={ResTranslationsEdit}
            />,
        ]
    )
};
