import React from 'react';

import {Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput,} from 'react-admin';
import TermsOfServiceTitle from './TermsOfServiceTitle';

const TermsOfServiceCreate = props => (
    <Create title={<TermsOfServiceTitle type="Create"/>} {...props}>
        <SimpleForm>
            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                            perPage={10} reference={"languages-admin"}>
                <SelectInput source={"languageCode"} optionText="code" optionValue="code"/>
            </ReferenceInput>
            <TextInput fullWidth label={"Details"} source="content"/>
        </SimpleForm>
    </Create>
);

export default TermsOfServiceCreate;