import jwt_decode from "jwt-decode";
import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title} from 'react-admin';
import {AppConstant} from "../../providers/constants";
import CsvButton from "../csv/csv";
import {Redirect} from "react-router-dom";
import UserProfile from "../user_profile/profile";
// import MyClock from "../clock/clock";

const MobileDocumentURL = `${AppConstant.SERVER_IMAGE}/resources/frontend-features/FE.pdf`;
const BEMobileDocumentURL = `${AppConstant.USER_ADDRESS}/swagger/index.html`;
const BECmsDocumentURL = `${AppConstant.ADMIN_ADDRESS}/swagger/index.html`;
const LogUrl = 'http://171.244.51.143:9986/';
const CMSLandingTrippal = 'https://cms.landing.huetrippal.com';

// const firebaseConfig = {
//     apiKey: "AIzaSyA5zYlRn1iFAxfb16-9l9T6I8ZmHnZZSUs",
//     authDomain: "hue-trippal.firebaseapp.com",
//     databaseURL: "https://hue-trippal.firebaseio.com",
//     projectId: "hue-trippal",
//     storageBucket: "hue-trippal.appspot.com",
//     messagingSenderId: "52035630332",
//     appId: "1:52035630332:web:711a9bc7b6d4482306d379",
//     measurementId: "G-4ZEENEXCEF"
// };

const Dashboard = () => {
    const token = localStorage.getItem("token");
    const [userId, setUserId] = useState(null);
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token && token !== "" && token !== 'undefined') {
            setUserId(jwt_decode(token).userID);
        }
    }, []);


    return (token && token !== "") ? [
        <Card>
            <Title title="Welcome to Hue Trippal's administration site Version 2"/>
            <CardContent>
                The Administration site enables admin users to:
                <CardContent>
                    - Show, add, edit, and delete data
                </CardContent>
                <CardContent>
                    - Create QR Code images for account activation.
                </CardContent>
                <CardContent>
                    - Show reviews about a place, sub-place.
                </CardContent>
                <CardContent>
                    - Drag and update the coordinates of a place's or region's location.
                </CardContent>
                {/*<CardContent>*/}
                {/*    <a href={`${MobileDocumentURL}`} target="_blank"*/}
                {/*       rel="noopener noreferrer">Mobile Frontend*/}
                {/*        Document</a>*/}
                {/*</CardContent>*/}
                {/*<CardContent>*/}
                {/*    <a href={`${BEMobileDocumentURL}`} target="_blank"*/}
                {/*       rel="noopener noreferrer">Mobile Backend*/}
                {/*        Document</a>*/}
                {/*</CardContent>*/}
                {/*<CardContent>*/}
                {/*    <a href={`${BECmsDocumentURL}`} target="_blank"*/}
                {/*       rel="noopener noreferrer">CMS Backend Document</a>*/}
                {/*</CardContent>*/}
                {/*<CardContent>*/}
                {/*    <a href={`${CMSLandingTrippal}`} target="_blank"*/}
                {/*       rel="noopener noreferrer">CMS Hue Landing*/}
                {/*        Trippal</a>*/}
                {/*</CardContent>*/}
                {/*<CardContent>*/}
                {/*    <a href={`${LogUrl}`} target="_blank" rel="noopener noreferrer">Show*/}
                {/*        Logs</a>*/}
                {/*</CardContent>*/}
                <CsvButton/>
                {userId ? <UserProfile userID={userId}/> : null}
            </CardContent>
        </Card>,
    ] : [<Redirect to="/login"/>]
};

export default Dashboard;