import React from "react";

import {AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput} from "react-admin";
import PlaceTitle from "./PlaceTitle";

const redirect = (basePath, id, data) => {
    return `/places-admin/${id}`;
};

const PlaceCreate = props => (
    <Create title={<PlaceTitle type="Create"/>} {...props}>
        <SimpleForm redirect={redirect}>
            <TextInput
                label="Name"
                source="translations[0].title"
                validate={required()}
            />
            <ReferenceInput label="Language Code" validate={required()} source="translations[0].languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
            <ReferenceInput label="Type" validate={required()} source="typeId"
                            reference="place-type">
                <AutocompleteInput optionText="type"/>
            </ReferenceInput>
            <TextInput fullWidth label="Search Tags" source="searchTags"/>
            <TextInput source="owner"/>
            <TextInput fullWidth source="addressCode"/>
            <TextInput source="openTime" type="time"/>
            <TextInput source="closeTime" type="time"/>
            <TextInput
                label="Link"
                source="link"
            />
        </SimpleForm>
    </Create>
);

export default PlaceCreate;
