import Popup from "reactjs-popup";
import {AppConstant} from "../../providers/constants";
import React, {Component} from "react";

class ImageViewer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {url, width, height} = this.props;
        let image = new Image();
        let imageUrl = '';
        if (url.includes(AppConstant.SERVER_IMAGE) || url.includes('blob:')) {
            imageUrl = url
        } else {
            imageUrl = `${AppConstant.SERVER_IMAGE}${url}`
        }
        image.src = imageUrl;
        return (
            <Popup
                trigger={
                    <img
                        alt=""
                        src={imageUrl}
                        width={width}
                        height={height}
                        style={{display: "inline-block", padding: "10px"}}
                    />
                }
                position="left center"
                contentStyle={{
                    width: () => {
                        if (image.naturalWidth > image.naturalHeight) {
                            if (image.naturalWidth > 854) {
                                return 854
                            }
                        } else {
                            if (image.naturalWidth > 480) {
                                return 480
                            }
                        }
                        return image.naturalWidth
                    },
                    height: () => {
                        if (image.naturalWidth > image.naturalHeight) {
                            if (image.naturalHeight > 480) {
                                return 480
                            }
                        } else {
                            if (image.naturalHeight > 854) {
                                return 854
                            }
                        }
                        return image.naturalHeight
                    }
                }}
                modal
                closeOnDocumentClick
                closeOnEscape
            >
                {() => {
                    if (image.naturalWidth > image.naturalHeight) {
                        return (
                            <img style={{
                                width: `${image.naturalWidth > 854 ? 854 : image.naturalWidth}px`,
                                height: `${image.naturalHeight > 480 ? 480 : image.naturalHeight}px`
                            }} src={imageUrl} alt=""/>
                        )
                    } else {
                        return (
                            <img style={{
                                width: `${image.naturalWidth > 480 ? 480 : image.naturalWidth}px`,
                                height: `${image.naturalHeight > 854 ? 854 : image.naturalHeight}px`
                            }} src={imageUrl} alt=""/>
                        )
                    }
                }}
            </Popup>
        )
    }
}

export default ImageViewer