import QRCodeList from "./QRCodeList";
import QRCreate from "./QRCodeCreate";
import QRCodeEdit from "./QRCodeEdit";
import CropFreeIcon from '@material-ui/icons/CropFree';

export default {
    list: QRCodeList,
    create: QRCreate,
    edit: QRCodeEdit,
    icon: CropFreeIcon
};