import {AppConstant} from "./constants";

let refreshID = null;

function timeoutPromise(ms, promise) {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error("promise timeout"))
        }, ms);
        promise.then(
            (res) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err) => {
                clearTimeout(timeoutId);
                reject(err);
            }
        );
    })
}

const getRefreshToken = async () => {
    let url = `${AppConstant.API_URL}/users-cms/refresh`;
    let tokenData = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        },
    });
    return await tokenData.json();
};

export const refreshToken = () => {
    timeoutPromise(3000, getRefreshToken()).then((data) => {
        let refreshStatus = data.code;
        if (refreshStatus === 200) {
            localStorage.setItem("token", data.access_token)
        } else {
            localStorage.removeItem("token");
            localStorage.removeItem("permissions");
            disableInterval()
        }
    }).catch((error) => {
        console.log("Error", error);
    });
};

export const disableInterval = () => {
    //alert('Interval disable')
    clearInterval(refreshID)
};

const enableInterval = () => {
    //alert('Interval enable')
    refreshID = setInterval(function () {
        refreshToken();
    }, AppConstant.REFRESH_INTERVAL * 1000);
};

const newAuthProvider = {
    login: ({username, password}) => {
        const urlLogin = `${AppConstant.API_URL}/users-cms/login`;
        const bodyRequest = {email: username, password: password};
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify(bodyRequest),
        };
        return fetch(urlLogin, options).then(response => {
            //console.log("response", response);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.status);
            }
            return response.json()
        }).then((data) => {
            localStorage.setItem("permissions", data.role);
            localStorage.setItem("token", data.token);
            enableInterval();
        });
    },
    logout: () => {
        disableInterval();
        localStorage.removeItem("permissions");
        localStorage.removeItem("token");
        return Promise.resolve()
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            localStorage.removeItem("permissions");
            localStorage.removeItem("token");
            return Promise.reject()
        }
        return Promise.resolve()
    },
    checkAuth: () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.log("NULL TOKEN");
            return Promise.reject();
        }
        if (token === "") {
            console.log("EMPTY TOKEN");
            return Promise.reject();
        }
        if (token === "undefined") {
            console.log("Undefined TOKEN");
            return Promise.reject();
        }
        //const token = localStorage.getItem("token");
        if (!(token && token !== "" && token !== "undefined")) {
            return Promise.reject()
        }
        return Promise.resolve(token)
    },
    getPermissions: () => {
        const role = localStorage.getItem("permissions");
        if (!role) {
            return Promise.reject();
        }
        if (role === "") {
            return Promise.reject();
        }
        return Promise.resolve(role)
    },
    // ...
};

export default newAuthProvider;