import React from 'react';
import {Datagrid, EditButton, Filter, FunctionField, List, NumberField, SearchInput, TextField} from 'react-admin';
import TaxiCompanyTitle from './TaxiCompanyTitle';
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";
import {AppConstant} from "../../providers/constants";

const ContactFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const TaxiCompanyList = props => (
    <List title={<TaxiCompanyTitle type="Contacts"/>} filters={<ContactFilter/>} {...props}>
        <Datagrid>
            <TextField label={"Title"} source="title"/>
            <FunctionField
                label="Thumbnail"
                render={record => {
                    if (record.thumbnail.url && record.thumbnail.url !== "") {
                        return (
                            <img
                                src={`${AppConstant.SERVER_IMAGE}${record.thumbnail.url}`}
                                width={80}
                                height={80}
                                alt=""/>
                        );
                    } else {
                        return null
                    }
                }}
            />
            <TextField label={"Phone Number"} source="phone"/>
            <NumberField label={"Stars"} source="stars"/>
            <NumberField label={"Price Per Kilometer in dong"} source="price"/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default TaxiCompanyList;