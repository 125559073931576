import React, {Component} from "react";
import {AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput} from "react-admin";
import CultArticlesTitle from "./CultArticlesTitle";

class CultArticlesCreate extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <Create title={<CultArticlesTitle type={"Create"}/>} {...props}>
                <SimpleForm>
                    <TextInput fullWidth label={"Title"} validate={required()} source={"title"}/>
                    <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                    reference="languages-admin">
                        <AutocompleteInput optionText="code" optionValue="code"/>
                    </ReferenceInput>
                    <TextInput
                        fullWidth
                        label="Search Tags"
                        source="searchTags"
                    />
                </SimpleForm>
            </Create>
        )
    }
}

export default CultArticlesCreate;