import React from "react";
import {
    ChipField,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    SearchInput,
    TextField
} from "react-admin";
import {AppConstant} from "../../providers/constants";
import RestaurantPanoramaNotationsTitle from "./RestaurantPanoramaNotationsTitle";
import {NoneActions} from "../actions/NoAction";
import ImageViewer from "../image_viewer/ImageViewer";

const PanoramaNotationsFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const AvailableLanguagesField = ({label, source, record}) => {
    let availableLanguages = '';
    for (let i = 0; i < record.panoramaTranslations.length; i++) {
        availableLanguages += " " + record.panoramaTranslations[i].languageCode
    }
    record.availableLanguages = availableLanguages;
    return (
        <ChipField label={label} source={source} record={record}/>
    )
};

const RestaurantPanoramaNotationsList = props => (
    <List filters={<PanoramaNotationsFilter/>}
          bulkActionButtons={<NoneActions/>}
          actions={<NoneActions/>}
          title={<RestaurantPanoramaNotationsTitle type="Panorama Notations"/>} {...props}>
        <Datagrid>
            <ReferenceField label="Title / Translations" source="destinationId"
                            reference={AppConstant.RESTAURANTS_ADMIN}>
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <FunctionField
                id="thumbnail"
                label="Thumbnail"
                render={record => {
                    if (record.thumbnail && record.thumbnail !== '') {
                        return (
                            <ImageViewer
                                url={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                                width={AppConstant.IMAGES_WIDTH}
                                height={AppConstant.IMAGES_HEIGHT}/>
                        );
                    }
                    return null;
                }}
            />
            <AvailableLanguagesField label="Available Languages" source="availableLanguages"/>
            <ChipField label={"Panorama Translations"} source={"panoramaTranslations[0].notation"}/>
            <EditButton label={""} style={{color: "#ffa24a"}}/>
        </Datagrid>
    </List>
);

export default RestaurantPanoramaNotationsList;
