import React from "react";
import {
    BooleanField,
    BooleanInput,
    ChipField,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    TextInput,
} from "react-admin";
import RegionTitle from "./RegionTitle";
import {AppConstant} from "../../providers/constants";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";
import Popup from "reactjs-popup";

const RegionFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
        <ReferenceInput label="Parent Place" source="parentID" reference={AppConstant.PLACES_ADMIN} allowEmpty>
            <SelectInput optionText="translations[0].title"/>
        </ReferenceInput>
        <ReferenceInput label="Parent Sub-Place" source="regionID" reference="regions-admin" allowEmpty>
            <SelectInput optionText="translations[0].title"/>
        </ReferenceInput>
        <ReferenceInput label="Type" source="typeId" reference="place-type" allowEmpty>
            <SelectInput optionText="type"/>
        </ReferenceInput>
        <TextInput source="owner"/>
        <TextInput source="rating"/>
        <TextInput source="openTime"/>
        <TextInput source="closeTime"/>
        <BooleanInput label={"Most Visited"} source={"isMostVisited"}/>
        <BooleanInput label={"Hide"} source={"isHide"}/>
    </Filter>
);

const RegionList = props => (
    <List
        title={<RegionTitle type="Sub-Place"/>}
        filters={<RegionFilter/>}
        {...props}
    >
        <Datagrid>
            <ReferenceField label={"Title / Translations"} source={"translationID"}
                            reference={AppConstant.REGION_TRANSLATIONS}>
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <FunctionField
                label="Image"
                render={record => {
                    return (
                        <img
                            src={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                            width={80}
                            height={80}
                            alt={null}/>
                    );
                }}
            />
            <ReferenceField label="Parent Place" source="parentID"
                            reference={AppConstant.PLACES_ADMIN}>
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <ReferenceField label="Parent Sub-Place" source="regionID"
                            reference="regions-admin">
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <ReferenceField label={"Type"} source="typeId" reference="place-type">
                <TextField source="type"/>
            </ReferenceField>
            <TextField source="rating"/>
            <TextField source="openTime" type="time"/>
            <TextField source="closeTime" type="time"/>
            <ChipField source="link"/>
            <ChipField label={"Available Languages"} source={"availableLanguages"}/>
            <FunctionField
                label="Search Tags"
                render={record => {
                    const l = record.searchTags.split(", ")
                    return (
                        <Popup trigger={<button>Show</button>} position="left center">
                            <div>{l.map((t, index) => <li
                                style={{"list-style": "none"}}>{(index + 1).toString() + ". " + t}</li>)}</div>
                        </Popup>
                    );
                }}
            />
            {/*<ChipField label={"Search Tags"} source={"searchTags"}/>*/}
            <BooleanField label={"Most Visited"} source={"isMostVisited"}/>
            <BooleanField label={"Hide"} source={"isHide"}/>
            <EditButton label={""} style={{color: "#ffa24a"}}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default RegionList;
