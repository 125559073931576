export const AppConstant = {
    API_URL: process.env.REACT_APP_MODE === 'prod' ?
        process.env.REACT_APP_API_URL_PROD :
        (process.env.REACT_APP_MODE === 'test' ?
            process.env.REACT_APP_API_URL_TEST :
            process.env.REACT_APP_API_URL_LOCAL),
    RESOURCES: process.env.REACT_APP_MODE === 'prod' ? process.env.REACT_APP_SERVER_IMAGE_PROD : process.env.REACT_APP_SERVER_IMAGE_LOCAL,
    TINY_HEIGHT: 720,
    TINY_WIDTH: 1366,
    IMAGES_WIDTH: 170,
    IMAGES_HEIGHT: 130,
    REFERENCE_PAGE: 500,
    SERVER_IMAGE: process.env.REACT_APP_MODE === 'prod' ? process.env.REACT_APP_SERVER_IMAGE_PROD :
        (process.env.REACT_APP_MODE === 'test' ?
            process.env.REACT_APP_SERVER_IMAGE_TEST :
            process.env.REACT_APP_SERVER_IMAGE_LOCAL),
    USER_ADDRESS: process.env.REACT_APP_USER_API_URL_PROD,
    ADMIN_ADDRESS: process.env.REACT_APP_ADMIN_API_URL_PROD,
    PLACES_ADMIN: 'places-admin',
    PLACE_PANORAMA_NOTATIONS: 'place-panorama-notations-admin',
    REGION_PANORAMA_NOTATIONS: 'region-panorama-notations-admin',
    RESTAURANTS_PANORAMA_NOTATIONS: 'restaurant-panorama-notations-admin',
    OBJECT_PANORAMA_NOTATIONS: 'object-panorama-notations-admin',
    REGIONS_ADMIN: 'regions-admin',
    OBJECTS_ADMIN: 'objects-admin',
    RESTAURANTS_ADMIN: 'restaurants-admin',
    TOURS_ADMIN: 'tours-admin',
    PLACE_TRANSLATIONS: "translations-admin/place",
    REGION_TRANSLATIONS: "translations-admin/region",
    RESTAURANT_TRANSLATIONS: "translations-admin/restaurant",
    OBJECT_TRANSLATIONS: "translations-admin/object",
    REGION_ORDERS_ADMIN: "region-order-admin",
    LOOPS_ADMIM: "loops-admin",
    SUB_LOCATION_ADMIN: "sub-location-admin",
    CULTURAL_ARTICLES: "common-articles-admin",
    TAXI_COMPANY: "taxi-company-admin",
    USER_CMS: "users-cms",
    PLACES_IMAGES: 'C:\\Users\\Administrator\\go\\src\\go-hue-resources\\resources\\places',
    REGIONS_IMAGES: 'C:\\Users\\Administrator\\go\\src\\go-hue-resources\\resources\\regions',
    OBJECTS_IMAGES: 'C:\\Users\\Administrator\\go\\src\\go-hue-resources\\resources\\objects',
    DEFAULT_LOCATION: [16.46772, 107.57914],
    SUPPORTED_MEDIA_FORMAT: ["mp4", "ogg", "webm"],
    SUPPORTED_AUDIO_FORMAT: ["mp3", "wav"],
    MULTIPLE_IMAGES_FORMAT: ['jpeg', 'jpg', 'png', 'gif'],
    PAPER_SIZE: [210, 297], // A4 size in mm
    QR_SIZE: 50, // mm
    REFRESH_INTERVAL: 840, // seconds
    MAX_MAP_ZOOM: 1000,
    COMMON_DESTINATION: [
        'places-admin',
        'regions-admin',
        'objects-admin',
        'loops-admin',
        'tours-admin',

        'place-panorama-notations-admin',
        'region-panorama-notations-admin',
        'restaurant-panorama-notations-admin',
        'object-panorama-notations-admin'
    ],
    DESTINATION_TRANSLATION: [
        "translations-admin/place",
        "translations-admin/region",
        "translations-admin/restaurant",
        "translations-admin/object",
    ]
};
