import {useForm} from "react-final-form";
import React, {useCallback, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";

const MoveButtons = (props) => {
    const form = useForm();
    const {formArray, index, fieldName} = props;
    const [arrayRecords, setArrayRecords] = useState([]);

    const buttonUp = () => {
        let records = arrayRecords;
        if (index === 0) {
            let temp = records[records.length - 1];
            records[records.length - 1] = records[0];
            records[0] = temp;
        } else {
            let temp = records[index - 1];
            records[index - 1] = records[index];
            records[index] = temp;
        }
        callbackUp(records)
    };

    const callbackUp = useCallback((records) => {
        form.change(fieldName, records);
        setArrayRecords(records)
    }, [form]);

    const buttonDown = () => {
        let records = arrayRecords;
        if (index === records.length - 1) {
            let temp = records[0];
            records[0] = records[records.length - 1];
            records[records.length - 1] = temp;
        } else {
            let temp = records[index + 1];
            records[index + 1] = records[index];
            records[index] = temp;
        }
        callbackDown(records)
    };

    const callbackDown = useCallback((records) => {
        form.change(fieldName, records);
        setArrayRecords(records)
    }, [form]);

    useEffect(() => {
        let records = [];
        for (let i = 0; i < formArray.length; i++) {
            records.push(formArray[i])
        }
        setArrayRecords(records)
    }, [formArray, index]);

    return (
        <div>
            <Button
                id={`buttonUp${index}`}
                type="button"
                style={{
                    marginLeft: 0,
                    marginBottom: 30,
                    verticalAlign: "left",
                    textDecoration: 'none'
                }}
                variant="contained"
                color="secondary"
                onClick={buttonUp}>
                Move Up
            </Button>
            <Button
                iid={`buttonDown${index}`}
                type="button"
                style={{
                    marginLeft: 30,
                    marginBottom: 30,
                    verticalAlign: "left",
                    textDecoration: 'none'

                }}
                variant="contained"
                color="secondary"
                onClick={buttonDown}>
                Move Down
            </Button>
        </div>
    )
};

export default MoveButtons;