import React from 'react';
import {Datagrid, EditButton, Filter, List, NumberField, SearchInput, TextField} from 'react-admin';
import TimetableTitle from './TimetableTitle';
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const TimetableFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const TimetableList = props => (
    <List title={<TimetableTitle type="Timetables"/>} filters={<TimetableFilter/>} {...props}>
        <Datagrid>
            <TextField label={"Destinations"} source={"locationsName"}/>
            <NumberField label={"Distance (meters)"} source={"totalDistance"}/>
            <NumberField label={"Times"} source={"totalTime"}/>
            <TextField label={"Locations"} source={"numberDestinations"}/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default TimetableList;