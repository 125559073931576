import React from 'react';
import {BooleanField, CardActions, Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import PlaceTypesTitle from "./PlaceTypesTitle";

const PlaceTypesFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const NoneActions = props => (
    <CardActions/>
);

const PlaceTypesList = ({permissions, ...props}) => (
    <List title={<PlaceTypesTitle type="PlaceTypes"/>}
          filters={<PlaceTypesFilter/>}
          {...props}
        // bulkActionButtons={permissions === "Admin" ? <NoneActions/> : false}
          bulkActionButtons={false}
    >
        <Datagrid>
            <TextField source="type"/>
            <BooleanField label={"Hide"} source={"isHide"}/>
            {permissions === "Admin" ? [<EditButton style={{color: "#ffa24a"}} label={""}/>] : null}
        </Datagrid>
    </List>
);

export default PlaceTypesList;