import React from 'react';

import {Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';

const LoopTypeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                            perPage={10} reference="languages-admin">
                <SelectInput source="languageCode" optionText={"code"} optionValue={"code"}/>
            </ReferenceInput>
            <TextInput label={"Loop Type"} source="name" validate={required()}/>
        </SimpleForm>
    </Create>
);

export default LoopTypeCreate;