import React from 'react';

import {AutocompleteInput, Create, ReferenceInput, required, SimpleForm} from 'react-admin';
import HelpTitle from "./HelpTitle";

const redirect = (basePath, id, data) => {
    return `/help-admin/${id}`;
};

const HelpCreate = props => (
    <Create title={<HelpTitle type="Create"/>} {...props}>
        <SimpleForm redirect={redirect}>
            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                            reference="languages-admin">
                <AutocompleteInput optionText="code" optionValue="code"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default HelpCreate;