import RegionList from './RegionList';
import RegionCreate from './RegionCreate';
import RegionEdit from './RegionEdit';
import RegionIcon from '@material-ui/icons/Transform';

export default {
    list: RegionList,
    create: RegionCreate,
    edit: RegionEdit,
    icon: RegionIcon
};