import React from 'react';
import {Edit, required, SimpleForm, TextField, TextInput} from 'react-admin';
import LanguageTitle from './LanguageTitle';

const LanguageEdit = props => (
    <Edit title={<LanguageTitle type="Edit"/>} {...props}>
        <SimpleForm>
            <TextInput source="code" validate={required()}/>
            <TextInput source="currency" validate={required()}/>
            <TextField source="title"/>
        </SimpleForm>
    </Edit>
);

export default LanguageEdit;