import React from "react";
import {
    BooleanField,
    ChipField,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    SearchInput,
    TextField
} from "react-admin";
import {AppConstant} from "../../providers/constants";
import ObjectTitle from "./ObjectTitle";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";
import Popup from "reactjs-popup";


const ObjectFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const ObjectList = props => (
    <List filters={<ObjectFilter/>} title={<ObjectTitle type="Objects"/>} {...props}>
        <Datagrid>
            <ReferenceField label={"Title / Translations"} source={"translationID"}
                            reference={AppConstant.OBJECT_TRANSLATIONS}>
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <FunctionField
                label="Image"
                render={record => {
                    return (
                        <img
                            src={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                            width={80}
                            height={80}
                        />
                    );
                }}
            />
            <ReferenceField label="Region" source="regionID" reference="regions-admin">
                <TextField source="translations[0].title"/>
            </ReferenceField>
            <ChipField source="link"/>
            <ChipField label={"Available Languages"} source={"availableLanguages"}/>
            <FunctionField
                label="Search Tags"
                render={record => {
                    const l = record.searchTags.split(", ")
                    return (
                        <Popup trigger={<button>Show</button>} position="left center">
                            <div>{l.map((t, index) => <li
                                style={{"list-style": "none"}}>{(index + 1).toString() + ". " + t}</li>)}</div>
                        </Popup>
                    );
                }}
            />
            {/*<ChipField label={"Search Tags"} source={"searchTags"}/>*/}
            <BooleanField label={"Hide"} source={"isHide"}/>
            <EditButton label={""} style={{color: "#ffa24a"}}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default ObjectList;
