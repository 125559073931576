import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    FormDataConsumer,
    FormTab,
    FunctionField,
    ImageField,
    ImageInput,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from "react-admin";
import {AppConstant} from "../../providers/constants";
import LoopTitle from "./LoopTitle";
import {styles} from "../../common/styleField";
import LinkButton from "../uploads/LinkButton";
import ImageViewer from "../image_viewer/ImageViewer";

const timeFormat = [
    {id: "hour", name: "Hour"},
    {id: "minute", name: "Minute"},
    {id: "second", name: "Second"},
];

const LoopEdit = (props) => {

    const formatImage = (value) => {
        if (!value || typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
            return {url: value};
        } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
            return value;
        }
    };

    return (
        <Edit title={<LoopTitle type="Edit"/>} {...props}>
            <TabbedForm redirect={false}>
                <FormTab label={"General"}>
                    <ReferenceArrayInput label={"Loop Types"} source="loopTypes" reference="loop-types-admin">
                        <SelectArrayInput optionText="loopTypeTranslations[0].name" optionValue={"id"}/>
                    </ReferenceArrayInput>
                    <NumberField source={"numberTime"}/>
                    <SelectInput source={"unitTime"} choices={timeFormat}/>
                    <FunctionField
                        id="loopThumbnail"
                        label="Thumbnail"
                        render={record => {
                            if (!record.thumbnail || record.thumbnail === '') {
                                return null
                            }
                            return (
                                <ImageViewer
                                    url={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                                    width={AppConstant.IMAGES_WIDTH}
                                    height={AppConstant.IMAGES_HEIGHT}/>
                            )
                        }}
                    />
                    <ImageInput source="thumbnail" label="Input Thumbnail" accept="image/*">
                        <ImageField source="thumbnail" title=""/>
                    </ImageInput>
                    <TextInput fullWidth label={"Search Tags"} source={"searchTags"}/>
                </FormTab>
                <FormTab label={"Translations"}>
                    <ArrayInput source={"contentTranslation"} style={styles.translations}>
                        <SimpleFormIterator>
                            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                            reference="languages-admin">
                                <AutocompleteInput optionText="code" optionValue="code"/>
                            </ReferenceInput>
                            <TextInput fullWidth label={"Title"} source={"title"}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={"Timetable"}>
                    <ArrayInput source={"days"} style={styles.timetables}>
                        <SimpleFormIterator>
                            <NumberInput label={"Day Order"} source={"dayOrder"}/>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (!scopedFormData) {
                                        return (
                                            <ReferenceInput perPage={AppConstant.REFERENCE_PAGE} label="Timetable"
                                                            validate={required()}
                                                            source={getSource("timetableID")}
                                                            reference="timetable">
                                                <SelectInput optionText="locationsName"/>
                                            </ReferenceInput>
                                        )
                                    }
                                    if (!scopedFormData.timetableID) {
                                        return (
                                            <ReferenceInput perPage={AppConstant.REFERENCE_PAGE} label="Timetable"
                                                            validate={required()}
                                                            source={getSource("timetableID")}
                                                            reference="timetable">
                                                <SelectInput optionText="locationsName"/>
                                            </ReferenceInput>
                                        )
                                    }
                                    let timetableLink = "";
                                    timetableLink = `${scopedFormData.timetableID}`;
                                    return (
                                        <div>
                                            <LinkButton source={timetableLink}/>
                                            <ReferenceInput perPage={AppConstant.REFERENCE_PAGE} label="Timetable"
                                                            validate={required()}
                                                            source={getSource("timetableID")}
                                                            reference="timetable">
                                                <AutocompleteInput options={{fullWidth: true}}
                                                                   optionText="locationsName"/>
                                            </ReferenceInput>
                                        </div>
                                    )
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default LoopEdit;