import ObjectPanoramaNotationsList from './ObjectPanoramaNotationsList';
//import ObjectPanoramaNotationsCreate from './ObjectPanoramaNotationsCreate';
import ObjectPanoramaNotationsEdit from './ObjectPanoramaNotationsEdit';
import PanoramaIcon from '@material-ui/icons/Panorama';

export default {
    list: ObjectPanoramaNotationsList,
    //create: ObjectPanoramaNotationsCreate,
    edit: ObjectPanoramaNotationsEdit,
    icon: PanoramaIcon
};