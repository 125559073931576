import React from 'react';
import {Edit, required, SimpleForm, TextInput} from 'react-admin';
import ContactTitle from './ContactTitle';

const ContactEdit = props => (
    <Edit title={<ContactTitle type="Edit"/>} {...props}>
        <SimpleForm redirect={false}>
            <TextInput label={"Title"} source="title" validate={required()}/>
            <TextInput fullWidth label={"Description"} source="description"/>
            <TextInput label={"Phone Number"} source="phoneNumber" validate={required()}/>
        </SimpleForm>
    </Edit>
);

export default ContactEdit;