import CultArticlesList from './CultArticlesList';
import CultArticlesCreate from './CultArticlesCreate';
import CultArticlesEdit from './CultArticlesEdit';
import DescriptionIcon from '@material-ui/icons/Description';

export default {
    list: CultArticlesList,
    create: CultArticlesCreate,
    edit: CultArticlesEdit,
    icon: DescriptionIcon,
};