import AppPricingList from './AppPricingList';
import AppPricingCreate from './AppPricingCreate';
import AppPricingEdit from './AppPricingEdit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

export default {
    list: AppPricingList,
    create: AppPricingCreate,
    edit: AppPricingEdit,
    icon: AttachMoneyIcon
};