import React from 'react';
import {Datagrid, EditButton, Filter, List, SearchInput, TextField} from 'react-admin';
import FreeAudioTitle from './FreeAudioTitle';
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const FreeAudioFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);

const FreeAudioList = props => (
    <List title={<FreeAudioTitle type="Free Audio"/>} filters={<FreeAudioFilter/>} {...props}>
        <Datagrid>
            <TextField label={"ID"} source={"freeAudio"}/>
            <TextField label={"Title"} source={"title"}/>
            <EditButton style={{color: "#ffa24a"}} label={""}/>
            <DeleteButtonWithConfirmation undoable={false} label={""}/>
        </Datagrid>
    </List>
);

export default FreeAudioList;