import React, {useCallback, useEffect, useState} from "react";
import "../map/map.css";
import {LayerGroup, Map, Marker, Polygon, Popup, TileLayer} from "react-leaflet";
import '../map/map.css'
import IconColor from "./constants";
import {AppConstant} from "../../providers/constants";
import {useForm} from "react-final-form";

const ShowBoundary = (props) => {
    const form = useForm();
    const [markers, setMarkers] = useState([]);
    const [bounds, setBounds] = useState([]);
    const [zoom, setZoom] = useState(18);
    const [draggable, setDraggable] = useState(true);

    useEffect(() => {
        let markerList = [];
        let bounds = [];
        props.boundary.coordinates[0].points.forEach((value) => {
            if (value) {
                if (value.lat && value.long) {
                    if (value.lat !== 0 && value.long !== 0) {
                        markerList.push([value.lat, value.long]);
                        bounds.push({lat: value.lat, lng: value.long})
                    }
                }
            }
        });
        setBounds(bounds);
        setMarkers(markerList);
    }, [props.boundary.coordinates[0].points]);

    const handleAddMarkers = useCallback((newPos, boundary_points) => {
        let newCoordinates = {
            coordinates: [
                {
                    points: boundary_points,
                }
            ],
        };
        let oldMarkers = markers;
        oldMarkers.push(newPos);
        let oldBoundary = bounds;
        oldBoundary.push({lat: newPos[0], lng: newPos[1]});
        form.change("boundary", newCoordinates);
        setBounds(oldBoundary);
        setMarkers(oldMarkers);
        // setMarkers(prevState => prevState.concat([newPos]));
    }, [form]);

    const handleUpdateMarkers = useCallback((points, oldBounds, oldMarkers, markerIndex, latLng) => {
        oldBounds[markerIndex].lat = points[markerIndex].lat = latLng.lat;
        oldBounds[markerIndex].lng = points[markerIndex].long = latLng.lng;
        let newCoordinates = {
            coordinates: [
                {
                    points: points,
                }
            ],
        };
        form.change("boundary", newCoordinates);
        oldMarkers[markerIndex] = [latLng.lat, latLng.lng];
        setMarkers(oldMarkers);
        setBounds(oldBounds);
    }, [form]);

    // const initData = (boundaryProps) => {
    //     let markerList = [];
    //     let bounds = [];
    //     boundaryProps.forEach((value) => {
    //         if (value.lat && value.long) {
    //             markerList.push([value.lat, value.long]);
    //             bounds.push({lat: value.lat, lng: value.long})
    //         }
    //     });
    //     setBounds(bounds);
    //     setMarkers(markerList);
    // };

    const addMarker = (markers, boundary_points) => (e) => {
        const newPos = [e.latlng.lat, e.latlng.lng];
        boundary_points.push({lat: newPos[0], long: newPos[1]});
        handleAddMarkers(newPos, boundary_points)
    };

    const updateMarkers = (points) => (e) => {
        const latLng = e.target.getLatLng();
        const markerIndex = e.target.options.marker_index;
        let oldBounds = [...bounds];
        let oldMarkers = [...markers];
        handleUpdateMarkers(points, oldBounds, oldMarkers, markerIndex, latLng)
    };

    const {boundary, boundaryMarkers, subBoundaries, location} = props;
    if (!boundary) {
        return null
    }
    return (
        <div id="mapId" className="leaflet-container">
            <Map
                style={{height: "100vh"}}
                id="mapId"
                preferCanvas={true}
                center={[location.coordinates.lat, location.coordinates.long]}
                draggable={draggable}
                doubleClickZoom={false}
                ondblclick={addMarker(markers, boundary.coordinates[0].points)}
                zoom={zoom}
                maxZoom={AppConstant.MAX_MAP_ZOOM}
                onZoomEnd={(event) => setZoom(event.target._zoom)}
            >
                <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                {markers.map((position, idx) =>
                    <LayerGroup>
                        <Marker
                            icon={IconColor("red")}
                            key={`marker-${idx}`} position={position}
                            marker_index={`${idx}`}
                            draggable={draggable}
                            onDragend={updateMarkers(boundary.coordinates[0].points)}
                        >
                            <Popup>
                                <span key={`marker-${idx}`}>Marker number {idx + 1}</span>
                            </Popup>
                        </Marker>
                    </LayerGroup>
                )}
                <Polygon color={"#733000"} positions={bounds}/>
                {boundaryMarkers ? boundaryMarkers.map((position, idx) =>
                    <LayerGroup>
                        <Marker
                            icon={IconColor("black")}
                            key={`marker-${idx}`} position={position}
                            marker_index={`${idx}`}
                            draggable={false}
                        >
                            <Popup>
                                <span key={`marker-${idx}`}>Sub Boundary Marker number {idx + 1}</span>
                            </Popup>
                        </Marker>
                    </LayerGroup>
                ) : null}
                {subBoundaries ? subBoundaries.map((position, idx) =>
                    <LayerGroup>
                        <Polygon color={"black"} positions={position.coordinates[0].points}/>
                    </LayerGroup>
                ) : null}
            </Map>
        </div>
    );
};

export default ShowBoundary;