import React, {useState} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    FunctionField,
    ImageField,
    ImageInput,
    ReferenceInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
} from 'react-admin';
import ObjectTitle from './ObjectTitle';
import {AppConstant} from "../../providers/constants";
import {styles} from "../../common/styleField";
import PropTypes from "prop-types";
import {EditToolbar} from "../toolbar/ToolBar";
import ImageViewer from "../image_viewer/ImageViewer";
import MoveButtons from "../order_buttons/MoveButtons";
import ShowPanorama from "../paranoma_images/ShowPanorama";

const ObjectEdit = (props) => {
    const [objectName, setObjectName] = useState('');
    const formatImage = (value) => {
        if (!value || typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
            return {url: value};
        } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
            return value;
        }
    };

    return (
        <Edit title={<ObjectTitle type={"Edit " + `${objectName}` + " "}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label="General">
                    <BooleanInput label={"Hide"} source={"isHide"}/>
                    <TextInput
                        disabled
                        source="code"
                        validate={required()}
                        style={styles.inputInline}
                    />
                    <ReferenceInput label="Region" validate={required()} source="regionID"
                                    reference="regions-admin"
                                    perPage={AppConstant.REFERENCE_PAGE}
                    >
                        <AutocompleteInput optionText="translations[0].title"/>
                    </ReferenceInput>
                    <TextInput
                        fullWidth
                        label="Link"
                        source="link"
                    />
                    <TextInput
                        fullWidth
                        label="Search Tags"
                        source="searchTags"
                    />
                    <TextInput label="Phone number" source="destinationContact.phone"/>
                    <TextInput label="Email" source="destinationContact.email"/>
                    <TextInput label="Website" source="destinationContact.website"/>
                    <FunctionField
                        id="objectThumbnail"
                        label="Thumbnail"
                        render={record => {
                            if (!record.thumbnail || record.thumbnail === '') {
                                return null
                            }
                            return (
                                <ImageViewer
                                    url={`${AppConstant.SERVER_IMAGE}${record.thumbnail}`}
                                    width={AppConstant.IMAGES_WIDTH}
                                    height={AppConstant.IMAGES_HEIGHT}/>
                            )
                        }}
                    />
                    <ImageInput source="thumbnail" label="Input Thumbnail" accept="image/*">
                        <ImageField source="thumbnail" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Images">
                    <ArrayInput source="images" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        return (
                                            <div>
                                                <ImageViewer url={scopedFormData.url}
                                                             width={AppConstant.IMAGES_WIDTH}
                                                             height={AppConstant.IMAGES_HEIGHT}/>
                                                <MoveButtons formArray={formData.images}
                                                             index={formData.images.indexOf(scopedFormData)}
                                                             fieldName={'images'}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Input Images" accept="image/*" multiple>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Panorama">
                    <ArrayInput source="panorama" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        return (
                                            <div>
                                                <ShowPanorama url={scopedFormData.url}/>
                                                <MoveButtons formArray={formData.panorama}
                                                             index={formData.panorama.indexOf(scopedFormData)}
                                                             fieldName={'panorama'}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="panorama" label="Input Images" accept="image/*" multiple>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

ObjectEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default ObjectEdit;
