import React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from "react-admin";
import TourTitle from "./TourTitle";
import {styles} from "../../common/styleField";
import {AppConstant} from "../../providers/constants";
import PropTypes from "prop-types";
import ImageViewer from "../image_viewer/ImageViewer";
import MoveButtons from "../order_buttons/MoveButtons";

const TourEdit = (props) => {
    return (
        <Edit title={<TourTitle type="Edit"/>} {...props}>
            <TabbedForm redirect={false}>
                <FormTab label="General">
                    <NumberInput disabled label="Total Destinations" source="numberDestinations"/>
                    <NumberInput label={"Price in VND"} source="price"/>
                    <NumberInput label="Total days" source="numberDays"/>
                    <NumberInput label="Total nights" source="numberNights"/>
                    <TextInput fullWidth label={"Search Tags"} source={"searchTags"}/>
                </FormTab>
                <FormTab label="Images">
                    <ArrayInput source="images" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        return (
                                            <div>
                                                <ImageViewer url={scopedFormData.url}
                                                             width={AppConstant.IMAGES_WIDTH}
                                                             height={AppConstant.IMAGES_HEIGHT}/>
                                                <MoveButtons formArray={formData.images}
                                                             index={formData.images.indexOf(scopedFormData)}
                                                             fieldName={'images'}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Input Images" accept="image/*" multiple>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label={"Translations"}>
                    <ArrayInput source={"contentTranslation"} style={styles.translations}>
                        <SimpleFormIterator>
                            <ReferenceInput label="Language Code" validate={required()} source="languageCode"
                                            reference="languages-admin">
                                <AutocompleteInput optionText="code" optionValue="code"/>
                            </ReferenceInput>
                            <TextInput fullWidth label={"Title"} source={"title"}/>
                            <TextInput fullWidth label={"Description"} source={"description"}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Schedules">
                    <ArrayInput source="schedules" style={styles.translations}>
                        <SimpleFormIterator>
                            <TextInput label={"Time"} source="time" type="time"/>
                            <SelectInput label={"Destination Type"} source="destinationType" choices={[
                                {id: 'place', name: 'Place'},
                                {id: 'region', name: 'Sub-place'},
                                {id: 'restaurant', name: 'Restaurant'},
                            ]}/>
                            <FormDataConsumer>
                                {({formData, getSource, scopedFormData, ...rest}) => {
                                    if (scopedFormData) {
                                        if (scopedFormData.destinationType) {
                                            if (scopedFormData.destinationType === "place") {
                                                return (
                                                    <ReferenceInput label="Place" validate={required()}
                                                                    source={getSource("destinationID")}
                                                                    perPage={AppConstant.REFERENCE_PAGE}
                                                                    reference={AppConstant.PLACES_ADMIN}>
                                                        <AutocompleteInput optionText="translations[0].title"/>
                                                    </ReferenceInput>
                                                )
                                            }
                                            if (scopedFormData.destinationType === "region") {
                                                return (
                                                    <ReferenceInput label="Sub-place" validate={required()}
                                                                    source={getSource("destinationID")}
                                                                    perPage={AppConstant.REFERENCE_PAGE}
                                                                    reference="regions-admin">
                                                        <AutocompleteInput optionText="translations[0].title"/>
                                                    </ReferenceInput>
                                                )
                                            }
                                            if (scopedFormData.destinationType === "restaurant") {
                                                return (
                                                    <ReferenceInput label="Restaurant" validate={required()}
                                                                    source={getSource("destinationID")}
                                                                    perPage={AppConstant.REFERENCE_PAGE}
                                                                    reference={AppConstant.RESTAURANTS_ADMIN}>
                                                        <AutocompleteInput optionText="translations[0].title"/>
                                                    </ReferenceInput>
                                                )
                                            }
                                        }
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

TourEdit.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default TourEdit;
