import RestaurantList from './RestaurantList';
import RestaurantCreate from './RestaurantCreate';
import RestaurantEdit from './RestaurantEdit';
import RestaurantIcon from '@material-ui/icons/Restaurant';

export default {
    list: RestaurantList,
    create: RestaurantCreate,
    edit: RestaurantEdit,
    icon: RestaurantIcon
};